import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useQuery } from "@apollo/client";
import { getCompareByZone } from "../../queries/queries";
import SelectedTable from "../SelectedTable/selectedTable";
import SelectedTableToolBar from "../SelectedTable/selectedTableToolBar";
import { selectApartment } from "../../redux/selectors/apartment.selectors";
import { selectHasZoneRows } from "../../redux/selectors/selectedRows.selectors";
import { selectPricingData } from "../../redux/selectors/pricingData.selectors";
import {
  addManyRowsZoneAction,
  addZoneRowAction,
  removeAllZoneRowsAction,
  removeManyRowsAction,
  removeRowAction,
} from "../../redux/actions/selectedRows.actions";
import {
  optionsArea,
  optionsDateLead,
  optionsProperty,
  optionsYears,
} from "../../utils/optionsUtilities";
import { createPolygonWKT } from "../../utils/generalUtils";
import { validateOnSelection } from "../../redux/utils/warnings.utlis";
import { setWarningsAndIdsAction } from "../../redux/actions/warnings.actions";
import { selectPolygonPoints } from "../../redux/selectors/mapPolygon.selectors";
import { addFlagRowDataAction } from "../../redux/actions/flagSelectionData.actions";

const ByZoneContainer = (props) => {
  const {
    apartment,
    addZoneRow,
    removeRow,
    removeManyRows,
    addManyZoneRows,
    sourceOptions,
    statusOptions,
    hasZoneRows,
    removeAllZoneRows,
    formValues,
    setWarningsAndIds,
    polygon,
    addFlagRow,
  } = props;

  const { area, antiguedad, inmuebleID, garajes, ascensores } = apartment;

  const [optionArea, setOptionArea] = useState("+/- 5 m²");
  const [filterArea, setFilterArea] = useState(5);

  const [garageFilter, setGarageFilter] = useState(garajes > 0 ? true : false);
  const [elevatorFilter, setElevatorFilter] = useState(
    ascensores > 0 ? true : false
  );

  const [sourceName, setSourceName] = useState(["Ventana"]);
  const [sourceIds, setSourceIds] = useState([1]);

  const [statusName, setStatusName] = useState(["Todas"]);
  const [statusIds, setStatusIds] = useState([0]);

  const [dateLeadName, setDateLeadName] = useState("Menos de 6 meses");
  const [filterDate, setFilterDate] = useState(6);

  const [propertyName, setPropertyName] = useState([
    `${apartment.labelInmueble}`,
  ]);
  const [propertyIds, setPropertyIds] = useState([apartment.tipoInmueble]);

  const [optionYears, setOptionYears] = useState("+/- 5 años");
  const [filterYear, setFilterYear] = useState(5);

  const { loading, error, data } = useQuery(
    getCompareByZone(
      inmuebleID,
      area,
      filterArea,
      antiguedad,
      filterYear,
      garageFilter ? 1 : 0,
      elevatorFilter ? 1 : 0,
      sourceIds,
      filterDate,
      propertyIds,
      createPolygonWKT(polygon),
      statusIds
    )
  );

  useEffect(() => {
    if (hasZoneRows && loading) removeAllZoneRows();
  }, [loading, hasZoneRows, removeAllZoneRows]);

  useEffect(() => {
    if (data?.getComparableByZone) {
      data.getComparableByZone.forEach((row) => {
        if (row.area_flag) {
          let tempObj = {
            area_flag: row.area,
            varareaFlag: row.area_flag,
          };
          addFlagRow({ rowId: row.id, data: tempObj });
        }
        if (row.garaje_flag) {
          let tempObj = {
            garaje_flag: row.garajes,
            vargarajeFlag: row.garaje_flag,
          };
          addFlagRow({ rowId: row.id, data: tempObj });
        }
        if (row.bano_flag) {
          let tempObj = {
            bano_flag: row.banos,
            varbanoFlag: row.bano_flag,
          };
          addFlagRow({ rowId: row.id, data: tempObj });
        }
        if (row.piso_flag) {
          let tempObj = {
            piso_flag: row.num_piso,
            varpisoFlag: row.piso_flag,
          };
          addFlagRow({ rowId: row.id, data: tempObj });
        }
        if (row.ascensor_flag) {
          let tempObj = {
            ascensor_flag: row.num_ascensores,
            varpisoFlag: row.ascensor_flag,
          };
          addFlagRow({ rowId: row.id, data: tempObj });
        }
        if (row.antiguedad_flag) {
          let tempObj = {
            antiguedad_flag: row.anos_antiguedad,
            varantiguedadFlag: row.antiguedad_flag,
          };
          addFlagRow({ rowId: row.id, data: tempObj });
        }
        if (row.conjunto_edificio_flag) {
          let tempObj = {
            conjunto_edificio_flag: row.conjunto_edificio,
            varconjuntoEdificioFlag: row.conjunto_edificio_flag,
          };
          addFlagRow({ rowId: row.id, data: tempObj });
        }
        if (row.tipo_inmueble_id_flag) {
          let tempObj = {
            tipo_inmueble_id_flag: row.tipo_inmueble_id,
            vartipoInmuebleIdFlag: row.tipo_inmueble_id_flag,
          };
          addFlagRow({ rowId: row.id, data: tempObj });
        }
        if (row.valorm2_flag) {
          let tempObj = {
            valorm2_flag: row.valormt2,
            varvalorM2Flag: row.valorm2_flag,
          };
          addFlagRow({ rowId: row.id, data: tempObj });
        }
      });
    }
  }, [data, addFlagRow]);

  const zoneRowHandler = (row, isSelected) => {
    if (isSelected) return removeRow(row.id);
    addZoneRow(row);
    const validationRes = validateOnSelection({
      formValues,
      apartment,
      selectedRows: [row],
    });
    if (validationRes.warningMessages.length === 0) return;
    setWarningsAndIds(validationRes);
  };

  const addManyRowsHandler = ({ rows, noRowsSelected }) => () => {
    if (!noRowsSelected) return removeManyRows(rows);
    addManyZoneRows(rows);
    const validationRes = validateOnSelection({
      formValues,
      apartment,
      selectedRows: rows,
    });
    if (validationRes.warningMessages.length === 0) return;
    setWarningsAndIds(validationRes);
  };

  return (
    <SelectedTable
      title="Comparables por zona"
      rows={data?.getComparableByZone || []}
      rowSelectionHandler={zoneRowHandler}
      addManyRowsHandler={addManyRowsHandler}
      areaCompare={area}
      yearsCompare={antiguedad}
      loading={loading}
      error={error}
      type={3}
    >
      <SelectedTableToolBar
        title="Comparables por zona"
        optionsArea={optionsArea}
        setOptionArea={setOptionArea}
        textArea={optionArea}
        setFilterArea={setFilterArea}
        setGarageFilter={setGarageFilter}
        garageFilter={garageFilter}
        setElevatorFilter={setElevatorFilter}
        elevatorFilter={elevatorFilter}
        sourceOptions={sourceOptions}
        sourceName={sourceName}
        setSourceName={setSourceName}
        sourceIds={sourceIds}
        setSourceIds={setSourceIds}
        statusOptions={statusOptions}
        statusName={statusName}
        setStatusName={setStatusName}
        statusIds={statusIds}
        setStatusIds={setStatusIds}
        optionsDateLead={optionsDateLead}
        dateLeadName={dateLeadName}
        setDateLeadName={setDateLeadName}
        setFilterDate={setFilterDate}
        optionsProperty={optionsProperty}
        propertyName={propertyName}
        setPropertyName={setPropertyName}
        propertyIds={propertyIds}
        setPropertyIds={setPropertyIds}
        optionsYears={optionsYears}
        setOptionYears={setOptionYears}
        textYears={optionYears}
        setFilterYear={setFilterYear}
      />
    </SelectedTable>
  );
};

const mapStateToProps = (state) => ({
  apartment: selectApartment(state),
  formValues: selectPricingData(state),
  hasZoneRows: selectHasZoneRows(state),
  polygon: selectPolygonPoints(state),
});

const mapDispatchToProps = (dispatch) => ({
  addZoneRow: (kv) => dispatch(addZoneRowAction(kv)),
  removeRow: (id) => dispatch(removeRowAction(id)),
  removeManyRows: (rows) => dispatch(removeManyRowsAction(rows)),
  removeAllZoneRows: () => dispatch(removeAllZoneRowsAction()),
  addManyZoneRows: (rows) => dispatch(addManyRowsZoneAction(rows)),
  setWarningsAndIds: (payload) => dispatch(setWarningsAndIdsAction(payload)),
  addFlagRow: (fieldData) => dispatch(addFlagRowDataAction(fieldData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ByZoneContainer);
