import React from "react";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import TextArea from "@habi/habi-react-components/dist/TextArea/textArea";
import * as Yup from "yup";
import { updatePricingDataAction } from "../../redux/actions/pricingData.actions";

const Comment = (props) => {
  const { updatePricingData } = props;

  const stringSchema = Yup.object().shape({
    comentario: Yup.string()
      .required()
      .min(5, `Por favor validar que el comentario tenga minimo 5 caracteres`),
  });

  return (
    <Formik
      validationSchema={stringSchema}
      enableReinitialize
      initialValues={{
        comentario: "",
      }}
    >
      {(propsForm) => {
        updatePricingData({ comentario: propsForm.values.comentario });
        return (
          <Form id="formFilter">
            <div className="field-container">
              <TextArea
                name="comentario"
                errors={propsForm.errors}
                touched={propsForm.touched}
                id="comentario"
                type="textarea"
                label="Comentario"
                placeholder={"Deja un comentario..."}
                className="input-description"
                values={propsForm.values}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updatePricingData: (kvpair) => dispatch(updatePricingDataAction(kvpair)),
});

export default connect(null, mapDispatchToProps)(Comment);
