import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { signFileData } from "../../queries/queries";
import { useMutation } from "@apollo/client";
import ReactS3Uploader from "react-s3-uploader";
import CloudUploadRoundedIcon from "@material-ui/icons/CloudUploadRounded";
import { Snackbar, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { LoadPhotosWrapper } from "./style";
import { updatePricingDataAction } from "../../redux/actions/pricingData.actions";

const LoadPhotos = ({ inmuebleId, agent, updatePricingData, ...props }) => {
  const [contentType, setContentType] = useState("");
  const [snackMessage, setSnackMessage] = useState(false);
  const [open, setOpen] = useState(false);
  const [signFile] = useMutation(signFileData);

  const onProgress = () => {
    setSnackMessage("En progreso");
    updatePricingData({ loaded_file: true });
  };

  const onError = () => {
    setSnackMessage("Hubo un error subiendo el archivo");
    setOpen(true);
  };

  const onFinish = () => {
    setSnackMessage("Archivo se subio exitosamente");
    setOpen(true);
  };

  const handleSignedFileUpload = async (file, callback) => {
    setContentType(file.type);
    signFile({
      variables: {
        name: file.name,
        type: file.type,
        inmuebleId: inmuebleId,
        agente: agent,
      },
      update: (cache, response) => {
        if (response.data.signFile === "error") {
          setSnackMessage("Hubo un error subiendo el archivo");
          setOpen(true);
        } else {
          callback(response.data.signFile);
        }
      },
    });
  };

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  }

  return (
    <Fragment>
      <LoadPhotosWrapper>
        <div className="upload-button">
          <ReactS3Uploader
            className="uploader-button"
            getSignedUrl={handleSignedFileUpload}
            accept="*"
            onProgress={onProgress}
            onError={onError}
            onFinish={onFinish}
            uploadRequestHeaders={{
              "Content-Type": contentType,
              "x-amz-acl": "private",
            }}
            contentDisposition="auto"
            autoUpload={true}
          />
          <CloudUploadRoundedIcon className="icon-cloud" />
          <span id="button-upload">Subir Foto</span>
        </div>
      </LoadPhotosWrapper>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{snackMessage}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updatePricingData: (kv) => dispatch(updatePricingDataAction(kv)),
});

export default connect(null, mapDispatchToProps)(LoadPhotos);
