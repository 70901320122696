import styled from "styled-components";

export const BucketWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-areas:
    "header"
    "current-lead"
    "title"
    "table"
    "question"
    "comment"
    "save";

  .header {
    grid-area: header;
  }

  .title {
    grid-area: title;
    display: flex;
    justify-content: space-between;
    padding: 28px 24px;
  }

  .save {
    grid-area: save;
    padding: 0px 64px;
  }

  .card {
    min-width: 275px;
    max-width: 800px;
    margin: 20px auto;
    .cardContent {
      margin: spacing(1);
      .save-button {
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
      .text-file {
        margin: 10px;
        width: 650px;
      }
      .select-empty {
        margin-top: spacing(2);
        min-width: 200px;
      }
    }
  }
`;
