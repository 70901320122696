import React from "react";
import { Dropdown } from "react-bootstrap";
import RoundedDropdownWrapper from "./style";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";

const RoundedDropdown = (props) => {
  const { option, title, text, idDropdown, setOption, setFilter } = props;

  const handleOptions = (e) => {
    setOption(e.target.innerText);
    if (!setFilter) return;
    setFilter(option[e.target.innerText]);
  };

  return (
    <RoundedDropdownWrapper>
      <div className="container">
        <span>{text}</span>
        <div className="dropdow-container">
          <Dropdown drop="down">
            <Dropdown.Menu
              key={idDropdown}
              id={`dropDown-rounded-${idDropdown}`}
            >
              {option &&
                Object.keys(option).map((item, index) => {
                  return (
                    <Dropdown.Item
                      onClick={(event) => handleOptions(event)}
                      className={title === item ? "active" : null}
                      key={index}
                    >
                      {item}
                    </Dropdown.Item>
                  );
                })}
            </Dropdown.Menu>
            <Dropdown.Toggle id="rounded-custom">
              {title}
              <ExpandMoreRoundedIcon className="icon-arrow" />
            </Dropdown.Toggle>
          </Dropdown>
        </div>
      </div>
    </RoundedDropdownWrapper>
  );
};

RoundedDropdown.defaultProps = { idDropdown: "dropDown-rounded" };

export default RoundedDropdown;
