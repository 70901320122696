export const pricingDataActionTypes = {
  UPDATE_PRICING_DATA_VALUE: "UPDATE_PRICING_DATA_VALUE",
  REMOVE_PRICING_DATA_VALUE: "REMOVE_PRICING_DATA_VALUE",
  SET_PRICING_DATA_VALUES: "SET_PRICING_DATA_VALUES",
  RESET_PRICING_DATA_VALUES: "RESET_PRICING_DATA_VALUES",
};

export const updatePricingDataAction = (kvPair) => ({
  type: pricingDataActionTypes.UPDATE_PRICING_DATA_VALUE,
  payload: kvPair,
});

export const removePricingDataValueAction = (key) => ({
  type: pricingDataActionTypes.REMOVE_PRICING_DATA_VALUE,
  payload: key,
});

export const setPricingDataAction = (kvPair) => ({
  type: pricingDataActionTypes.SET_PRICING_DATA_VALUES,
  payload: kvPair,
});

export const resetPricingDataAction = () => ({
  type: pricingDataActionTypes.RESET_PRICING_DATA_VALUES,
});
