import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import store from "./redux/store";
import WebFont from "webfontloader";
import "./static/assets/fonts/Archia-Regular.otf";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";

const url =
  process.env.REACT_APP_ENV !== "local"
    ? process.env.REACT_APP_FORM_API_URL_V2 +
      process.env.REACT_APP_ENDPOINT_GRAPHQL_BASE_PATH +
      process.env.REACT_APP_GRAPHQL_ENDPOINT
    : process.env.REACT_APP_LOCAL_GRAPHQL;

const client = new ApolloClient({
  cache: new InMemoryCache({addTypename: false}),
  uri: url,
  // uri: "http://localhost:4000/graphql",
  headers: {
    "x-api-key": process.env.REACT_APP_GRAPHQL_API_KEY,
  },
});

WebFont.load({
  custom: {
    families: ["Open Sans", "sans-serif", "Muli"],
  },
});

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
