import styled from "styled-components";
import { muliBoldFamily, scarpaGray500, size } from "../../StylesConstants";

const HomeListWrapper = styled.div`
  grid-template-areas: "header-area", "filters-area", "table-area";

  .header-container {
    grid-area: "header-area";
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .title {
      font-family: ${muliBoldFamily};
      font-size: 24px;
      display: flex;
      align-items: baseline;

      h1 {
        margin: 0px;
      }

      .count-content {
        margin: 0;
        padding-left: 32px;
        color: ${scarpaGray500};
      }
    }
    .btn-container {
      display: inline-flex;
      justify-content: flex-end;
    }
  }

  .filters-container {
    grid-area: "filters-area";
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 2rem;
    row-gap: 1.5rem;
    margin: 28px 0px 32px;
    align-items: center;
  }

  .table-container {
    grid-area: "table-area";
  }

  @media screen and (min-width: ${size.desktopBigger}) {
    .filters-container {
      grid-area: "filters-area";
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      column-gap: 2rem;
      margin: 28px 0px 32px;
      align-items: center;
    }
  }
`;

export default HomeListWrapper;
