import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { connect } from "react-redux";

import { getComparisonHandbook } from "../../queries/queries";
import { getPricingPercentage } from "../../queries/queries";

import SelectedManualTable from "../SelectedTable/selectedManualTable";
import { selectApartment } from "../../redux/selectors/apartment.selectors";
import { selectRowsDataForMutations } from "../../redux/selectors/selectedRows.selectors";
import {
  removeRowAction,
  setRowsAction,
} from "../../redux/actions/selectedRows.actions";
import { updatePricingDataAction } from "../../redux/actions/pricingData.actions";
import {
  selectCoeficiente,
  selectPercentageSales,
} from "../../redux/selectors/pricingData.selectors";
import { resetRowsAction } from "../../redux/actions/selectedRows.actions";
import { decodeJsonData } from "./manualTableContainer.utils";

const ManualTableContainer = ({
  apartment,
  selectedRows,
  removeRow,
  updatePricingData,
  setRows,
  resetRows,
  starPricing,
}) => {
  const { data: dataPercent } = useQuery(
    getPricingPercentage(apartment.nid)
  );

  const { data: dataComparisonHandbook } = useQuery(
    getComparisonHandbook(apartment.inmuebleID)
  );


  useEffect(() => {
    if (!dataComparisonHandbook) return;
    const data = dataComparisonHandbook.getComparison;
    const decodedData = decodeJsonData(data.comparable);
    if (decodeJsonData.length > 0) setRows(decodedData);

    return () => resetRows();
  }, [dataComparisonHandbook, setRows, resetRows]);

  useEffect(() => {
    if (!dataPercent) return;
    const percentageSales = dataPercent?.getPercentage?.percentageSales;
    updatePricingData({ percentageSales });
  }, [dataPercent, updatePricingData]);

  return (
    <SelectedManualTable
      title="Comparables seleccionados manualmente"
      manualTable={true}
      numSelected={selectedRows.length}
      rowsManuallyAdded={selectedRows}
      apartment={apartment}
      percent={dataPercent?.getPercentage?.percentageSales || 0}
      removeRow={removeRow}
      updatePricingData={updatePricingData}
      starPricing={starPricing}
    />
  );
};

const mapStateToProps = (state) => ({
  apartment: selectApartment(state),
  selectedRows: selectRowsDataForMutations(state),
  coefficientVariation: selectCoeficiente(state),
  percentageSales: selectPercentageSales(state),
});

const mapDispatchToProps = (dispatch) => ({
  removeRow: (id) => dispatch(removeRowAction(id)),
  updatePricingData: (kv) => dispatch(updatePricingDataAction(kv)),
  setRows: (rows) => dispatch(setRowsAction(rows)),
  resetRows: () => dispatch(resetRowsAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManualTableContainer);
