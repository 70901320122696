import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getCustomNames,
  headers,
  possibleNames,
  renderNames,
} from "./Standarization.utils";
import { ButtonEdit, StandarizationListingWrapper } from "./style";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import StandarizationTypeCards from "./StandarizationTypeCards";
import {
  removeStandarizationAction,
  setStandarizationAction,
} from "../../redux/actions/standarizationData.actions";
import GenericOverlay from "../GenericOverlay/GenericOverlay";
import StandarizationEditForm from "./StandarizationEditForm";
import HabiPagination from "../HabiPagination/habiPagination";
import { useQuery } from "@apollo/client";
import { getListStandardization } from "../../queries/queries";
import { LoadingContainer } from "../SelectedTable/style";
import DotsLoading from "../DotsLoading/dotsLoading";

const StandarizationListing = (props) => {
  const { setStandarization, removeStandarization, inputValue } = props;
  const ITEMS_BY_PAGE = 10;
  const [open, setOpen] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [pageData, setPageData] = useState(1);
  const handlePageChange = (newPage) => {
    setPageData(newPage);
  };
  const [paginator, setPaginator] = useState({
    currentPage: pageData,
    max_pages: 0,
    totalItemsCount: 0,
  });

  
  const { loading, data, refetch } = useQuery(
    getListStandardization({
      init: pageData,
      perPage: ITEMS_BY_PAGE,
      nid: inputValue ?? null,
    })
  );

  useEffect(() => {
    setDataTable(data?.getListStandardization?.result);
    setPaginator({
      currentPage: pageData,
      itemsCountPerPage: ITEMS_BY_PAGE,
      totalItemsCount: data?.getListStandardization.count,
    });
  }, [data, pageData]);

  useEffect(() => {
    // if (!open) removeStandarization();
  }, [setStandarization, removeStandarization, open]);

  return (
    <StandarizationListingWrapper>
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              {headers.map((item, index) => {
                return <th key={`${index}-${Math.random()}`}>{item}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {dataTable?.map((row) => (
              <tr key={`${row.nid}-${Math.random()}`}>
                <th>{Number(row.nid)}</th>
                <th>
                  <StandarizationTypeCards
                    typeStandarization={getCustomNames(row, possibleNames)}
                  />
                </th>
                <th>
                  <ButtonEdit
                    onClick={() => {
                      setOpen(!open);
                      setStandarization({
                        ...row,
                        customName: getCustomNames(row, renderNames),
                      });
                    }}
                  >
                    <EditRoundedIcon />
                  </ButtonEdit>
                </th>
              </tr>
            ))}
            {dataTable?.length === 0 &&
              <tr><th>No hay datos para mostrar</th></tr>
            }
          </tbody>
        </table>
        {loading && (
          <LoadingContainer>
            <DotsLoading />
          </LoadingContainer>
        )}
        {paginator?.totalItemsCount > paginator?.itemsCountPerPage && (
          <div className="paginator-container">
            <HabiPagination
              className="paginator"
              handlePageChange={handlePageChange}
              paginator={paginator}
            />
          </div>
        )}
      </div>
      <GenericOverlay
        openLeftModal={open}
        closeLeftModal={() => setOpen(!open)}
      >
        {open && (
          <StandarizationEditForm
            cancel={() => setOpen(!open)}
            refetch={refetch}
          />
        )}
      </GenericOverlay>
    </StandarizationListingWrapper>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setStandarization: (row) => dispatch(setStandarizationAction(row)),
  removeStandarization: () => dispatch(removeStandarizationAction()),
});

export default connect(null, mapDispatchToProps)(StandarizationListing);
