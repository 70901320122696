export const propertiesCountTypes = {
  SET_COUNT: "SET_COUNT",
  REMOVE_COUNT: "REMOVE_COUNT",
};

export const setPropertiesCountAction = (count) => ({
  type: propertiesCountTypes.SET_COUNT,
  payload: count,
});

export const removePropertiesCountAction = () => ({
  type: propertiesCountTypes.REMOVE_COUNT,
});
