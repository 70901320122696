import styled from "styled-components";
import { error, scarpaGray300, scarpaGray600 } from "../../StylesConstants";

export const CoefficientWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  .coefficient {
    text-align: center;
    color: ${scarpaGray600};
    border-left: 1px solid ${scarpaGray300};
    border-right: 1px solid ${scarpaGray300};
    .title-coefficient {
      font-weight: 600;
      margin-left: 1rem;
    }
    .value-coefficient {
      padding: 1rem;
    }
    .high-value {
      padding: 1rem;
      color: ${error};
    }
  }
`;
