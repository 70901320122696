import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import SquareDropdown from "../SquareDropdown/squareDropdown";
import { StyledButton, StyledButtonGroup, ToolWrapper } from "./style";
import SwitchButton from "../SwitchButton/switchButton";
import RoundedDropdown from "../RoundedDropdown/roundedDropdown";
import RadioButtonGroup from "../RadioButtonGroup/radioButtonGroup";
import RadioButton from "../RadioButton/radioButton";
import { Formik, Form, Field } from "formik";
import CloudDownloadRoundedIcon from "@material-ui/icons/CloudDownloadRounded";
import exportFromJSON from "export-from-json";
import moment from "moment";
import CheckBoxDropdown from "../CheckBoxDropdown/checkBoxDropdown";
import PieChartIcon from "@material-ui/icons/PieChart";
import { updatePricingDataAction } from "../../redux/actions/pricingData.actions";
import { selectPercetilTextValue } from "../../redux/selectors/pricingData.selectors";
import { selectRowsDataForMutations } from "../../redux/selectors/selectedRows.selectors";
import SquareCheckboxDropdown from "../SquareCheckboxDropdown/SquareCheckboxDropdown";
import { normalizationOptions } from "./selectedManualTable.utils";
import Button from "../ButtonV2/Button";
import { getStandardizationAll } from "../../services/pricingApi";
import {
  addManualRowDataAction,
  removeManualRowDataIdAction,
} from "../../redux/actions/manualRowData.actions";
import CoefficientInfo from "../CoefficientInfo/CoefficientInfo";
import { setCoefficientAction } from "../../redux/actions/coeffientRows.actions";

const SelectedTableToolBar = (props) => {
  const {
    optionsArea,
    setOptionArea,
    setFilterArea,
    textArea,
    setGarageFilter,
    garageFilter,
    setElevatorFilter,
    elevatorFilter,
    sourceOptions,
    setSourceName,
    sourceName,
    setSourceIds,
    sourceIds,
    statusOptions,
    setStatusName,
    statusName,
    setStatusIds,
    statusIds,
    optionsDateLead,
    setDateLeadName,
    dateLeadName,
    setFilterDate,
    optionsProperty,
    propertyName,
    setPropertyName,
    propertyIds,
    setPropertyIds,
    optionsYears,
    setOptionYears,
    textYears,
    setFilterYear,
    radioZona,
    externalZone,
    handleExternalZone,
    numSelected,
    title,
    percent,
    percentilTextValue,
    updatePricingData,
    rowsDataForMutations,
    garageOptions,
    garageName,
    setGarageName,
    setGarageIds,
    elevatorOptions,
    elevatorName,
    setElevatorName,
    setElevatorIds,
    setLoading,
    SetAllProperties,
  } = props;
  const { apartment, selectedRows } = useSelector((state) => state);
  const [normalize, setNormalize] = useState(["ninguno"]);
  const [close, setClose] = useState(false);
  const dispatch = useDispatch();

  const percentilOptions = {
    "Percentil 10": 10,
    "Percentil 20": 20,
    "Percentil 30": 30,
    "Percentil 40": 40,
    "Percentil 50": 50,
  };

  const handlePercentil = (value) =>
    updatePricingData({ percentil: percentilOptions[value] });

  const showNumSeleccionados =
    numSelected > 0 && title === "Comparables seleccionados manualmente";

  const handleStandarization = async (
    apartment,
    alldata,
    typeStandardization
  ) => {
    const data = {
      pricing: apartment,
      row: alldata,
      type_standardization: typeStandardization,
    };
    setLoading(true);
    getStandardizationAll(data).then((res) => {
      res.data.row.forEach((row) => {
        dispatch(
          addManualRowDataAction({
            rowId: row.id,
            data: {
              last_ask_price: row.last_ask_price,
              valormt2: row.valormt2,
              garajes: typeStandardization.includes("garajes")
                ? apartment.garajes
                : row.garajes,
              banos: typeStandardization.includes("banos")
                ? apartment.banos
                : row.banos,
              num_piso: typeStandardization.includes("num_piso")
                ? apartment.piso
                : typeStandardization.includes("height_premium")
                ? apartment.piso
                : row.num_piso,
              type_standardization: typeStandardization,
              area: typeStandardization.includes("area")
                ? apartment.area
                : row.area,
              height_premium: typeStandardization.includes("height_premium")
                ? true
                : false,
            },
          })
        );
      });
      setClose(true);
      setLoading(false);
      dispatch(setCoefficientAction(true));
    });
  };

  const handleCleanAll = (rows) => {
    setLoading(true);
    setNormalize(["ninguno"]);
    rows.forEach((row) => {
      dispatch(removeManualRowDataIdAction(row.id));
    });
    setLoading(false);
  };

  return (
    <ToolWrapper>
      <Toolbar className={showNumSeleccionados ? "active" : ""}>
        <Formik
          enableReinitialize
          initialValues={{
            radio_zona: radioZona,
            radio_zona_external: externalZone,
          }}
          onSubmit={(values) => console.log(values)}
        >
          {(propsFormik) => (
            <Form className="wrapper">
              <Typography variant="h6" id="tableTitle" component="div">
                {props.title}
              </Typography>

              {props.title === "Comparables en el conjunto" && (
                <div className="filters">
                  <SquareDropdown
                    text="Área"
                    option={optionsArea}
                    setOption={setOptionArea}
                    setFilter={setFilterArea}
                    title={textArea}
                    idDropdown="areaConjunto"
                  />
                  <SwitchButton
                    text="Garaje"
                    idButton="conjuntoGarajeId"
                    value={garageFilter}
                    handleValue={setGarageFilter}
                  />
                  <SwitchButton
                    text="Ascensor"
                    idButton="conjuntoAscensorId"
                    value={elevatorFilter}
                    handleValue={setElevatorFilter}
                  />
                  <CheckBoxDropdown
                    idDropdown="select-1"
                    text="Mostrar Fuentes:"
                    option={sourceOptions}
                    setOption={setSourceName}
                    title={sourceName}
                    setIds={setSourceIds}
                    ids={sourceIds}
                  />
                  <RoundedDropdown
                    idDropdown="select-lead-1"
                    text="Fecha de lead:"
                    option={optionsDateLead}
                    setOption={setDateLeadName}
                    title={dateLeadName}
                    setFilter={setFilterDate}
                  />
                  <CheckBoxDropdown
                    idDropdown="select-property-1"
                    text="Tipo Inmueble:"
                    option={optionsProperty}
                    setOption={setPropertyName}
                    title={propertyName}
                    setIds={setPropertyIds}
                    ids={propertyIds}
                  />
                  <CheckBoxDropdown
                    idDropdown="select-status-1"
                    text="Estado negocio:"
                    option={statusOptions}
                    setOption={setStatusName}
                    title={statusName}
                    setIds={setStatusIds}
                    ids={statusIds}
                  />
                </div>
              )}

              {props.title === "Comparables por zona" && (
                <div className="filters-zona">
                  <SquareDropdown
                    text="Área"
                    option={optionsArea}
                    setOption={setOptionArea}
                    setFilter={setFilterArea}
                    title={textArea}
                    idDropdown="areaZona"
                  />
                  <SquareDropdown
                    text="Antigüedad"
                    option={optionsYears}
                    setOption={setOptionYears}
                    setFilter={setFilterYear}
                    title={textYears}
                    idDropdown="yearsZona"
                  />
                  <SwitchButton
                    text="Garaje"
                    idButton="zonaGarajeId"
                    value={garageFilter}
                    handleValue={setGarageFilter}
                  />
                  <SwitchButton
                    text="Ascensor"
                    idButton="zonaAscensorId"
                    value={elevatorFilter}
                    handleValue={setElevatorFilter}
                  />
                  <CheckBoxDropdown
                    idDropdown="select"
                    text="Mostrar Fuentes:"
                    option={sourceOptions}
                    setOption={setSourceName}
                    title={sourceName}
                    setIds={setSourceIds}
                    ids={sourceIds}
                  />
                  <RoundedDropdown
                    idDropdown="select-lead"
                    text="Fecha de lead:"
                    option={optionsDateLead}
                    setOption={setDateLeadName}
                    title={dateLeadName}
                    setFilter={setFilterDate}
                  />
                  <CheckBoxDropdown
                    idDropdown="select-property"
                    text="Tipo Inmueble:"
                    option={optionsProperty}
                    setOption={setPropertyName}
                    title={propertyName}
                    setIds={setPropertyIds}
                    ids={propertyIds}
                  />
                  <CheckBoxDropdown
                    idDropdown="select-status-2"
                    text="Estado negocio:"
                    option={statusOptions}
                    setOption={setStatusName}
                    title={statusName}
                    setIds={setStatusIds}
                    ids={statusIds}
                  />
                </div>
              )}

              {props.title === "Comparables por otras fuentes" && (
                <div className="filters-zona">
                  <SquareDropdown
                    text="Área"
                    option={optionsArea}
                    setOption={setOptionArea}
                    setFilter={setFilterArea}
                    title={textArea}
                    idDropdown="areaZonaExternal"
                  />
                  <SquareDropdown
                    text="Antigüedad"
                    option={optionsYears}
                    setOption={setOptionYears}
                    setFilter={setFilterYear}
                    title={textYears}
                    idDropdown="yearsExternal"
                  />
                  <RoundedDropdown
                    idDropdown="select-garage-external"
                    text="Garajes:"
                    option={garageOptions}
                    setOption={setGarageName}
                    title={garageName}
                    setFilter={setGarageIds}
                  />
                  <RoundedDropdown
                    idDropdown="select-elevator-external"
                    text="Ascensores:"
                    option={elevatorOptions}
                    setOption={setElevatorName}
                    title={elevatorName}
                    setFilter={setElevatorIds}
                  />

                  <div className="radio-container">
                    <p className="title">Radio Zona</p>
                    <RadioButtonGroup
                      id="radio_zona"
                      label=""
                      value={propsFormik.values["radio_zona_external"]}
                      error={propsFormik.errors["radio_zona_external"]}
                      touched={propsFormik.touched["radio_zona_external"]}
                      setFieldValue={propsFormik.setFieldValue}
                    >
                      <Field
                        component={RadioButton}
                        name="radio_zona_external"
                        id="zona-250-external"
                        label="250 m"
                        value="250"
                        values={propsFormik.values}
                        onClick={() => {
                          handleExternalZone(250);
                        }}
                        error={propsFormik.errors["radio_zona_external"]}
                        touched={propsFormik.touched["radio_zona_external"]}
                        setFieldValue={propsFormik.setFieldValue}
                      />
                      <Field
                        component={RadioButton}
                        name="radio_zona_external"
                        id="zona-440-external"
                        label="440 m"
                        value="440"
                        values={propsFormik.values}
                        error={propsFormik.errors["radio_zona_external"]}
                        onClick={() => {
                          handleExternalZone(440);
                        }}
                        touched={propsFormik.touched["radio_zona_external"]}
                        setFieldValue={propsFormik.setFieldValue}
                      />
                    </RadioButtonGroup>
                  </div>
                  <CheckBoxDropdown
                    idDropdown="select-external"
                    text="Mostrar Fuentes:"
                    option={sourceOptions}
                    setOption={setSourceName}
                    title={sourceName}
                    setIds={setSourceIds}
                    ids={sourceIds}
                  />
                  <RoundedDropdown
                    idDropdown="select-lead-external"
                    text="Fecha de lead:"
                    option={optionsDateLead}
                    setOption={setDateLeadName}
                    title={dateLeadName}
                    setFilter={setFilterDate}
                  />
                  <CheckBoxDropdown
                    idDropdown="select-property-external"
                    text="Tipo Inmueble:"
                    option={optionsProperty}
                    setOption={setPropertyName}
                    title={propertyName}
                    setIds={setPropertyIds}
                    ids={propertyIds}
                  />
                </div>
              )}

              {showNumSeleccionados && (
                <Typography color="inherit" variant="subtitle1" component="div">
                  {props.numSelected} inmuebles seleccionados para comparación
                </Typography>
              )}

              {props.title === "Comparables seleccionados manualmente" && (
                <div className="manual-comparable">
                  <StyledButton
                    id="download-button"
                    className="download-button"
                    onClick={(e) => {
                      exportFromJSON({
                        data: rowsDataForMutations,
                        fileName: moment().format("lll"),
                        exportType: exportFromJSON.types.xls,
                      });
                    }}
                  >
                    <CloudDownloadRoundedIcon className="download-icon" />
                  </StyledButton>
                  <CoefficientInfo rowsDataForMutation={rowsDataForMutations}/>
                  <div className="sales">
                    <PieChartIcon className="donut-icon" />
                    <span className="title-sales">Ventas en conjunto:</span>
                    <span
                      className={percent >= 7 ? "greater-than" : "value-sales"}
                    >
                      {percent} %
                    </span>
                  </div>
                  <div className="percent">
                    <RoundedDropdown
                      idDropdown="select-precentil"
                      text="Percentil:"
                      option={percentilOptions}
                      title={percentilTextValue}
                      setOption={handlePercentil}
                    />
                    <div className="separator" />
                  </div>
                  <div className="standardization">
                    <span className="title-standardization">Normalizar:</span>
                    <SquareCheckboxDropdown
                      index="all"
                      isAll={true}
                      options={normalizationOptions}
                      defaultValues={normalize ?? ["ninguno"]}
                      onChange={(value) => {
                        if (normalize.includes("ninguno"))
                          setNormalize(["ninguno"]);
                        setNormalize(value);
                      }}
                      customCloseFn={setClose}
                      customClose={close}
                    >
                      <StyledButtonGroup>
                        <Button
                          fillDiv
                          label="Normalizar"
                          disabled={normalize.includes("ninguno")}
                          onClick={() => {
                            if (!normalize.includes("ninguno"))
                              handleStandarization(
                                apartment,
                                selectedRows,
                                normalize
                              );
                          }}
                        />
                        <Button
                          fillDiv
                          buttonType="secondary"
                          label="Limpiar"
                          onClick={() => {
                            setClose(true);
                            handleCleanAll(selectedRows);
                          }}
                        />
                        <Button
                          fillDiv
                          buttonType="secondary"
                          label="Cancelar"
                          onClick={() => {
                            setClose(true);
                            setNormalize(["ninguno"]);
                          }}
                        />
                      </StyledButtonGroup>
                    </SquareCheckboxDropdown>
                    <div className="separator" />
                  </div>
                  <Button
                    id="saveOutliersButton"
                    typeClass="primary"
                    className="d-inline-block"
                    label="Ver más"
                    type="submit"
                    onClick={(e) => {
                      SetAllProperties(true);
                    }}
                    disabled={rowsDataForMutations.length === 0 ?? false}
                  />
                </div>
              )}
            </Form>
          )}
        </Formik>
      </Toolbar>
    </ToolWrapper>
  );
};

SelectedTableToolBar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  percentilTextValue: selectPercetilTextValue(state),
  rowsDataForMutations: selectRowsDataForMutations(state),
});

const mapDispatchToProps = (dispatch) => ({
  updatePricingData: (kv) => dispatch(updatePricingDataAction(kv)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectedTableToolBar);
