import React, { useEffect } from "react";
import { RadioWrapper } from "./style";

const InputFeedback = ({ error }) =>
  error ? <div className="error">{error}</div> : null;

const RadioButtonGroup = ({
  value,
  error,
  touched,
  id,
  label,
  children,
  setFieldValue,
  ...props
}) => {
  useEffect(() => {
    if (value === undefined) {
      setFieldValue(props.default);
    }
  }, [value, setFieldValue, props]);

  return (
    <RadioWrapper>
      <fieldset id={id}>
        <legend>{label}</legend>
        <div className="switch-toggle">{children}</div>
        {touched && <InputFeedback error={error} />}
      </fieldset>
    </RadioWrapper>
  );
};

export default RadioButtonGroup;
