import { InputBase, withStyles } from "@material-ui/core";
import styled from "styled-components";
import {
  skyBlue000,
  scarpaGray000,
  electricPurple500,
  white,
  electricPurple700,
  scarpaGray600,
  scarpaGray200,
  brightTurquoise400,
  archiaRegular,
  brightTurquoise000,
  tomatoCoral500,
} from "../../StylesConstants";

export const ResultFieldsWrapper = styled.div`
  min-height: 156px;
  background: ${skyBlue000};
  padding: 32px 32px;
  margin-bottom: 60px;

  .input-price {
    color: ${scarpaGray600};
    padding: 14px 0;
    text-align: center;
    background-color: ${white};
    border: 1px solid ${scarpaGray200};
    border-radius: 8px;

    &:disabled {
      background-color: ${scarpaGray000};
    }

    &:focus {
      outline: none;
      border: 2px solid ${brightTurquoise400};
      padding: 13px 0;
    }
  }

  .field-container {
    display: grid;
    grid-template-columns: repeat(6, auto);
    .field-price {
      width: 200px;
      p {
        margin-bottom: 8px;
        font-weight: bold;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
      }
      label {
        margin-bottom: 0px;
      }
      .form-control.disabled {
        background: white;
      }
      .form-control.no-label input {
        background: white;
      }
    }

    #saveButton {
      margin: 35px 0 0 48px;
      width: 176px;
      height: 56px;
      text-align: center;
      &.primary {
        border: none;
        color: ${scarpaGray000};
        background-color: ${electricPurple500};
        span {
          color: ${scarpaGray000};
          vertical-align: middle;
        }
        &:hover {
          color: ${white};
          background-color: ${electricPurple700};
          span {
            color: ${white};
            vertical-align: middle;
          }
        }
      }
    }

    #saveOutliersButton {
      margin: 35px 0 0 48px;
      width: 176px;
      height: 56px;
      text-align: center;
      &.primary {
        border: none;
        color: ${scarpaGray000};
        background-color: ${electricPurple500};
        span {
          color: ${scarpaGray000};
          vertical-align: middle;
        }
        &:hover {
          color: ${white};
          background-color: ${electricPurple700};
          span {
            color: ${white};
            vertical-align: middle;
          }
        }
      }
    }
  }
  .field-container-final {
    .result,
    .result-final,
    .result-extra {
      display: grid;
      grid-template-columns: repeat(4, auto);
      margin-bottom: 24px;
      row-gap: 24px;
      .field-price {
        width: 200px;
        p {
          margin-bottom: 8px;
          font-weight: bold;
          font-size: 16px;
          line-height: 28px;
          text-align: center;
        }
        label {
          margin-bottom: 0px;
        }
        .form-control.disabled {
          background: white;
        }
        .form-control.no-label input {
          background: white;
        }
        #saveButton {
          width: 176px;
          height: 56px;
          text-align: center;
          &.primary {
            border: none;
            color: ${scarpaGray000};
            background-color: ${electricPurple500};
            span {
              color: ${scarpaGray000};
              vertical-align: middle;
            }
            &:hover {
              color: ${white};
              background-color: ${electricPurple700};
              span {
                color: ${white};
                vertical-align: middle;
              }
            }
          }
        }
      }
      .field-price-end {
        width: 230px;
      }
    }
  }
`;

export const StyledInputResult = withStyles(() => ({
  root: {
    backgroundColor: `${white}`,
    border: `1px solid ${scarpaGray200}`,
    borderRadius: "8px",
    padding: "14px 0",
    fontFamily: `${archiaRegular}`,
    fontSize: "14px",
    color: `${scarpaGray600}`,
    height: "54px",

    "&:hover:not($disabled)": {
      backgroundColor: `${brightTurquoise000}`,
      border: `2px solid ${brightTurquoise400}`,
    },

    "&:focus": {
      backgroundColor: `${brightTurquoise000}`,
      boxShadow: "none",
      border: `2px solid ${brightTurquoise400}`,
    },
  },
  disabled: {
    backgroundColor: `${scarpaGray000}`,
  },
  input: {
    textAlign: "center",
    "&[type=number]": {
      "-moz-appearance": "textfield",
    },
    "&::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    underline: {
      "&:before": {
        content: "none",
      },
      "&:after": {
        content: "none",
      },
    },
  },
  error: {
    border: `2px solid ${tomatoCoral500}`,
  },
}))(InputBase);
