import React from "react";
import CalendarFilter from "./calendarFilter";

const CalendarFilterContainer = ({ label, selectedDayRange, setSelectedDayRange, ...props }) => {
  const customLocale = {
    months: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ],

    weekDays: [
      {
        name: "Domingo",
        short: "D",
      },
      {
        name: "Lunes",
        short: "L",
      },
      {
        name: "Martes",
        short: "M",
      },
      {
        name: "Miércoles",
        short: "X",
      },
      {
        name: "Jueves",
        short: "J",
      },
      {
        name: "Viernes",
        short: "V",
      },
      {
        name: "Sábado",
        short: "S",
      },
    ],

    weekStartingIndex: 0,

    getToday(gregorainTodayObject) {
      return gregorainTodayObject;
    },

    toNativeDate(date) {
      return new Date(date.year, date.month - 1, date.day);
    },

    getMonthLength(date) {
      return new Date(date.year, date.month, 0).getDate();
    },

    transformDigit(digit) {
      return digit;
    },

    defaultPlaceholder: "Seleccionar",
  };

  return (
    <CalendarFilter
      label={label}
      selectedDayRange={selectedDayRange}
      setSelectedDayRange={setSelectedDayRange}
      customLocale={customLocale}
    />
  );
};

export default CalendarFilterContainer;
