import styled from "styled-components";
import { scarpaGray100, scarpaGray900, white } from "../../StylesConstants";

// top: -6px;
// right: 49px;

export const HabiLogoContainer = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1;

  img {
    position: absolute;
    width: 46px;
    height: 43px;
    margin: 18px 24px;
  }
`;

export const CerrarX = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 1;
  padding: 12px;

  div {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: ${white};
    border-radius: 50%;
    cursor: pointer;
    color: ${scarpaGray900};
    font-size: 32px;
    margin: 16px;
    top: -6px;
    right: -6px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    &:hover {
      background-color: ${scarpaGray100};
    }
  }
`;
