const HourFormat = (date) => {
  const formatDate = new Date(date);
  return formatDate.toLocaleString("es-CO", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
};
const MonthYearFormat = (date) => {
  if (!date) return null;
  const formatDate = new Date(date);
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const day = formatDate.getUTCDate();
  const month = months[formatDate.getUTCMonth()];
  const year = formatDate.getUTCFullYear();
  return `${month} ${day},  ${year}`;
};
export { HourFormat, MonthYearFormat };
