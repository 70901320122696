export const pipe = (...fns) => (x) => {
  const [head, ...tail] = fns;
  const res = head(x);
  return tail.length > 0 ? pipe(...tail)(res) : res;
};

export const map = (fn) => (x) => x.map(fn);

export const head = (arr) => arr.slice(0, 1)[0];

export const tail = (arr) => arr.slice(1, arr.length);

export const decoupleHead = (arr) => [head(arr), tail(arr)];

export const evalPredicates = (...fns) => (x) => {
  const [head, tail] = decoupleHead(fns);
  return tail.length === 0
    ? head(x)
    : head(x)
    ? evalPredicates(...tail)(x)
    : false;
};

export const filter = (...fns) => (x) => {
  return fns.length === 1 ? x.filter(fns[0]) : x.filter(evalPredicates(...fns));
};

export const logger = (x) => {
  console.log(x);
  return x;
};

export const setObjectNullsToZero = (obj) => {
  const newObj = { ...obj };
  for (const key in newObj) {
    if (newObj[key] === null) newObj[key] = 0;
  }
  return newObj;
};

export const createPolygonWKT = (pointArr) => {
  const polygonToStr = pointArr.reduce(
    (acc, point) => `${acc} ${point.lng} ${point.lat},`,
    ""
  );
  const firstPoint = pointArr[0];
  const polygonWKT = `"${polygonToStr} ${firstPoint.lng} ${firstPoint.lat}"`;
  return polygonWKT;
};

export const range = (end, start, step = 1) => {
  if (!start) return [...Array(end).keys()];

  const length = Math.ceil((end - start) / step);

  const arr = [...Array(length).keys()];

  return arr.map((x) => x + start + x * (step - 1));
};

export const getKeyByValue = (obj, val) => {
  return Object.keys(obj).find((key) => obj[key] === val);
};
