import React from "react";
import DotsLoading from "../../DotsLoading/dotsLoading";

import {
  CardContainer,
  DataContainer,
  FuenteContainer,
  FuenteLabel,
  CountLabel,
  LoadingContainer,
  ButtonContainer,
  StyledButton,
} from "./ComparablesSummary.style";

const ComparablesSummary = ({
  buttonRef,
  selectPolygon,
  loading,
  error,
  sources,
}) => (
  <CardContainer>
    <h1>Comparables</h1>
    <DataContainer>
      {sources.map((data) => (
        <FuenteContainer key={data.fuente}>
          <div className="source-label">
            <FuenteLabel>{data.fuente}</FuenteLabel>
          </div>
          <div className="counters">
            <CountLabel>{data.count}</CountLabel>
            <CountLabel className="filtered">{data.countFiltered || 0}</CountLabel>
          </div>
        </FuenteContainer>
      ))}
      {error && <pre>{JSON.stringify(error, null, 2)}</pre>}
      {loading && (
        <LoadingContainer>
          <DotsLoading />
        </LoadingContainer>
      )}
    </DataContainer>
    <ButtonContainer>
      <StyledButton
        id="seleccionar"
        label="Seleccionar área"
        type="button"
        onClick={() => selectPolygon()}
      />
      <StyledButton
        id="cancelar"
        label="Cancelar selección"
        type="button"
        onClick={() => buttonRef.current.click()}
      />
    </ButtonContainer>
  </CardContainer>
);

export default ComparablesSummary;
