import React, { useState }  from "react";
import { 
  connect,
  useSelector 
}                           from "react-redux";
import { makeStyles }       from "@material-ui/core/styles";
import { 
  useMutation
}                           from "@apollo/client";
import DotsLoading          from "../DotsLoading/dotsLoading";
import Table                from "@material-ui/core/Table";
import TableBody            from "@material-ui/core/TableBody";
import TableCell            from "@material-ui/core/TableCell";
import TableContainer       from "@material-ui/core/TableContainer";
import TablePagination      from "@material-ui/core/TablePagination";
import TableRow             from "@material-ui/core/TableRow";
import Paper                from "@material-ui/core/Paper";
import Checkbox             from "@material-ui/core/Checkbox";
import SelectedTableHead    from "./selectedTableHead";
import FlagRoundedIcon      from "@material-ui/icons/FlagRounded";
import { 
  addFlagRowDataAction 
}                           from "../../redux/actions/flagSelectionData.actions";
import { 
  selectSelectedRows 
}                           from "../../redux/selectors/selectedRows.selectors";
import { 
  getComparator, 
  stableSort 
}                           from "../../utils/sorting";
import { 
  LoadingContainer, 
  NoResultsContainer, 
  FlagContainer 
}                           from "./style";
import {
  saveHabiFlagData,
}                           from "../../queries/queries";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
  },
}));

const SelectedTable = ({
  rows,
  title,
  rowSelectionHandler,
  selectedRows,
  addManyRowsHandler,
  children,
  loading,
  error,
  addFlagRow,
  rowsFlag,
  type,
}) => {

  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);


  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const checkIfSelected = (id) =>
    selectedRows.filter((row) => row.id === id).length === 1 ? true : false;

  const sortedAndFilteredRows = stableSort(
    rows,
    getComparator(order, orderBy)
  ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const getSelectedRowsFromPage = () => {
    const selectedRowsIds = selectedRows.map((row) => row.id);
    const bySelectedRowsIds = (row) => selectedRowsIds.includes(row.id);
    return sortedAndFilteredRows.filter(bySelectedRowsIds);
  };

  const checkSelectedByType = (selected, type) =>
    selected.filter((row) => row.tipo_consulta === type).length;

  const isAllSelected =
    selectedRows.length !== 0 &&
    rows.length !== 0 &&
    checkSelectedByType(selectedRows, type) === rows.length;

  const selectedRowsFromPage = getSelectedRowsFromPage();

  const partialRowsSelected =
    sortedAndFilteredRows.length > 0 &&
    selectedRowsFromPage.length === sortedAndFilteredRows.length
      ? true
      : false;

  const noRowsSelected = selectedRowsFromPage.length === 0;

  const someRowsSelected =
    selectedRowsFromPage.length > 0 && !partialRowsSelected;

  const selectManyHandlerConfig = {
    rows: sortedAndFilteredRows,
    noRowsSelected: noRowsSelected,
  };

  const results = rows.length > 0;

  const [saveHabiFlag]  = useMutation(saveHabiFlagData);
  const { userData }    = useSelector((state) => state); 

  const saveDataOutliers = (objectFlag, id) => {

    let objectData        = {};
    let firstKey          = id;
    let firstKeyValue     = objectFlag;
    objectData[firstKey]  = firstKeyValue;

    saveHabiFlag({
      variables: {
        rowsFlag: JSON.stringify(objectData) || "",
        agent: userData.email,
      },
    }).catch((err) => {
      console.log(`Error in saveDataOutliers saveHabiFlag: ${err.message}`);
    });
  };

  const getFlagProperty = (id, name, value, varname) => {
    let tempObj = {};
    if (rowsFlag?.[id]?.[varname] === 1) {
      tempObj = {
        [name]: null,
        [varname]: 2,
      };
    } else {
      tempObj = {
        [name]: value,
        [varname]: 1,
      };
    }
    let objectFlag = { 
      rowId: id, 
      data: tempObj 
    }    
    addFlagRow(objectFlag);
    saveDataOutliers(tempObj, id);
  };
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {children}
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <SelectedTableHead
              handleAllClicked={addManyRowsHandler({
                rows: rows,
                noRowsSelected: noRowsSelected,
              })}
              allRowsSelected={isAllSelected}
              classes={classes}
              numSelected={selectedRows.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              title={title}
              addManyRowsHandler={addManyRowsHandler(selectManyHandlerConfig)}
              partialRowsSelected={partialRowsSelected}
              someRowsSelected={someRowsSelected}
            />
            {title !== "Comparables por otras fuentes" ? (
              <TableBody>
                {sortedAndFilteredRows.map((row, index) => {
                  const isItemSelected = checkIfSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={labelId}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                          onClick={() =>
                            rowSelectionHandler(row, isItemSelected)
                          }
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.area ?? 0}
                        <FlagContainer
                          onClick={() =>
                            getFlagProperty(
                              row.id,
                              "area_flag",
                              row.area,
                              "varareaFlag"
                            )
                          }
                        >
                          <FlagRoundedIcon
                            className={`${
                              rowsFlag?.[row.id]?.["varareaFlag"] === 1
                                ? "activeFlag"
                                : "inactiveFlag"
                            }`}
                            fontSize="small"
                          />
                        </FlagContainer>
                      </TableCell>
                      <TableCell align="right">
                        {row.area_catastro ?? "N/A"}
                      </TableCell>
                      <TableCell align="right">
                        {row.garajes ?? "N/A"}
                        <FlagContainer
                          onClick={() =>
                            
                            getFlagProperty(
                              row.id,
                              "garaje_flag",
                              row.garajes,
                              "vargarajeFlag"
                            )
                          }
                        >
                          <FlagRoundedIcon
                            className={`${
                              rowsFlag?.[row.id]?.["vargarajeFlag"] === 1
                                ? "activeFlag"
                                : "inactiveFlag"
                            }`}
                            fontSize="small"
                          />
                        </FlagContainer>
                      </TableCell>
                      <TableCell align="right">
                        {row.banos ?? "N/A"}
                        <FlagContainer
                          onClick={() =>
                            getFlagProperty(
                              row.id,
                              "bano_flag",
                              row.banos,
                              "varbanoFlag"
                            )
                          }
                        >
                          <FlagRoundedIcon
                            className={`${
                              rowsFlag?.[row.id]?.["varbanoFlag"] === 1
                                ? "activeFlag"
                                : "inactiveFlag"
                            }`}
                            fontSize="small"
                          />
                        </FlagContainer>
                      </TableCell>
                      <TableCell align="right">
                        {row.num_piso ?? "N/A"}
                        <FlagContainer
                          onClick={() =>
                            getFlagProperty(
                              row.id,
                              "piso_flag",
                              row.num_piso,
                              "varpisoFlag"
                            )
                          }
                        >
                          <FlagRoundedIcon
                            className={`${
                              rowsFlag?.[row.id]?.["varpisoFlag"] === 1
                                ? "activeFlag"
                                : "inactiveFlag"
                            }`}
                            fontSize="small"
                          />
                        </FlagContainer>
                      </TableCell>
                      <TableCell align="right">
                        {row.num_ascensores ?? "N/A"}
                        <FlagContainer
                          onClick={() =>
                            getFlagProperty(
                              row.id,
                              "ascensor_flag",
                              row.num_ascensores,
                              "varascensorFlag"
                            )
                          }
                        >
                          <FlagRoundedIcon
                            className={`${
                              rowsFlag?.[row.id]?.["varascensorFlag"] === 1
                                ? "activeFlag"
                                : "inactiveFlag"
                            }`}
                            fontSize="small"
                          />
                        </FlagContainer>
                      </TableCell>
                      <TableCell align="right">
                        {row.anos_antiguedad ?? "N/A"}
                        <FlagContainer
                          onClick={() =>
                            getFlagProperty(
                              row.id,
                              "antiguedad_flag",
                              row.anos_antiguedad,
                              "varantiguedadFlag"
                            )
                          }
                        >
                          <FlagRoundedIcon
                            className={`${
                              rowsFlag?.[row.id]?.["varantiguedadFlag"] === 1
                                ? "activeFlag"
                                : "inactiveFlag"
                            }`}
                            fontSize="small"
                          />
                        </FlagContainer>
                      </TableCell>
                      <TableCell align="right">
                        {title === "Comparables por zona"
                          ? row.conjunto_edificio
                            ? row.conjunto_edificio
                            : "Sin nombre"
                          : row.id}
                        {title === "Comparables por zona" ? (
                          <FlagContainer
                            onClick={() =>
                              getFlagProperty(
                                row.id,
                                "conjunto_edificio_flag",
                                row.conjunto_edificio,
                                "varconjuntoEdificioFlag"
                              )
                            }
                          >
                            <FlagRoundedIcon
                              className={`${
                                rowsFlag?.[row.id]?.[
                                  "varconjuntoEdificioFlag"
                                ] === 1
                                  ? "activeFlag"
                                  : "inactiveFlag"
                              }`}
                              fontSize="small"
                            />
                          </FlagContainer>
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell align="center">{row.fuente}</TableCell>
                      <TableCell align="right">
                        {row.label_inmueble}
                        <FlagContainer
                          onClick={() =>
                            getFlagProperty(
                              row.id,
                              "tipo_inmueble_id_flag",
                              row.tipo_inmueble_id,
                              "vartipoInmuebleIdFlag"
                            )
                          }
                        >
                          <FlagRoundedIcon
                            className={`${
                              rowsFlag?.[row.id]?.["vartipoInmuebleIdFlag"] ===
                              1
                                ? "activeFlag"
                                : "inactiveFlag"
                            }`}
                            fontSize="small"
                          />
                        </FlagContainer>
                      </TableCell>
                      <TableCell align="right">
                        {"$ " +
                          new Intl.NumberFormat().format(
                            row.last_ask_price ?? 0
                          )}
                          </TableCell>
                      <TableCell align="right">
                        {"$ " +
                          new Intl.NumberFormat().format(row.valormt2 ?? 0)}
                        <FlagContainer
                          onClick={() =>
                            getFlagProperty(
                              row.id,
                              "valorm2_flag",
                              row.valormt2,
                              "varvalorM2Flag"
                            )
                          }
                        >
                          <FlagRoundedIcon
                            className={`${
                              rowsFlag?.[row.id]?.["varvalorM2Flag"] === 1
                                ? "activeFlag"
                                : "inactiveFlag"
                            }`}
                            fontSize="small"
                          />
                        </FlagContainer>
                      </TableCell>
                      <TableCell align="right">
                        {row.obra_gris === 1
                          ? "Si"
                          : row.obra_gris === 0
                          ? "No"
                          : "N/A"}
                      </TableCell>
                      <TableCell align="right">
                        {row.piscina === 1
                          ? "Si"
                          : row.piscina === 0
                          ? "No"
                          : "N/A"}
                      </TableCell>
                      <TableCell align="right">
                        {row.deposito === 1
                          ? "Si"
                          : row.deposito === 0
                          ? "No"
                          : "N/A"}
                      </TableCell>
                      <TableCell align="right">
                        {row.vis_type}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <TableBody>
                {sortedAndFilteredRows.map((row, index) => {
                  const isItemSelected = checkIfSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={labelId}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                          onClick={() =>
                            rowSelectionHandler(row, isItemSelected)
                          }
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.area ?? 0}
                      </TableCell>
                      <TableCell align="right">
                        {row.garajes ?? "N/A"}
                      </TableCell>
                      <TableCell align="right">{row.banos ?? "N/A"}</TableCell>
                      <TableCell align="right">
                        {row.num_piso ?? "N/A"}
                      </TableCell>
                      <TableCell align="right">
                        {row.num_ascensores ?? "N/A"}
                      </TableCell>
                      <TableCell align="right">
                        {row.anos_antiguedad ?? "N/A"}
                      </TableCell>
                      <TableCell align="right">
                        {row.id && (
                          <a
                            href={row.url_external}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {row.id}
                          </a>
                        )}
                      </TableCell>
                      <TableCell align="right">{row.fuente}</TableCell>
                      <TableCell align="right">{row.label_inmueble}</TableCell>
                      <TableCell align="right">
                          {"$ " +
                          new Intl.NumberFormat().format(
                            row.last_ask_price ?? 0
                          )}
                      </TableCell>
                      <TableCell align="right">
                        {"$ " +
                          new Intl.NumberFormat().format(
                            row.valormt2 ? row.valormt2.toFixed(0) : 0
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {loading && (
          <LoadingContainer>
            <DotsLoading />
          </LoadingContainer>
        )}
        {error && (
          <LoadingContainer>
            <pre>{JSON.stringify(error, null, 2)}</pre>
          </LoadingContainer>
        )}
        {results && (
          <TablePagination
            rowsPerPageOptions={[5]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
        {!results && !loading && (
          <NoResultsContainer>
            <p className="top-message">
              No hay inmuebles {title} con estas características.
            </p>
            <p className="bottom-message">Intenta cambiando los filtros.</p>
          </NoResultsContainer>
        )}
      </Paper>
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectedRows: selectSelectedRows(state),
  rowsFlag: state.flagRowData,
});

const mapDispatchToProps = (dispatch) => ({
  addFlagRow: (fieldData) => dispatch(addFlagRowDataAction(fieldData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectedTable);
