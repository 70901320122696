export const warningsActionTypes = {
  SET_WARNINGS: "SET_WARNINGS",
  SET_WARNINGS_AND_IDS: "SET_WARNINGS_AND_IDS",
  RESET_WARNINGS: "RESET_WARNINGS",
};

export const setWarningsAction = (warningMessages) => ({
  type: warningsActionTypes.SET_WARNINGS,
  payload: warningMessages,
});

export const setWarningsAndIdsAction = ({ warningMessages, rowIds }) => ({
  type: warningsActionTypes.SET_WARNINGS_AND_IDS,
  payload: { warningMessages, rowIds },
});

export const resetWarningsAction = () => ({
  type: warningsActionTypes.RESET_WARNINGS,
});
