import React from "react";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import { LogCommentWrapper } from "./style";
import { MonthYearFormat } from "../../utils/dateFormat";
import Button from "../Button/button";
import CloudDownloadRounded from "@material-ui/icons/CloudDownloadRounded";

const LogComment = (props) => (
  <LogCommentWrapper>
    <Timeline>
      {props.commentaries &&
        props.commentaries.map((comment, i) => (
          <TimelineItem key={i}>
            <TimelineSeparator>
              <TimelineDot variant="outlined" />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <div className="date-comment">
                {MonthYearFormat(comment.date)}
              </div>
              {comment.url ? (
                <div className="text-comment">
                  <a href={comment.url}>
                    <Button
                      id="button"
                      typeClass="primary"
                      className="comment-button"
                      label="Descargar"
                      type="button"
                    >
                      <CloudDownloadRounded className="icon-cloud" />
                    </Button>
                  </a>
                </div>
              ) : (
                <div className="text-comment">{comment.commentary}</div>
              )}
            </TimelineContent>
          </TimelineItem>
        ))}
    </Timeline>
  </LogCommentWrapper>
);

export default LogComment;
