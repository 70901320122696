import React, { Fragment } from "react";
import { CalculateAmountsWrapper } from "./style";
import Button from "../Button/button";
import LoadPhotos from "../LoadPhotos/loadPhotos";
import { useSelector } from "react-redux";

const CalculateAmounts = ({ isValid, ...props }) => {
  const { userData } = useSelector((state) => state);

  return (
    <CalculateAmountsWrapper>
      {!props.flagTab && (
        <Fragment>
          <Button
            id="button"
            typeClass={isValid ? "disabled" : "primary"}
            className="d-inline-block"
            label="Calcular precio por comparables"
            type="button"
            onClick={() => (isValid ? "" : props.calculate("comparables"))}
          />
          <Button
            id="button"
            typeClass={isValid ? "disabled" : "primary"}
            className="d-inline-block"
            label="Calcular precio por menor valor m²"
            type="button"
            onClick={() => (isValid ? "" : props.calculate("meters"))}
          />
        </Fragment>
      )}
      {props.flagTab && (
        <LoadPhotos inmuebleId={props.inmuebleId} agent={props.agent} />
      )}
      {!props.flagTab && userData.role !== "ventas" && (
        <Fragment>
          <Button
            id="button"
            typeClass="primary"
            className="d-inline-block"
            label="Calcular manualmente"
            type="button"
            onClick={() => props.calculate("manually")}
          />
          <Button
            id="button"
            typeClass="primary"
            className="d-inline-block"
            label="Inmueble No Habi"
            type="button"
            onClick={() => props.calculate("nohabi")}
          />
          <Button
            id="button"
            typeClass="primary"
            className="d-inline-block"
            label="No se puede pricear"
            type="button"
            onClick={() => props.calculate("impossible")}
          />
        </Fragment>
      )}
    </CalculateAmountsWrapper>
  );
};

export default CalculateAmounts;
