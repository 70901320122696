export const selectedRowsActionTypes = {
  SET_ROWS: "SET_ROWS",
  ADD_ZONE_ROW: "ADD_ZONE_ROW",
  ADD_MANY_ZONE_ROWS: "ADD_MANY_ZONE_ROWS",
  ADD_LOT_ROW: "ADD_LOT_ROW",
  ADD_MANY_LOT_ROWS: "ADD_MANY_LOT_ROWS",
  ADD_ROW_EXTERNAL: "ADD_ROW_EXTERNAL",
  ADD_MANY_EXTERNAL_ROWS: "ADD_MANY_EXTERNAL_ROWS",
  REMOVE_ALL_EXTERNAL_ROWS: "REMOVE_ALL_EXTERNAL_ROWS",
  REMOVE_ROW: "REMOVE_ROW",
  REMOVE_MANY_ROWS: "REMOVE_MANY_ROWS",
  REMOVE_ALL_ZONE_ROWS: "REMOVE_ALL_ZONE_ROWS",
  REMOVE_ALL_LOT_ROWS: "REMOVE_ALL_LOT_ROWS",
  REMOVE_ROWS_BY_IDS: "REMOVE_ROWS_BY_IDS",
  RESET_ROWS: "RESET_ROWS",
};

export const setRowsAction = (rows) => ({
  type: selectedRowsActionTypes.SET_ROWS,
  payload: rows,
});

export const addZoneRowAction = (row) => ({
  type: selectedRowsActionTypes.ADD_ZONE_ROW,
  payload: row,
});

export const addLotRowAction = (row) => ({
  type: selectedRowsActionTypes.ADD_LOT_ROW,
  payload: row,
});

export const addExternalRowAction = (row) => ({
  type: selectedRowsActionTypes.ADD_ROW_EXTERNAL,
  payload: row,
});

export const addManyExternalRowAction = (row) => ({
  type: selectedRowsActionTypes.ADD_MANY_EXTERNAL_ROWS,
  payload: row,
});

export const removeAllExternalRowsAction = () => ({
  type: selectedRowsActionTypes.REMOVE_ALL_EXTERNAL_ROWS,
});

export const addManyRowsZoneAction = (rows) => ({
  type: selectedRowsActionTypes.ADD_MANY_ZONE_ROWS,
  payload: rows,
});

export const addManyRowsLotAction = (rows) => ({
  type: selectedRowsActionTypes.ADD_MANY_LOT_ROWS,
  payload: rows,
});

export const removeRowAction = (id) => ({
  type: selectedRowsActionTypes.REMOVE_ROW,
  payload: id,
});

export const removeManyRowsAction = (rows) => ({
  type: selectedRowsActionTypes.REMOVE_MANY_ROWS,
  payload: rows,
});

export const removeAllZoneRowsAction = () => ({
  type: selectedRowsActionTypes.REMOVE_ALL_ZONE_ROWS,
});

export const removeAllLotRowsAction = () => ({
  type: selectedRowsActionTypes.REMOVE_ALL_LOT_ROWS,
});

export const removeRowsByIdsAction = (rowIds) => ({
  type: selectedRowsActionTypes.REMOVE_ROWS_BY_IDS,
  payload: rowIds,
});

export const resetRowsAction = (id) => ({
  type: selectedRowsActionTypes.RESET_ROWS,
});
