export const apartmentActionTypes = {
  SET_APARTMENT: "SET_APARTMENT",
  REMOVE_APARTMENT: "REMOVE_APARTMENT",
};

export const setApartmentAction = (apartment) => ({
  type: apartmentActionTypes.SET_APARTMENT,
  payload: apartment,
});

export const removeApartmentAction = () => ({
  type: apartmentActionTypes.REMOVE_APARTMENT,
});
