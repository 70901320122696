import { useState } from "react";
import  React  from "react";
import StarSelected from "../../static/assets/icons/starSelectedVector.svg";
import StarClear from "../../static/assets/icons/starClearVector.svg";
import { range } from "../../utils/generalUtils";

import { StarContainer, ErrorMessage } from "./StarRankingSelector.style";

const StarRankingSelector = ({
  number,
  handleChange,
  name,
  errors,
  defaultValue,
  size = "44px",
  separation = "20px",
  values,
}) => {
  const [selected, setSelected] = useState(defaultValue);

  const handleClick = (starValue) => {
    setSelected(starValue);
    handleChange(starValue, name);
  };

  const createSeparation = (separation) => {
    const number = +separation.split("p")[0];
    const marginSides = Math.round(number / 2);
    const marginStyle = `0 ${marginSides}px`;
    return marginStyle;
  };

  const sharedProps = {
    style: {
      margin: createSeparation(separation),
      width: size,
      height: size,
    },
  };

  const starValues = values ?? range(number + 1, 1);

  return (
    <>
      <StarContainer>
        {starValues.map((value) =>
          selected >= value ? (
            <img
              alt={`selected star with value ${value}`}
              key={value}
              src={StarSelected}
              onClick={() => handleClick(value)}
              {...sharedProps}
            />
          ) : (
            <img
              alt={`unselected star with value ${value}`}
              key={value}
              src={StarClear}
              onClick={() => handleClick(value)}
              {...sharedProps}
            />
          )
        )}
      </StarContainer>
      {errors && (
        <ErrorMessage>
          {typeof errors === "object" ? errors[name] : errors}
        </ErrorMessage>
      )}
    </>
  );
};

export default StarRankingSelector;
