export const warningKeys = {
  OVER_20_PERCENT_VARIATION: 1,
  COEFICIENT_OVER_10: 2,
  LESS_THAN_7_COMPARABLES: 3,
  HALF_COMPARABLES_BY_ZONE: 4,
  PERCENTAGE_SALES_OVER_7: 5,
  POSSIBLE_LAST_FLOOR_NO_ELEVATOR: 6,
  DIFFERENT_GARAGE_NUMBER: 7,
  DIFFERENT_BATHROOM_NUMBER: 8,
  COMPARING_HOUSE_TO_APTO: 9,
  COMPARING_APTO_TO_HOUSE: 10,
  NO_ELEVATOR: 11,
  SELECTED_MANUALLY_LESS_WEIGHT: 12,
  SELECTED_MANUALLY_HAS_NO_WEIGHT: 13,
};

export const warnings = new Map([
  [
    warningKeys.OVER_20_PERCENT_VARIATION,
    {
      message:
        "El valor de tu inmueble varía más de 20% con respecto al ask price.",
    },
  ],
  [
    warningKeys.COEFICIENT_OVER_10,
    {
      message:
        "Tus comparables tienen un coeficiente de variación superior al 10%",
    },
  ],
  [warningKeys.LESS_THAN_7_COMPARABLES, { message: "", question: "" }],
  [
    warningKeys.HALF_COMPARABLES_BY_ZONE,
    {
      message: "La mayoría de tus comparables son de zona.",
      question: "¿Estás seguro que no hay más?",
    },
  ],
  [
    warningKeys.PERCENTAGE_SALES_OVER_7,
    {
      message: "El porcentaje en venta del conjunto excede el 7%",
      question: "¿Seguro seleccionaste el percentil correcto para pricing?",
    },
  ],
  [warningKeys.POSSIBLE_LAST_FLOOR_NO_ELEVATOR, { message: "", question: "" }],
  [
    warningKeys.DIFFERENT_GARAGE_NUMBER,
    {
      message:
        "Algunos de tus comparables tienen un número de parqueaderos diferente a los del inmueble que quieres valorar.",
      question: "¿Estás seguro de que realizaste la normalziación?",
    },
  ],
  [
    warningKeys.DIFFERENT_BATHROOM_NUMBER,
    {
      message:
        "Tus comparables tienen un número de baños diferente a los del inmueble que quieres valorar.",
      question: "¿Estás seguro de que realizaste la normalziación?",
    },
  ],
  [
    warningKeys.COMPARING_HOUSE_TO_APTO,
    {
      message:
        "Estás valorando una casa y parece que has seleccionado algún apartamento.",
      question: "¿Estás seguro que tus comparables son todos casas?",
    },
  ],
  [
    warningKeys.COMPARING_APTO_TO_HOUSE,
    {
      message:
        "Estás valorando un apartamento y parece que has seleccionado alguna casa.",
      question: "¿Estás seguro que tus comparables son todos apartamentos?",
    },
  ],
  [
    warningKeys.NO_ELEVATOR,
    {
      message: "El inmueble que quieres valorar no tiene ascensor.",
      question: "¿Estás seguro de que realizaste normalización por piso?",
    },
  ],
  [
    warningKeys.SELECTED_MANUALLY_LESS_WEIGHT,
    {
      message: "",
    },
  ],
  [
    warningKeys.SELECTED_MANUALLY_HAS_NO_WEIGHT,
    {
      message:
        "Al menos uno de tus comparables no cuenta con una descripción de calidad (peso).",
    },
  ],
]);
