import styled from "styled-components";
import {
  scarpaGray700,
  brightTurquoise000,
  electricPurple500,
  bogotaBlue100,
  error,
  brightTurquoise600,
  tomatoCoral000,
} from "../../StylesConstants";

export const ComparableWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-areas:
    "table"
    "card-5"
    "card-1"
    "card-2"
    "already-priced"
    "card-3"
    "card-4"
    "typology-list"
    "buttons"
    "pricing-area"
    "comments";
  row-gap: 40px;

  .fixed-table {
    grid-area: table;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
    tbody tr th {
      background-color: ${bogotaBlue100};
    }
  }

  .lead-table {
    grid-area: card-5;
  }

  .comp-zona {
    grid-area: card-2;
    padding: 0px 24px;
    .MuiTableCell-root {
      font-size: 12px;
    }
  }

  .comp-conjunto {
    grid-area: card-1;
    padding: 0px 24px;
    .MuiTableCell-root {
      font-size: 12px;
    }
  }

  .already-priced {
    grid-area: already-priced;
    padding: 0px 24px;
    .MuiTableCell-root {
      font-size: 12px;
    }
  }

  .comp-external {
    grid-area: card-3;
    padding: 0px 24px;
    .MuiTableCell-root {
      font-size: 12px;
    }
  }

  .manual-selection {
    grid-area: card-4;
    padding: 0px 24px;
    overflow-x: auto;

    .row-to-delete {
      background: ${tomatoCoral000};
    }

    .MuiTableCell-root {
      font-size: 12px;
    }
    .greater-than {
      color: ${brightTurquoise600};
    }
    .less-than {
      color: ${error};
    }
  }

  .typology-list {
    grid-area: typology-list;
    padding: 0px 24px;
    .MuiTableCell-root {
      font-size: 12px;
    }
  }

  .buttons {
    grid-area: buttons;
  }

  .comments {
    grid-area: comments;
    padding: 0px 24px;
  }

  .pricing-area {
    grid-area: pricing-area;
    padding: 0px 24px;
  }
  .makeStyles-highlight-6 {
    color: ${scarpaGray700};
    background-color: ${brightTurquoise000};
  }
  .MuiTableRow-root.Mui-selected,
  .MuiTableRow-root.Mui-selected:hover {
    background-color: ${bogotaBlue100};
  }
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: ${electricPurple500};
  }
  .MuiTableCell-root {
    text-align: center;
  }
`;
