import { createSelector } from "reselect";
import { selectApartment } from "./apartment.selectors";

const selectState = (state) => state;

export const selectPricingData = createSelector(
  [selectState],
  (state) => state.pricingData
);

export const selectDerivedValues = createSelector(
  [selectPricingData, selectApartment],
  (values, apartment) => ({
    valor_m2: Math.round(values.ask_price / apartment.area),
    redondeo_habi: Math.round(values.ask_price * 0.95),
  })
);

export const selectFinalDerivedValues = createSelector(
  [selectPricingData, selectApartment],
  (values, apartment) => ({
    valor_m2_final: Math.round(values.ask_price_final / apartment.area),
    redondeo_habi_final: Math.round(values.ask_price_final * 0.95),
  })
);

export const selectHasLoadedFile = createSelector(
  [selectPricingData],
  (values) => (values.loaded_file ? true : false)
);

const options = new Map([
  [10, "Percentil 10"],
  [20, "Percentil 20"],
  [30, "Percentil 30"],
  [40, "Percentil 40"],
  [50, "Percentil 50"],
]);

export const selectPercetilTextValue = createSelector(
  [selectPricingData],
  (values) => options.get(values.percentil)
);

export const selectPercentil = createSelector(
  [selectPricingData],
  (values) => values.percentil
);

export const selectCoeficiente = createSelector(
  [selectPricingData],
  (values) => values.coeficiente
);

export const selectPercentageSales = createSelector(
  [selectPricingData],
  (values) => values.percentageSales
);
