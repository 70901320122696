import styled from "styled-components";
import { error, brightTurquoise600 } from "../../StylesConstants";

export const ValueWrapper = styled.div`
  &.greater-than {
    color: ${brightTurquoise600};
  }

  &.less-than {
    color: ${error};
  }
`;
