import { gql } from "@apollo/client";

export const getPricingTable = (
  init,
  perPage,
  cityId,
  dateFrom,
  dateTo,
  zoneBig,
  zoneMedian
) => {
  return gql`
  {
    getPricingInfo(init: ${init} , perPage: ${perPage}, cityId: ${cityId}, dateFrom: "${dateFrom}", dateTo: "${dateTo}", zoneBig: ${zoneBig}, zoneMedian: ${zoneMedian}){
      count
      result {
        inmuebleID
        negocioID
        fechaCreacion
        nid
        proyecto
        fuenteId
        fuente
        direccion
        lastAskPrice
        loteID
        longitud
        latitud
        area
        area_catastro
        prioridad
        antiguedad
        piso
        habitaciones
        ascensores
        garajes
        banos
        tipoInmueble
        labelInmueble
        labelZonaMediana
        labelCity
        areaMetropolitanaId  
        tipo_inmueble_id
        area_flag
        garaje_flag
        bano_flag
        piso_flag
        ascensor_flag
        antiguedad_flag
        valorm2_flag
        tipo_inmueble_id_flag
        conjunto_edificio_flag
        obra_gris
        localidad
        deposito
        piscina
        vis_type
      }
    }
  }
  `;
};

export const getRePricingTable = (
  init,
  perPage,
  cityId,
  dateFrom,
  dateTo,
  zoneBig,
  zoneMedian
) => {
  return gql`
  {
    getRePricingInfo(init: ${init} , perPage: ${perPage}, cityId: ${cityId}, dateFrom: "${dateFrom}", dateTo: "${dateTo}", zoneBig: ${zoneBig}, zoneMedian: ${zoneMedian}){
      count
      result {
        inmuebleID
        negocioID
        fechaCreacion
        nid
        proyecto
        fuente
        direccion
        lastAskPrice
        loteID
        longitud
        latitud
        area
        area_catastro
        prioridad
        antiguedad
        piso
        habitaciones
        ascensores
        garajes
        banos
        tipoInmueble
        labelInmueble
        labelZonaMediana
        labelCity
        areaMetropolitanaId
        tipo_inmueble_id
        area_flag
        garaje_flag
        bano_flag
        piso_flag
        ascensor_flag
        antiguedad_flag
        valorm2_flag
        tipo_inmueble_id_flag
        conjunto_edificio_flag
        obra_gris
        localidad
        deposito
        piscina
        vis_type
      }
    }
  }
  `;
};

export const getReactivadoPricingTable = (
  init,
  perPage,
  cityId,
  dateFrom,
  dateTo,
  zoneBig,
  zoneMedian
) => {
  return gql`
  {
    getReactivadoPricingInfo(init: ${init} , perPage: ${perPage}, cityId: ${cityId}, dateFrom: "${dateFrom}", dateTo: "${dateTo}" zoneBig: ${zoneBig}, zoneMedian: ${zoneMedian}){
      count
      result {
        inmuebleID
        negocioID
        fechaCreacion
        nid
        proyecto
        fuente
        direccion
        lastAskPrice
        loteID
        longitud
        latitud
        area
        area_catastro
        prioridad
        antiguedad
        piso
        habitaciones
        ascensores
        garajes
        banos
        tipoInmueble
        labelInmueble
        labelZonaMediana
        labelCity
        areaMetropolitanaId
        tipo_inmueble_id
        area_flag
        garaje_flag
        bano_flag
        piso_flag
        ascensor_flag
        antiguedad_flag
        valorm2_flag
        tipo_inmueble_id_flag
        conjunto_edificio_flag
        obra_gris
        localidad
        deposito
        piscina
        vis_type
      }
    }
  }
  `;
};

export const getPricingImpossibleInfoTable = (
  init,
  perPage,
  cityId,
  dateFrom,
  dateTo,
  zoneBig,
  zoneMedian
) => {
  return gql`
  {
    getPricingImpossibleInfo(init: ${init} , perPage: ${perPage}, cityId: ${cityId}, dateFrom: "${dateFrom}", dateTo: "${dateTo}", zoneBig: ${zoneBig}, zoneMedian: ${zoneMedian}){
      count
      result {
        inmuebleID
        negocioID
        fechaCreacion
        nid
        proyecto
        fuente
        direccion
        lastAskPrice
        loteID
        longitud
        latitud
        area
        area_catastro
        prioridad
        antiguedad
        piso
        habitaciones
        ascensores
        garajes
        banos
        tipoInmueble
        labelInmueble
        labelZonaMediana
        labelCity
        areaMetropolitanaId
        tipo_inmueble_id
        area_flag
        garaje_flag
        bano_flag
        piso_flag
        ascensor_flag
        antiguedad_flag
        valorm2_flag
        tipo_inmueble_id_flag
        conjunto_edificio_flag
        obra_gris
        localidad
        deposito
        piscina
        vis_type
      }
    }
  }
  `;
};

export const getCompareByLot = (
  loteId,
  inmuebleId,
  area,
  filterArea,
  garaje,
  elevator,
  sources,
  filterDate,
  typeProperty,
  status
) => {
  return gql`
  {
    getComparableByLot(
      loteID:${loteId},
      inmuebleId:${inmuebleId},
      area:${area},
      filterArea:${filterArea},
      garaje:${garaje},
      elevator:${elevator},
      sources:[${sources}],
      filterDate:${filterDate},
      typeProperty:[${typeProperty}],
      status: [${status}],
    ){
      pid
      nid
      area
      area_catastro
      num_piso
      garajes
      num_ascensores
      vetustez
      banos
      fuente_id
      last_ask_price
      valormt2
      idconjunto
      num_apartamento
      direccion_homologada
      estrato_auto
      estrato_catastro
      telefono
      nombre_o_inmobiliaria
      ciudad_id
      fecha_creacion
      latitud
      longitud
      id
      fuente
      tipo_consulta
      label_inmueble
      anos_antiguedad
      conjunto_edificio
      area_flag
      garaje_flag
      bano_flag
      piso_flag
      ascensor_flag
      antiguedad_flag
      valorm2_flag
      tipo_inmueble_id_flag
      conjunto_edificio_flag
      estado
      estado_id
      tipo_inmueble_id
      obra_gris
      deposito
      piscina
      vis_type
    }
  }
  `;
};

export const getCompareByZone = (
  inmuebleId,
  area,
  filterArea,
  year,
  filterYear,
  garaje,
  elevator,
  sources,
  filterDate,
  typeProperty,
  polygon,
  status
) => {
  return gql`
  {
    getComparableByZone(
      inmuebleId:${inmuebleId},
      area:${area},
      filterArea:${filterArea},
      year:${year},
      filterYear:${filterYear},
      garaje:${garaje},
      elevator:${elevator},
      sources:[${sources}],
      filterDate:${filterDate},
      typeProperty:[${typeProperty}],
      polygon: ${polygon},
      status: [${status}],
      ) {
        pid
        nid
        area
        area_catastro
        num_piso
        garajes
        num_ascensores
        vetustez
        banos
        fuente_id
        last_ask_price
        valormt2
        idconjunto
        num_apartamento
        direccion_homologada
        estrato_auto
        estrato_catastro
        telefono
        nombre_o_inmobiliaria
        ciudad_id
        fecha_creacion
        latitud
        longitud
        id
        fuente
        tipo_consulta
        label_inmueble
        anos_antiguedad
        conjunto_edificio
        area_flag
        garaje_flag
        bano_flag
        piso_flag
        ascensor_flag
        antiguedad_flag
        valorm2_flag
        tipo_inmueble_id_flag
        conjunto_edificio_flag
        estado
        estado_id
        tipo_inmueble_id
        obra_gris
        deposito
        piscina
        vis_type
      }
  }`;
};

export const getCompareExternalSources = (
  longitud,
  latitud,
  distance,
  area,
  filterArea,
  year,
  filterYear,
  garaje,
  elevator,
  sources,
  filterDate,
  typeProperty
) => {
  return gql`
  {
    getComparareByExternalSources(
      longitud: ${longitud},
      latitud: ${latitud},
      distance:${distance},
      area:${area},
      filterArea:${filterArea},
      year:${year},
      filterYear:${filterYear},
      garaje:${garaje},
      elevator:${elevator},
      sources:[${sources}],
      filterDate:${filterDate},
      typeProperty:[${typeProperty}]
    ) {
      area
      num_piso
      garajes
      num_ascensores
      banos
      fuente_id
      last_ask_price
      valormt2
      fecha_creacion
      id
      fuente
      tipo_consulta
      label_inmueble
      anos_antiguedad
      tipo_inmueble_id
      url_external
    }
  }`;
};

export const getSourcesFilters = (type) => {
  return gql`
  {
    getSources(type: ${type}) {
      id
      fuente
      type
    }
  }`;
};

export const getStatusFilters = (id) => {
  return gql`
  {
    getStatus(id: ${id}) {
      id
      status
    }
  }`;
};

export const getPricingPercentage = (nid) => {
  return gql`
  {
    getPercentage(nid:${nid}){
      percentageSales
    }
  }
  `;
};

export const getTypology = (loteId) => {
  return gql`
  {
    getTypology(loteID:${loteId}){
      aconst
      count
    }
  }
  `;
};

export const getDiscountFormAskPrice = gql`
  query GetDiscount($inmuebleId: Int!, $askPrice: Int!, $nid: Float!) {
    getDiscount(inmuebleId: $inmuebleId, askPrice: $askPrice, nid: $nid) {
      pricingManual
    }
  }
`;

export const getComparisonHandbook = (inmuebleId) => {
  return gql`
  {
    getComparison(inmuebleID: ${inmuebleId} ){
      comparable
    }
  }
  `;
};

export const getPricingNID = gql`
  query GetPricingInfoByNID($nid: Float!, $type: String!) {
    getPricingInfoByNID(nid: $nid, type: $type) {
      count
      result {
        inmuebleID
        negocioID
        fechaCreacion
        nid
        proyecto
        fuente
        direccion
        lastAskPrice
        loteID
        longitud
        latitud
        area
        area_catastro
        prioridad
        antiguedad
        piso
        habitaciones
        ascensores
        garajes
        banos
        tipoInmueble
        labelInmueble
        labelZonaMediana
        labelCity
        areaMetropolitanaId
        tipo_inmueble_id
        area_flag
        garaje_flag
        bano_flag
        piso_flag
        ascensor_flag
        antiguedad_flag
        valorm2_flag
        tipo_inmueble_id_flag
        conjunto_edificio_flag
        obra_gris
        localidad
        deposito
        piscina
        vis_type
      }
    }
  }
`;

export const getHistoricoComentario = (inmuebleId) => {
  return gql`
  {
    getHistoricoComentarioInmueble(inmuebleId: ${inmuebleId}){
      inmuebleId 
      commentary 
      url
      agent
      date
    }
  }
  `;
};

export const getPricingFuente = (
  type,
  fuente,
  init,
  perPage,
  cityId,
  dateFrom,
  dateTo,
  zoneBig,
  zoneMedian
) => {
  return gql`
  {
    getPricingInfoByFuente(type: "${type}", fuente: "${fuente}", init: ${init} , perPage: ${perPage}, cityId: ${cityId}, dateFrom: "${dateFrom}", dateTo: "${dateTo}", zoneBig: ${zoneBig}, zoneMedian: ${zoneMedian}){
      count
      result {
        inmuebleID
        negocioID
        fechaCreacion
        nid
        proyecto
        fuente
        direccion
        lastAskPrice
        loteID
        longitud
        latitud
        area
        area_catastro
        prioridad
        antiguedad
        piso
        habitaciones
        ascensores
        garajes
        banos
        tipoInmueble
        labelInmueble
        labelZonaMediana
        labelCity
        areaMetropolitanaId
        tipo_inmueble_id
        area_flag
        garaje_flag
        bano_flag
        piso_flag
        ascensor_flag
        antiguedad_flag
        valorm2_flag
        tipo_inmueble_id_flag
        conjunto_edificio_flag    
        obra_gris
        localidad
        deposito
        piscina
        vis_type
      }
    }
  }
  `;
};

export const getPricedInmueblesByLot = (inmuebleId, loteId) => {
  return gql`
  {
    getPricedInmueblesByLot(inmuebleId: ${inmuebleId}, loteId: ${loteId}){
      inmuebleId
      area
      garajes
      banos
      piso
      ascensores
      antiguedad
      fuenteId
      fuente
      tipoInmueble
      labelInmueble
      ask_price
      precio_manual
      precioManualComite
      fechaPrecioManual
      precioFinalDeCierre
    }
  }
  `;
};

export const getComparableCoordinates = ({ inmuebleId, lng, lat }) => {
  return gql`
  {
    getComparableCoordinates(inmuebleId: ${inmuebleId}, lng: ${lng}, lat: ${lat}){
      inmuebleId
      fuenteId
      tipoInmueble
      labelInmueble
      lng
      lat
    }
  }
  `;
};

export const getSourcesByPolygon = ({
  inmuebleId,
  polygon,
  area,
  year,
  garage,
  typePropertyId,
  elevator,
}) => {
  return gql`
  {
    getSourcesByPolygon(
      inmuebleId: ${inmuebleId},
      polygon: ${polygon},
      area: ${area},
      year: ${year},
      garage: ${garage},
      typePropertyId: [${typePropertyId}],
      elevator: ${elevator}) {
      count
      fuenteId
      fuente
      countFiltered
    }
  }
  `;
};

export const getStarPricing = () => {
  return gql`
    {
      getStarPricing(name: "star_pricing") {
        value_string
        value_number
      }
    }
  `;
};

export const getPropertyInfo = ({ nid }) => {
  return gql`
    {
      getPropertyInfo(nid: ${nid}) {
        nid
        inmueble_id
        lote_id
        historico_pricing_id
        area
        num_habitaciones
        banos
        num_piso
        garajes
        num_ascensores
        anos_antiguedad
        ap_comite
        valor_admon
        porcentaje_venta_conjunto
        prima_altura
        v_o_area_construida_confirmado
        v_o_num_hab_confirmadas_confirmado
        v_o_num_banos_confirmados_confirmado
        v_o_piso_confirmado
        v_o_ascensor_confirmado
        v_o_garaje_confirmado
        v_o_tipo_de_garaje
      }
    }
  `;
};

export const getPropertyPhotosId = ({ nid }) => {
  return gql`
  {
    getPropertyPhotosId(nid: ${nid}) {
      driveId
    }
  }
  `;
};

export const getListTaskEngine = (id) => {
  return gql`
  {
    getListTaskEngine(id: ${id}){
      count
      result {
        id
        name
        task
        value
        priority
        flag
      }
    }
  }
  `;
};

export const getListStandardization = ({ init, perPage, nid }) => {
  return gql`
  {
    getListStandardization(init: ${init}, perPage: ${perPage}, nid: ${nid}){
      count
      result {
        id
        inmueble_id
        nid
        area_original
        garaje_original
        bano_original
        piso_original
        ascensor_original
        antiguedad_original
        valor_m2_original
        tipo_inmueble_id_original
        nombre_conjunto_original
      }
    }
  }
  `;
};

export const getSimulatorNid = gql`
  query getPricingSimulator($nid: Float!) {
    getPricingSimulator(nid: $nid) {
      count
      result {
        inmuebleID
        negocioID
        fechaCreacion
        nid
        proyecto
        fuente
        direccion
        lastAskPrice
        loteID
        longitud
        latitud
        area
        area_catastro
        prioridad
        antiguedad
        piso
        habitaciones
        ascensores
        garajes
        banos
        tipoInmueble
        labelInmueble
        labelZonaMediana
        labelCity
        areaMetropolitanaId
        tipo_inmueble_id
        area_flag
        garaje_flag
        bano_flag
        piso_flag
        ascensor_flag
        antiguedad_flag
        valorm2_flag
        tipo_inmueble_id_flag
        conjunto_edificio_flag
        obra_gris
        localidad
      }
    }
  }
`;

export const getCitiesFilter = () => {
  return gql`
    {
      getCities {
        id
        label
      }
    }
  `;
};

export const getZoneBigFilter = (cityId) => {
  return gql`{
    getZoneBig(cityId: ${cityId}) {
      id
      label
    }
  }`;
};

export const getZoneMedianFilter = (zoneBig) => {
  return gql`{
    getZoneMedian(zoneBig: ${zoneBig}) {
      id
      label
    }
  }`;
};

export const getBuckets = (zoneMedianId) => {
  return gql`
  {
    getBuckets(zoneMedianId: ${zoneMedianId}){
      zoneMedianId
      city
      buckets
    }
  }
  `;
};

export const saveHabiProperty = gql`
  mutation saveHabiProperty(
    $nid: Float!
    $inmueble_id: Float!
    $lote_id: Float!
    $historico_pricing_id: Float
    $area: Int!
    $num_habitaciones: Int!
    $banos: Int!
    $num_piso: Int!
    $garajes: Int!
    $num_ascensores: Int!
    $anos_antiguedad: Int!
    $ap_comite: Int!
    $valor_admon: Float!
    $porcentaje_venta_conjunto: Float!
    $prima_altura: String!
    $agente_pricing_2: String!
  ) {
    saveHabiProperty(
      nid: $nid
      inmueble_id: $inmueble_id
      lote_id: $lote_id
      historico_pricing_id: $historico_pricing_id
      area: $area
      num_habitaciones: $num_habitaciones
      banos: $banos
      num_piso: $num_piso
      garajes: $garajes
      num_ascensores: $num_ascensores
      anos_antiguedad: $anos_antiguedad
      ap_comite: $ap_comite
      valor_admon: $valor_admon
      porcentaje_venta_conjunto: $porcentaje_venta_conjunto
      prima_altura: $prima_altura
      agente_pricing_2: $agente_pricing_2
    ) {
      message
      statusCode
    }
  }
`;

export const saveStandardization = gql`
  mutation saveStandardization(
    $id: Int!
    $inmueble_id: Float!
    $nid: Float!
    $area_normalizado: Int
    $garaje_normalizado: Int
    $bano_normalizado: Int
    $piso_normalizado: Int
    $ascensor_normalizado: Int
    $antiguedad_normalizado: Int
    $valor_m2_normalizado: Int
    $tipo_inmueble_id_normalizado: Int
    $nombre_conjunto_normalizado: String
    $agente_actualizacion: String!
    $activo: Int!
  ) {
    saveStandardization(
      id: $id
      inmueble_id: $inmueble_id
      nid: $nid
      area_normalizado: $area_normalizado
      garaje_normalizado: $garaje_normalizado
      bano_normalizado: $bano_normalizado
      piso_normalizado: $piso_normalizado
      ascensor_normalizado: $ascensor_normalizado
      antiguedad_normalizado: $antiguedad_normalizado
      valor_m2_normalizado: $valor_m2_normalizado
      tipo_inmueble_id_normalizado: $tipo_inmueble_id_normalizado
      nombre_conjunto_normalizado: $nombre_conjunto_normalizado
      agente_actualizacion: $agente_actualizacion
      activo: $activo
    ) {
      message
      statusCode
    }
  }
`;

export const savePricingData = gql`
  mutation SaveHabiPrice(
    $askPrice: Int!
    $precioHabi: Int!
    $inmuebleId: Int!
    $nid: Float
    $prioridad: Int
    $fuente: String!
    $fechaCreacion: String!
    $comparable: String!
    $originalData: String!
    $agent: String!
    $rowsFlag: String
    $mapPolygon: String!
    $typePricing: Int!
  ) {
    saveHabiPrice(
      askPrice: $askPrice
      precioHabi: $precioHabi
      inmuebleId: $inmuebleId
      nid: $nid
      prioridad: $prioridad
      fuente: $fuente
      fechaCreacion: $fechaCreacion
      comparable: $comparable
      originalData: $originalData
      agent: $agent
      rowsFlag: $rowsFlag
      mapPolygon: $mapPolygon
      typePricing: $typePricing
    ) {
      message
      statusCode
    }
  }
`;

export const savePricingDataFinal = gql`
  mutation saveHabiHandbookPrice(
    $askPrice: Int!
    $precioHabi: Int!
    $inmuebleId: Int!
    $nid: Float
    $prioridad: Int
    $fuente: String!
    $fechaCreacion: String!
    $comparable: String!
    $originalData: String!
    $agent: String!
    $rowsFlag: String
    $mapPolygon: String!
    $precio_zona: Float
    $nivel_confianza: Float
    $consistencia_conjunto: Float
    $typePricing: Int!
  ) {
    saveHabiHandbookPrice(
      askPrice: $askPrice
      precioHabi: $precioHabi
      inmuebleId: $inmuebleId
      nid: $nid
      prioridad: $prioridad
      fuente: $fuente
      fechaCreacion: $fechaCreacion
      comparable: $comparable
      originalData: $originalData
      agent: $agent
      rowsFlag: $rowsFlag
      mapPolygon: $mapPolygon
      precio_zona: $precio_zona
      nivel_confianza: $nivel_confianza
      consistencia_conjunto: $consistencia_conjunto
      typePricing: $typePricing
    ) {
      message
      statusCode
    }
  }
`;

export const SaveHabiNoProperty = gql`
  mutation SaveHabiNoPrice(
    $inmuebleId: Int!
    $nid: Float!
    $negocioId: Int!
    $agent: String!
  ) {
    saveHabiNoPrice(
      inmuebleId: $inmuebleId
      nid: $nid
      negocioId: $negocioId
      agent: $agent
    ) {
      message
      statusCode
    }
  }
`;

export const saveCommentData = gql`
  mutation saveHabiCommentary(
    $inmuebleId: Int!
    $commentary: String!
    $url: String
    $agent: String!
    $askPrice: Int!
    $fechaCreacion: String!
    $nid: Float
    $prioridad: Int
    $responseQuestion: Int!
    $flag: Int!
    $tipoInmuebleId: Int
    $numPiso: Int
    $areaMetropolitanaId: Int
    $finalCommentary: Int
    $originalData: String
    $mapPolygon: String
  ) {
    saveHabiCommentary(
      inmuebleId: $inmuebleId
      commentary: $commentary
      url: $url
      agent: $agent
      askPrice: $askPrice
      fechaCreacion: $fechaCreacion
      nid: $nid
      prioridad: $prioridad
      responseQuestion: $responseQuestion
      flag: $flag
      tipoInmuebleId: $tipoInmuebleId
      numPiso: $numPiso
      areaMetropolitanaId: $areaMetropolitanaId
      finalCommentary: $finalCommentary
      originalData: $originalData
      mapPolygon: $mapPolygon
    ) {
      message
      statusCode
    }
  }
`;

export const saveImpossibleData = gql`
  mutation saveHabiImpossible(
    $inmuebleId: Int!
    $nid: Float!
    $flag: Int!
    $agent: String!
  ) {
    saveHabiImpossible(
      inmuebleId: $inmuebleId
      nid: $nid
      flag: $flag
      agent: $agent
    ) {
      message
      statusCode
    }
  }
`;

export const signFileData = gql`
  mutation (
    $name: String!
    $type: String!
    $inmuebleId: Int!
    $agente: String!
  ) {
    signFile(
      name: $name
      type: $type
      inmuebleId: $inmuebleId
      agente: $agente
    ) {
      signedUrl
    }
  }
`;

export const deleteTaskEngine = gql`
  mutation ($id: Int!) {
    deleteTaskEngine(id: $id) {
      message
      statusCode
    }
  }
`;

export const saveTaskEngine = gql`
  mutation (
    $id: Int
    $name: String!
    $task: String!
    $value: Float!
    $priority: Int!
    $flag: Int!
  ) {
    saveTaskEngine(
      id: $id
      name: $name
      task: $task
      value: $value
      priority: $priority
      flag: $flag
    ) {
      message
      statusCode
    }
  }
`;

export const saveHabiFlagData = gql`
  mutation saveHabiFlag($rowsFlag: String, $agent: String!) {
    saveHabiFlag(rowsFlag: $rowsFlag, agent: $agent) {
      message
      statusCode
    }
  }
`;

export const saveBuckets = gql`
  mutation saveBuckets($zoneMedianId: Int!, $buckets: String!) {
    saveBuckets(zoneMedianId: $zoneMedianId, buckets: $buckets) {
      message
      statusCode
    }
  }
`;
