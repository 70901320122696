import styled from "styled-components";
import {
  brightTurquoise600,
  brightTurquoise800,
  muliBoldFamily,
} from "../../StylesConstants";

export const StyledLinkContainer = styled.div`
  display: flex;
`;

export const StyledLink = styled.a`
  color: ${brightTurquoise600};
  font-family: ${muliBoldFamily};
  font-weight: 600;
  &:hover {
    color: ${brightTurquoise800};
  }
  .link-icon {
    margin-right: 8px;
  }
`;
