import { InputBase, withStyles } from "@material-ui/core";
import styled from "styled-components";
import {
  archiaRegular,
  brightTurquoise000,
  brightTurquoise600,
  muliBoldFamily,
  nightBlue000,
  nightBlue500,
  scarpaGray000,
  scarpaGray100,
  scarpaGray200,
  scarpaGray600,
  tomatoCoral500,
} from "../../StylesConstants";

export const StyledContainer = styled.div`
  background: ${nightBlue000};
  border: 1px solid ${scarpaGray100};
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05),
    0px 2px 4px rgba(48, 49, 51, 0.1);
  margin: 0 auto;
  padding: 24px;
  width: calc(100% - 32px);
`;

export const StyledTitle = styled.div`
  color: ${nightBlue500};
  font-family: ${muliBoldFamily};
  font-size: 18px;
  margin-bottom: 24px;
`;

export const StyledContent = styled.div`
  align-content: center;
  align-items: center;
  column-gap: 16px;
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 24px;
  row-gap: 16px;
`;

export const StyledGroupInput = styled.div`
  display: grid;
  grid-template-columns: 112px 120px;
  column-gap: 8px;
  font-family: ${archiaRegular};
  font-size: 14px;

  .label-item {
    align-self: center;
    justify-self: end;
    text-align: end;
    word-break: break-word;
  }
`;

export const StyledInput = withStyles(() => ({
  root: {
    backgroundColor: `${scarpaGray000}`,
    border: `2px solid ${scarpaGray200}`,
    borderRadius: "8px",
    padding: "0 8px",
    fontFamily: `${archiaRegular}`,
    fontSize: "14px",
    color: `${scarpaGray600}`,
    height: "44px",
    textAlign: "start",

    "&:hover:not($disabled)": {
      backgroundColor: `${brightTurquoise000}`,
      border: `2px solid ${brightTurquoise600}`,
    },

    "&:focus": {
      backgroundColor: `${brightTurquoise000}`,
      boxShadow: "none",
      border: `2px solid ${brightTurquoise600}`,
    },
  },
  disabled: {
    backgroundColor: `${scarpaGray100}`,
  },
  input: {
    "&[type=number]": {
      "-moz-appearance": "textfield",
    },
    "&::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    underline: {
      "&:before": {
        content: "none",
      },
      "&:after": {
        content: "none",
      },
    },
  },
  error: {
    border: `2px solid ${tomatoCoral500}`,
  },
}))(InputBase);

export const ErrorMessage = styled.p`
  color: ${tomatoCoral500};
  display: ${(props) => (props.error ? "flex" : "none")};
  justify-content: center;
  font-family: ${archiaRegular};
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  margin: 0 0 20px 0;
`;
