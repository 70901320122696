import { flagSelectionDataActionTypes } from "../actions/flagSelectionData.actions";

const INITIAL_STATE = {};

const flagRowDataReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case flagSelectionDataActionTypes.ADD_FLAG_ROW_DATA:
      return {
        ...state,
        [payload.rowId]: { ...state[payload.rowId], ...payload.data },
      };
    case flagSelectionDataActionTypes.CLEAR_FLAG_ROW_DATA:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default flagRowDataReducer;
