import styled from "styled-components";
import Button from "../../Button/button";
import {
  nightBlue500,
  scarpaGray000,
  scarpaGray600,
  scarpaGray100,
  electricPurple500,
  white,
  electricPurple600,
} from "../../../StylesConstants";

export const CardContainer = styled.div`
  position: absolute;
  top: 50%;
  right: calc(-100px + 16px);
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 256px;
  padding: 24px 16px;
  background-color: white;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
  border-radius: 16px;

  h1 {
    font-family: "Muli ExtraBold";
    font-size: 14px;
    line-height: 20px;
    color: ${nightBlue500};
  }
`;

export const DataContainer = styled.div`
  margin-bottom: 16px;
`;

export const FuenteContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-size: 13px;
  line-height: 24px;
  color: ${nightBlue500};
  .counters {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 60px;
    .filtered {
      color: ${electricPurple500};
    }
  }
`;

export const FuenteLabel = styled.p`
  margin: unset;
  font-family: "Open Sans";
`;

export const CountLabel = styled.p`
  margin: unset;
  font-family: "Open Sans Bold";
  justify-self: end;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 88px;
  margin-bottom: 16px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80px;
`;

export const StyledButton = styled(Button)`
  max-height: unset;
  height: 32px;
  width: 100%;
  font-family: "Muli Bold";
  font-size: 13px;
  line-height: 18px;
  text-align: center;

  button {
    height: 32px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    font-size: 13px;
  }

  #seleccionar {
    background-color: ${electricPurple500};
    color: ${white};

    &:hover {
      background-color: ${electricPurple600};
    }
  }

  #cancelar {
    color: ${scarpaGray600};
    background: ${scarpaGray000};
    border: 1px solid ${scarpaGray100};
    box-sizing: border-box;

    &:hover {
      background-color: ${scarpaGray100};
    }
  }
`;
