import styled from 'styled-components';
import {
  brightTurquoise000,
  scarpaGray600,
  brightTurquoise500,
  archiaRegular,
  scarpaGray200, scarpaGray000, black
} from '../../StylesConstants';

const SquareDropdownWrapper = styled.div`
  .container {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 16px;
    justify-items: start;
    align-items: center;
    font-family: ${archiaRegular};
    padding: 0px;
    span{
      font-size: 15px;
      font-weight: 600;
      color: ${scarpaGray600};
    }

    .dropdow-container {
      display: flex;
      align-items: center;
      position: relative;

      .btn-primary {
        border-color: ${scarpaGray200};
        background-color: ${scarpaGray000};
        border-radius: 8px;
        color: ${scarpaGray600};
        padding: 8px 16px;
        padding-right: 33px;
        font-size: 15px;
        min-width: 144px;
        height: 40px;
        text-align: initial;

        &:hover {
          border: 1px solid ${brightTurquoise500};
          background-color: ${brightTurquoise000};
        }

        &:focus {
          background-color: ${brightTurquoise000};
          box-shadow: none;
          border: 2px solid ${brightTurquoise500};
        }

        &.dropdown-toggle:focus {
          box-shadow: none;
          color: ${scarpaGray600};
        }
      }

      .icon-arrow {
        transform: translateY(-50%);
        position: absolute;
        right: 10px;
        top: 50%;
        color: ${black};
      }
    }

    .dropdown-menu {
      border: none;
      box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05), 0px 8px 16px rgba(48, 49, 51, 0.1);
      border-radius: 8px;
      text-align: center;
    }

    .dropdown-item {
      padding: 8px 16px;
      color: ${scarpaGray600};
      font-size: 16px;
      text-decoration: none;
      text-align: start;

      &:hover {
        background-color: ${brightTurquoise000};
      }

      &:focus {
        background-color: ${brightTurquoise500};
      }
    }

    .active {
      background-color: ${brightTurquoise500};
    }

    .dropdown-toggle::after{
      content: none;
    }
  }
`;

export default SquareDropdownWrapper;