import React from "react";
import PropTypes from "prop-types";
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Checkbox,
} from "@material-ui/core";
import { HiddenSorter, StyledMultipleCheckboxes } from "./style";
import {
  headManualTable,
  headManualTableDetail,
} from "./selectedManualTable.utils";

const headExternal = [
  {
    id: "area",
    numeric: true,
    disablePadding: true,
    label: "Área",
  },
  { id: "garajes", numeric: true, disablePadding: false, label: "Garajes" },
  { id: "banos", numeric: true, disablePadding: false, label: "Baños" },
  { id: "num_piso", numeric: true, disablePadding: false, label: "Piso" },
  {
    id: "num_ascensores",
    numeric: true,
    disablePadding: false,
    label: "Ascensores",
  },
  {
    id: "anos_antiguedad",
    numeric: true,
    disablePadding: false,
    label: "Antiguedad",
  },
  {
    id: "id",
    numeric: true,
    disablePadding: false,
    label: "Id",
  },
  {
    id: "fuente",
    numeric: false,
    disablePadding: false,
    label: "Fuente",
  },
  {
    id: "label_inmueble",
    numeric: false,
    disablePadding: false,
    label: "Tipo Inmueble",
  },
  {
    id: "last_ask_price",
    numeric: true,
    disablePadding: false,
    label: "Ask Price",
  },
  { id: "valormt2", numeric: true, disablePadding: false, label: "Valor m2" },
];

const SelectedTableHead = (props) => {
  const {
    order,
    orderBy,
    onRequestSort,
    addManyRowsHandler,
    allRowsSelected,
    partialRowsSelected,
    someRowsSelected,
    handleAllClicked,
    allProperties,
  } = props;

  const headCells = [
    {
      id: "area",
      numeric: true,
      disablePadding: true,
      label: "Área",
    },
    {
      id: "area_catastro",
      numeric: true,
      disablePadding: true,
      label: "Área Catastro",
    },
    { id: "garajes", numeric: true, disablePadding: false, label: "Garajes" },
    { id: "banos", numeric: true, disablePadding: false, label: "Baños" },
    { id: "num_piso", numeric: true, disablePadding: false, label: "Piso" },
    {
      id: "num_ascensores",
      numeric: true,
      disablePadding: false,
      label: "Ascensores",
    },
    {
      id: "anos_antiguedad",
      numeric: true,
      disablePadding: false,
      label: "Antiguedad",
    },
    {
      id: props.title === "Comparables por zona" ? "conjunto_edificio" : "id",
      numeric: props.title === "Comparables por zona" ? false : true,
      disablePadding: false,
      label:
        props.title === "Comparables por zona"
          ? "Nombre del conjunto"
          : "Inmueble Id",
    },
    {
      id: "fuente",
      numeric: false,
      disablePadding: false,
      label: "Fuente",
    },
    {
      id: "label_inmueble",
      numeric: false,
      disablePadding: false,
      label: "Tipo Inmueble",
    },
    {
      id: "last_ask_price",
      numeric: true,
      disablePadding: false,
      label: "Ask Price",
    },
    { id: "valormt2", numeric: true, disablePadding: false, label: "Valor m2" },
    {
      id: "obra_gris",
      numeric: false,
      disablePadding: false,
      label: "Obra gris",
    },
    {
      id: "piscina",
      numeric: false,
      disablePadding: false,
      label: "Piscina",
    },
    {
      id: "deposito",
      numeric: false,
      disablePadding: false,
      label: "Cuarto útil",
    },
    {
      id: "vis_type",
      numeric: false,
      disablePadding: false,
      label: "Tipo VIS",
    },
  ];

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      {props.title !== "Comparables seleccionados manualmente" && (
        <TableRow>
          <TableCell padding="checkbox">
            <StyledMultipleCheckboxes>
              <div className="checkbox_section">
                <label className="check-label">Todos</label>
                <Checkbox
                  checked={allRowsSelected}
                  indeterminate={someRowsSelected}
                  onClick={handleAllClicked}
                  key={props.title}
                  size="small"
                />
              </div>
              <div className="checkbox_section">
                <label className="check-label">Parcial</label>
                <Checkbox
                  checked={partialRowsSelected || someRowsSelected}
                  indeterminate={someRowsSelected}
                  onClick={addManyRowsHandler}
                  size="small"
                />
              </div>
            </StyledMultipleCheckboxes>
          </TableCell>
          {props.title !== "Comparables por otras fuentes" &&
            props.title !== "Comparables seleccionados manualmente" &&
            headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <HiddenSorter>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </HiddenSorter>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))}
          {props.title === "Comparables por otras fuentes" &&
            props.title !== "Comparables seleccionados manualmente" &&
            headExternal.map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <HiddenSorter>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </HiddenSorter>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))}
        </TableRow>
      )}
      {props.title === "Comparables seleccionados manualmente" && (
        <TableRow>
          {props.title === "Comparables seleccionados manualmente" &&
            !allProperties &&
            headManualTable.map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <HiddenSorter>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </HiddenSorter>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))}
          {props.title === "Comparables seleccionados manualmente" &&
            allProperties === true &&
            [...headManualTable, ...headManualTableDetail].map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <HiddenSorter>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </HiddenSorter>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))}
        </TableRow>
      )}
    </TableHead>
  );
};

SelectedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default SelectedTableHead;
