import { manualRowDataActionTypes } from "../actions/manualRowData.actions";

const INITIAL_STATE = {};

const manualRowDataReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case manualRowDataActionTypes.ADD_MANUAL_ROW_DATA:
      return {
        ...state,
        [payload.rowId]: { ...state[payload.rowId], ...payload.data },
      };
    case manualRowDataActionTypes.REMOVE_MANUAL_ROW_DATA_ID:
      return { ...state, [payload]: { quality: state[payload].quality ?? 0 } };
    case manualRowDataActionTypes.CLEAR_MANUAL_ROW_DATA:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default manualRowDataReducer;
