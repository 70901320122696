import React from 'react';
import logo from "../../static/assets/logo/habi.svg";
import {HeaderWrapper} from './style';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';

const Header = props => {
  return (
    <HeaderWrapper>
      <div className="items-container">
        <img src={logo} alt="Habi logo" className="logo"></img>
      </div>
      <div className="menu">
        <span className="menu-title">Menú</span>
        <MenuRoundedIcon className="menu-icon" />
      </div>
    </HeaderWrapper>
  );
};

export default Header;
