import { warningsActionTypes } from "../actions/warnings.actions";

const INITIAL_STATE = { warningMessages: [], rowIds: [] };

const warningsReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case warningsActionTypes.SET_WARNINGS:
      return { ...state, warningMessages: payload };
    case warningsActionTypes.SET_WARNINGS_AND_IDS:
      return payload;
    case warningsActionTypes.RESET_WARNINGS:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default warningsReducer;
