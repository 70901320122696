import React, { useState } from "react";
import { connect } from "react-redux";
import { useQuery } from "@apollo/client";
import { getPricedInmueblesByLot } from "../../queries/queries";
import { selectApartment } from "../../redux/selectors/apartment.selectors";
import AlreadyPriced from "./AlreadyPriced";
import {
  sortAndSliceInmuebles,
  getAndFormatAverage,
} from "./AlreadyPriced.utils";
import { tableHeadCells } from "./AlreadyPriced.data";

const AlreadyPricedContainer = (props) => {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("area");

  const { apartment } = props;

  const { loading, data } = useQuery(
    getPricedInmueblesByLot(apartment.inmuebleID, apartment.loteID)
  );

  const handlePageChange = (_, newPage) => setPage(newPage);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const validData = data?.getPricedInmueblesByLot;
  const sortedAndSlicedInmuebles = sortAndSliceInmuebles({
    data: validData,
    order,
    orderBy,
    page,
  });
  const totalItems = validData?.length;
  const averageM2 = getAndFormatAverage({
    data: validData,
  });

  return (
    <AlreadyPriced
      title="Pricing Conjunto"
      inmuebles={sortedAndSlicedInmuebles}
      loading={loading}
      page={page}
      handlePageChange={handlePageChange}
      order={order}
      orderBy={orderBy}
      handleRequestSort={handleRequestSort}
      apartment={apartment}
      totalItems={totalItems}
      tableHeadCells={tableHeadCells}
      averageM2={averageM2}
    />
  );
};

const mapStateToProps = (state) => ({
  apartment: selectApartment(state),
});

export default connect(mapStateToProps)(AlreadyPricedContainer);
