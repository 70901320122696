import styled from "styled-components";
import {
  hintPerano100,
  electricPurple600,
  archiaBoldFamily,
  scarpaGray600,
} from "../../StylesConstants";

export const PricingWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-areas:
    "header"
    "current-lead"
    "title"
    "table"
    "question"
    "comment"
    "save";

  .header {
    grid-area: header;
  }

  .current-lead {
    grid-area: current-lead;
  }

  .title {
    grid-area: title;
    display: flex;
    justify-content: space-between;
    padding: 28px 24px;
    .save-button {
      .primary {
        height: 40px;
        min-width: 162px;
        font-weight: 600px;
        .icon-cloud {
          margin-right: 4px;
          font-size: 22px;
        }
      }
    }
  }

  .table {
    grid-area: table;
    padding: 0px 24px;
  }

  .source-question {
    grid-area: question;
    padding: 16px 24px;
    color: ${scarpaGray600};
    display: flex;
    align-items: center;
    .question {
      margin-right: 25px;
    }
  }

  .comment {
    grid-area: comment;
    padding: 16px 64px;
  }

  .save {
    grid-area: save;
    padding: 0px 64px;
  }

  input[type="file"] {
    opacity: 0;
    width: 140px;
    position: absolute;
  }
  .upload-button {
    display: grid;
    grid-template: 1fr/41px 113px;
    align-items: center;
    justify-items: center;
    background-color: ${hintPerano100};
    width: 162px;
    height: 40px;
    margin-right: 50px;
    border-radius: 100px;
    color: ${electricPurple600};
    font-size: 16px;
    font-family: ${archiaBoldFamily};
  }
`;
