import React from "react";
import ModalWrapper from "../../components/ModalWrapper/ModalWrapper";
import MapComparablesContainer from "../../components/MapComparables/MapComparables.container";

import { ModalContainer, Overlay } from "./ComparablesMapPage.style";

const ComparablesMapPage = () => {
  return (
    <ModalWrapper>
      <ModalContainer>
        <MapComparablesContainer />
      </ModalContainer>
      <Overlay />
    </ModalWrapper>
  );
};

export default ComparablesMapPage;
