import React from "react";
import TableCell from "@material-ui/core/TableCell";
import { ValueWrapper } from "./ComparisonTableCell.style";
import {
  getClassName,
  getDisplayValue,
  renderComparisonArrow,
} from "./ComparisonTableCell.utils";

/**
 * Material-UI Based table cell with custom styling for comparison.
 * 
 * @param {string | number} key - Value to be passed as key to cell array.
 * @param {string} type - "price" | "area" | "date" Determines format to display on cell.
 * @param {boolean} ignoreComparison - Ignore comparison styling/arrow for cell.
 * @param {any} dataA - Data to compare.
 * @param {any} dataB - Data to compare against.
 *
 * @example
 *
 *  {cellArray.map((cell) => (
      <ComparisonTableCell
        key={cell.value}
        type={cell.type}
        ignoreComparison={[
          "inmuebleId",
          "fuente",
          "tipoInmueble",
        ].includes(cell.value)}
        dataA={inmueble[cell.value]}
        dataB={apartment[cell.value]}
      />
    ))} 
 */
const ComparisonTableCell = (props) => {
  const { key, ignoreComparison, dataA, dataB, type } = props;

  const displayValue = getDisplayValue({ dataA, type });
  const className = getClassName({
    ignoreComparison,
    dataA,
    dataB,
  });
  const comparisonArrow = renderComparisonArrow({ className });

  return (
    <TableCell key={key}>
      <ValueWrapper className={className}>
        {displayValue}
        {comparisonArrow}
      </ValueWrapper>
    </TableCell>
  );
};

export default ComparisonTableCell;
