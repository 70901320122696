import React from "react";
import CalendarWrapper from "./style";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker, {utils} from "react-modern-calendar-datepicker";
import DateRangeRoundedIcon from "@material-ui/icons/DateRangeRounded";
import { electricPurple500 } from "../../StylesConstants";

const CalendarFilter = ({
  label,
  selectedDayRange,
  setSelectedDayRange,
  customLocale,
  ...props
}) => {
  const renderCustomInput = ({ ref }) => (
    <div className="calendar-input">
      <DateRangeRoundedIcon className="calendar-icon" />
      <input
        readOnly
        ref={ref}
        placeholder="Seleccionar"
        className="input-tag"
        value={
          selectedDayRange.from && selectedDayRange.to
            ? `${selectedDayRange.from.year}-${selectedDayRange.from.month}-${selectedDayRange.from.day} - ${selectedDayRange.to.year}-${selectedDayRange.to.month}-${selectedDayRange.to.day}`
            : ""
        }
      />
    </div>
  );

  return (
    <CalendarWrapper>
      <span>{label}</span>
      <DatePicker
        value={selectedDayRange}
        onChange={setSelectedDayRange}
        shouldHighlightWeekends
        locale={customLocale}
        renderInput={renderCustomInput}
        className="calendar-date-range"
        colorPrimary={electricPurple500}
        colorPrimaryLight=""
        maximumDate={utils().getToday()}
        calendarPopperPosition="bottom"
      />
    </CalendarWrapper>
  );
};

export default CalendarFilter;
