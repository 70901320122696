import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  addManualRowDataAction,
  removeManualRowDataIdAction,
} from "../../redux/actions/manualRowData.actions";
import { withRouter } from "react-router";
import SelectedTableToolBar from "./selectedTableToolBar";
import SelectedTableHead from "./selectedTableHead";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";
import ArrowDropUpRoundedIcon from "@material-ui/icons/ArrowDropUpRounded";
import DeleteIcon from "@material-ui/icons/Delete";
import { getComparator, stableSort } from "../../utils/sorting";
import StarRankingSelector from "../StarRankingSelector/StarRankingSelector";
import {
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import {
  NoResultsContainer,
  StarContainer,
  StyledButtonGroup,
  StyledPaper,
  StyledTable,
  ButtonRemove,
} from "./style";
import SquareCheckboxDropdown from "../SquareCheckboxDropdown/SquareCheckboxDropdown";
import { fetchDataAxios } from "../../utils/fetchRequest";
import Button from "../ButtonV2/Button";
import { normalizationOptions } from "./selectedManualTable.utils";
import DotsLoading from "../DotsLoading/dotsLoading";
import moment from 'moment';
import { setCoefficientAction } from "../../redux/actions/coeffientRows.actions";

const rootUrlForms =
  process.env.REACT_APP_FORM_API_URL_V2 +
  process.env.REACT_APP_ENDPOINT_FORMS_API_BASE_PATH;
const endpointPricingStandardization =
  process.env.REACT_APP_PRICING_STANDARDIZATION_URL_ENDPOINT;
const urlGetPricingStandardization =
  rootUrlForms + endpointPricingStandardization;
const apiKeyForms = process.env.REACT_APP_FORMS_API_KEY;

const SelectedManualTable = ({
  title,
  manualTable,
  numSelected,
  rowsManuallyAdded,
  apartment,
  percent,
  removeRow,
  starPricing,
  addManualRowData,
  removeManualRowDataId,
}) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [normalize, setNormalize] = useState(["ninguno"]);
  const [close, setClose] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allProperties, SetAllProperties] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (allProperties){
      setRowsPerPage(rowsManuallyAdded.length);
    }    
  }, [allProperties, rowsManuallyAdded.length]);

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const selectedRows = rowsManuallyAdded.length > 0;

  const getStandardizationPricing = async (data) => {
    let responseDataPricingStandardization = 0;
    try {
      const responsePricingStandardization = fetchDataAxios(
        urlGetPricingStandardization,
        {
          method: "POST",
          headers: {
            "x-api-key": apiKeyForms,
            "Content-Type": "application/json",
          },
          data: data,
        }
      );
      responseDataPricingStandardization = await responsePricingStandardization;
      return responseDataPricingStandardization;
    } catch (err) {
      console.log(`Error in getStandardizationPricing: ${err.message}`);
    }
  };

  const changeStandardization = async (apartment, row, typeStandardization) => {
    setClose(true);
    const data = {
      pricing: apartment,
      row: row,
      type_standardization: typeStandardization,
    };
    const rowResponse = await getStandardizationPricing(data);
    rowResponse.height_premium = false;
    if (typeStandardization.includes("garajes")) {
      rowResponse.garajes = apartment.garajes;
    }
    if (typeStandardization.includes("banos")) {
      rowResponse.banos = apartment.banos;
    }
    if (typeStandardization.includes("num_piso")) {
      rowResponse.num_piso = apartment.piso;
    }
    if (typeStandardization.includes("area")) {
      rowResponse.area = apartment.area;
    }
    if (typeStandardization.includes("height_premium")) {
      rowResponse.num_piso = apartment.piso;
      rowResponse.height_premium = true;
    }
    addManualRowData({
      rowId: rowResponse.id,
      data: {
        last_ask_price: rowResponse.last_ask_price,
        valormt2: rowResponse.valormt2,
        garajes: rowResponse.garajes,
        banos: rowResponse.banos,
        num_piso: rowResponse.num_piso,
        type_standardization: typeStandardization,
        area: rowResponse.area,
        height_premium: rowResponse.height_premium,
      },
    });
    dispatch(setCoefficientAction(true));
  };

  return (
    <StyledPaper>
      <SelectedTableToolBar
        className="manual-table"
        addField
        numSelected={numSelected}
        title={title}
        manualTable={manualTable}
        rowsManuallyAdded={rowsManuallyAdded}
        percent={percent}
        setLoading={setLoading}
        SetAllProperties={SetAllProperties}
      />
      {loading && <DotsLoading />}
      {!loading && (
        <TableContainer>
          <StyledTable
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <SelectedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              title={title}
              allProperties={allProperties}
            />
            <TableBody>
              {stableSort(rowsManuallyAdded, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {                  
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow key={row.id} className={row.delete ? "row-to-delete" : ""}>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.area ?? "N/A"}
                      </TableCell>
                      <TableCell align="right">
                        {row.area_catastro ?? "N/A"}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={
                          apartment.garajes === row.garajes
                            ? ""
                            : apartment.garajes > row.garajes
                            ? "less-than"
                            : "greater-than"
                        }
                      >
                        {row.garajes ?? "N/A"}
                        {apartment.garajes === row.garajes ? (
                          <></>
                        ) : apartment.garajes > row.garajes ? (
                          <ArrowDropDownRoundedIcon />
                        ) : (
                          <ArrowDropUpRoundedIcon />
                        )}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={
                          apartment.banos === row.banos
                            ? ""
                            : apartment.banos > row.banos
                            ? "less-than"
                            : "greater-than"
                        }
                      >
                        {row.banos ?? "N/A"}
                        {apartment.banos === row.banos ? (
                          <></>
                        ) : apartment.banos > row.banos ? (
                          <ArrowDropDownRoundedIcon />
                        ) : (
                          <ArrowDropUpRoundedIcon />
                        )}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={
                          apartment.piso === row.num_piso
                            ? ""
                            : apartment.piso > row.num_piso
                            ? "less-than"
                            : "greater-than"
                        }
                      >
                        {row.num_piso ?? "N/A"}
                        {apartment.piso === row.num_piso ? (
                          <></>
                        ) : apartment.piso > row.num_piso ? (
                          <ArrowDropDownRoundedIcon />
                        ) : (
                          <ArrowDropUpRoundedIcon />
                        )}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={
                          apartment.ascensores === row.num_ascensores
                            ? ""
                            : apartment.ascensores > row.num_ascensores
                            ? "less-than"
                            : "greater-than"
                        }
                      >
                        {row.num_ascensores ?? "N/A"}
                        {apartment.ascensores === row.num_ascensores ? (
                          <></>
                        ) : apartment.ascensores > row.num_ascensores ? (
                          <ArrowDropDownRoundedIcon />
                        ) : (
                          <ArrowDropUpRoundedIcon />
                        )}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={
                          apartment.antiguedad === row.anos_antiguedad
                            ? ""
                            : apartment.antiguedad > row.anos_antiguedad
                            ? "less-than"
                            : "greater-than"
                        }
                      >
                        {row.anos_antiguedad ?? "N/A"}
                        {apartment.antiguedad === row.anos_antiguedad ? (
                          <></>
                        ) : apartment.antiguedad > row.anos_antiguedad ? (
                          <ArrowDropDownRoundedIcon />
                        ) : (
                          <ArrowDropUpRoundedIcon />
                        )}
                      </TableCell>
                      <TableCell align="right">{row.fuente}</TableCell>
                      <TableCell align="right">{row.label_inmueble}</TableCell>
                      <TableCell align="right">{`$ ${new Intl.NumberFormat().format(
                        row.last_ask_price ?? 0
                      )}`}</TableCell>
                      <TableCell align="right">{`$ ${new Intl.NumberFormat().format(
                        row.valormt2 ?? 0
                      )}`}</TableCell>
                      <TableCell align="right">{row.estado}</TableCell>
                      <TableCell align="right">
                        {row.obra_gris === 1
                          ? "Si"
                          : row.obra_gris === 0
                          ? "No"
                          : "N/A"}
                      </TableCell>
                      <TableCell>
                        <StarContainer>
                          <StarRankingSelector
                            defaultValue={row.quality}
                            number={starPricing?.valueString.length || 0}
                            name={"star_" + row.id}
                            handleChange={(v, n) =>
                              addManualRowData({
                                rowId: row.id,
                                data: {
                                  quality: v,
                                },
                              })
                            }
                            size="16px"
                            values={starPricing?.valueString || []}
                          />
                        </StarContainer>
                      </TableCell>
                      <TableCell>
                        <SquareCheckboxDropdown
                          index={index}
                          isAll={false}
                          options={normalizationOptions}
                          defaultValues={row.type_standardization ?? normalize}
                          onChange={(value) => {
                            if (normalize.includes("ninguno"))
                              setNormalize("ninguno");
                            setNormalize(value);
                          }}
                          customCloseFn={setClose}
                          customClose={close}
                        >
                          <StyledButtonGroup>
                            <Button
                              fillDiv
                              label="Normalizar"
                              disabled={normalize.includes("ninguno")}
                              onClick={() => {
                                if (!normalize.includes("ninguno"))
                                  changeStandardization(
                                    apartment,
                                    row,
                                    normalize
                                  );
                              }}
                            />
                            <Button
                              fillDiv
                              buttonType="secondary"
                              label="Limpiar"
                              onClick={() => {
                                setClose(true);
                                removeManualRowDataId(row.id);
                              }}
                            />
                            <Button
                              fillDiv
                              buttonType="secondary"
                              label="Cancelar"
                              onClick={() => {
                                setClose(true);
                                setNormalize(["ninguno"]);
                              }}
                            />
                          </StyledButtonGroup>
                        </SquareCheckboxDropdown>
                      </TableCell>

                      <TableCell align="right">
                        {row.tipo_consulta === 3
                          ? "Zona"
                          : row.tipo_consulta === 1
                          ? "Conjunto"
                          : row.tipo_consulta === 2
                          ? "Base de Datos"
                          : row.tipo_consulta === 4
                          ? "Otras Fuentes"
                          : ""}
                      </TableCell>
                      <TableCell>
                        <ButtonRemove onClick={() => removeRow(row.id)}>
                          <DeleteIcon />
                        </ButtonRemove>
                      </TableCell>
                      {allProperties && (
                        <>
                        <TableCell align="right">{row.pid}</TableCell>
                        <TableCell align="right">{row.nid}</TableCell>
                        <TableCell align="right">{row.vetustez}</TableCell>
                        <TableCell align="right">{row.fuente_id}</TableCell>
                        <TableCell align="right">{row.idconjunto}</TableCell>
                        <TableCell align="right">{row.num_apartamento}</TableCell>
                        <TableCell align="right">{row.direccion_homologada}</TableCell>
                        <TableCell align="right">{row.estrato_auto}</TableCell>
                        <TableCell align="right">{row.estrato_catastro}</TableCell>
                        <TableCell align="right">{row.telefono}</TableCell>
                        <TableCell align="right">{row.nombre_o_inmobiliaria}</TableCell>
                        <TableCell align="right">{row.ciudad_id}</TableCell>
                        <TableCell align="right">{moment(row.fechaCreacion).format("YYYY-MM-DD HH:mm")}</TableCell>
                        <TableCell align="right">{row.latitud}</TableCell>
                        <TableCell align="right">{row.longitud}</TableCell>
                        <TableCell align="right">{row.id}</TableCell>
                        <TableCell align="right">{row.tipo_consulta}</TableCell>
                        <TableCell align="right">{row.conjunto_edificio}</TableCell>
                        <TableCell align="right">{row.estado_id}</TableCell>
                        <TableCell align="right">{row.tipo_inmueble_id}</TableCell>
                        </>
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </StyledTable>
        </TableContainer>
      )}
      {selectedRows && !allProperties && (
        <TablePagination
          rowsPerPageOptions={[5]}
          component="div"
          count={rowsManuallyAdded.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      {!selectedRows && (
        <NoResultsContainer>
          <p className="top-message">No hay {title}.</p>
          <p className="bottom-message">
            Selecciona comparables de las tablas.
          </p>
        </NoResultsContainer>
      )}
    </StyledPaper>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addManualRowData: (data) => dispatch(addManualRowDataAction(data)),
  removeManualRowDataId: (id) => dispatch(removeManualRowDataIdAction(id)),
});

export default connect(
  null,
  mapDispatchToProps
)(withRouter(SelectedManualTable));
