import styled from "styled-components";

export const DashBoardWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-areas: 
    "header"
    "body";

  .header {
    grid-area: header;
  } 

  .body {
    grid-area: body;
  }
`;