import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import ListTableWrapper from "./style";
import Button from "../Button/button";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import { useQuery } from "@apollo/client";
import DotsLoading from "../DotsLoading/dotsLoading";
import { getBuckets } from "../../queries/queries";
import Overlay from "../overlay/overlay";

const ListBucket = (props) => {
  const { headers, refresh, setRefresh, inputVal } = props;
  const [row, setRow] = useState([]);
  const { loading, data, refetch } = useQuery(getBuckets(+inputVal));

  useEffect(() => {
    if (!refresh) return;
    refetch();
    setRefresh(false);
  }, [refresh, setRefresh, refetch]);

  let originalData = data?.getBuckets || data;

  useEffect(() => {
    if (typeof data !== "undefined") {
      refetch();
      setRow(originalData);
    }
  }, [data, originalData]);

  const editBucket = async (row) => {
    props.history.push({
      pathname: "/editBucket",
      state: {
        row: row,
      },
    });
  };

  if (loading) return <DotsLoading />;

  return (
    <>
      <ListTableWrapper>
        {row?.zoneMedianId !== 0 && (
          <div className="table-container">
            <table className="table">
              <thead>
                <tr>
                  {headers.map((item, index) => {
                    return <th key={index}>{item}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                <tr key={row?.zoneMedianId}>
                  <th>{Number(row?.zoneMedianId)}</th>
                  <th>{row?.city}</th>
                  <th>{row?.buckets}</th>
                  <th>
                    <Button
                      id="button"
                      typeClass="primary"
                      className="d-inline-block"
                      type="button"
                      onClick={() => {
                        editBucket(row);
                      }}
                    >
                      <EditRoundedIcon className="dollar-icon" />
                    </Button>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </ListTableWrapper>
      <Overlay></Overlay>
    </>
  );
};

export default withRouter(ListBucket);
