import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import ListTableWrapper from "./style";
import Button from "../Button/button";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import { useQuery, useMutation } from "@apollo/client";
import DotsLoading from "../DotsLoading/dotsLoading";
import { getListTaskEngine, deleteTaskEngine } from "../../queries/queries";
import Overlay from "../overlay/overlay";

const ListDiscount = (props) => {
  const { headers, refresh, setRefresh } = props;

  const [dataTable, setDataTable] = useState([]);

  const [dTaskEngine] = useMutation(deleteTaskEngine);

  const { loading, data, refetch } = useQuery(getListTaskEngine(null));

  useEffect(() => {
    if (!refresh) return;
    refetch();
    setRefresh(false);
  }, [refresh, setRefresh, refetch]);

  let originalData = data?.getListTaskEngine.result || data;

  useEffect(() => {
    if (typeof data !== "undefined") {
      setDataTable(originalData);
    }
  }, [data, originalData]);

  const deleteTask = async (id) => {
    dTaskEngine({
      variables: {
        id: id,
      },
    })
      .then(() => setRefresh(true))
      .catch((err) => {
        console.log(`Error in deleteTask deleteProcess: ${err.message}`);
      });
  };

  const editTask = async (row) => {
    props.history.push({
      pathname: "/editTask",
      state: {
        row: row,
      },
    });
  };

  if (loading) return <DotsLoading />;

  return (
    <>
      <ListTableWrapper>
        <Button
          size="small"
          typeClass="primary"
          className="d-inline-block button-new"
          type="button"
          onClick={() => {
            editTask({});
          }}
        >
          Nuevo
        </Button>
        <div className="table-container">
          <table className="table">
            <thead>
              <tr>
                {headers.map((item, index) => {
                  return <th key={index}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {dataTable?.map((row) => (
                <tr key={row.id}>
                  <th>{Number(row.id)}</th>
                  <th>{row.name}</th>
                  <th>{row.priority}</th>
                  <th>
                    <Button
                      id="button"
                      typeClass="primary"
                      className="d-inline-block"
                      type="button"
                      onClick={() => {
                        editTask(row);
                      }}
                    >
                      <EditRoundedIcon className="dollar-icon" />
                    </Button>
                  </th>
                  <th>
                    <div
                      onClick={() => {
                        deleteTask(row.id);
                      }}
                    >
                      <DeleteRoundedIcon className="delete-icon" />
                    </div>
                  </th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </ListTableWrapper>
      <Overlay></Overlay>
    </>
  );
};

export default withRouter(ListDiscount);
