export const coefficientActionTypes = {
  SET_COEFFICIENT: "SET_COEFFICIENT",
  REMOVE_COEFFICIENT: "REMOVE_COEFFICIENT",
};

export const setCoefficientAction = (hasChange) => ({
  type: coefficientActionTypes.SET_COEFFICIENT,
  payload: hasChange,
});

export const removeCoefficientAction = () => ({
  type: coefficientActionTypes.REMOVE_COEFFICIENT,
});
