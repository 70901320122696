import React from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import DotsLoading from "../DotsLoading/dotsLoading";
import Paper from "@material-ui/core/Paper";
import ComparisonTableCell from "../ComparisonTableCell/ComparisonTableCell";
import SortingTableHeader from "../SortingTableHeader/SortingTableHeader";
import NotificationBanner from "../NotificationBanner/NotificationBanner";

import {
  TableTitleContainer,
  TableTitle,
  AverageM2Container,
} from "./AlreadyPriced.style";

const AlreadyPriced = (props) => {
  const {
    title,
    inmuebles,
    loading,
    page,
    handlePageChange,
    order,
    orderBy,
    handleRequestSort,
    apartment,
    totalItems,
    tableHeadCells,
    averageM2,
  } = props;

  return (
    <Paper>
      <TableContainer>
        <TableTitleContainer>
          <TableTitle>{title}</TableTitle>
          <AverageM2Container>
            <strong>
              Pricing Manual Avg m<sup>2</sup> :
            </strong>
            <span id="average-m2"> {averageM2}</span>
          </AverageM2Container>
        </TableTitleContainer>
        <Table style={{ tableLayout: "fixed" }}>
          <SortingTableHeader
            headerCells={tableHeadCells}
            keyPropName="label"
            sortPropName="value"
            labelPropName="label"
            orderBy={orderBy}
            order={order}
            handleSort={handleRequestSort}
            bold
          />
          <TableBody>
            {inmuebles.map((inmueble) => (
              <TableRow key={inmueble.inmuebleId}>
                {tableHeadCells.map((cell) => (
                  <ComparisonTableCell
                    key={cell.value}
                    type={cell.type}
                    ignoreComparison={["fuente", "tipoInmueble"].includes(
                      cell.value
                    )}
                    dataA={inmueble[cell.value]}
                    dataB={
                      cell.value !== "ask_price"
                        ? apartment[cell.value]
                        : apartment.lastAskPrice
                    }
                  />
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {inmuebles.length === 0 && !loading && (
          <NotificationBanner
            type="info"
            message="No se han encontrado inmuebles con pricing manual realizado en el mismo conjunto."
            style={{ maxWidth: "500px", margin: "24px" }}
          />
        )}
        {inmuebles.length > 0 && (
          <TablePagination
            rowsPerPageOptions={[5]}
            component="div"
            count={totalItems ?? 0}
            rowsPerPage={5}
            page={page}
            onPageChange={handlePageChange}
          />
        )}
        {loading && <DotsLoading />}
      </TableContainer>
    </Paper>
  );
};

export default AlreadyPriced;
