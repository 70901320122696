import styled from "styled-components";
import {
  muliRegular,
  scarpaGray600,
  scarpaGray700,
} from "../../StylesConstants";

export const TypologyListWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05),
    0px 2px 4px rgba(48, 49, 51, 0.1);
  border-radius: 16px;
  width: 100%;
  min-height: 197px;

  .title {
    margin: 0;
    font-family: ${muliRegular};
    font-weight: 800;
    font-size: 20px;
    line-height: 28px;
    color: ${scarpaGray700};
    padding: 16px 16px 24px 16px;
    border-bottom: 1px #dddddd solid;
  }

  .column-cards {
    display: grid;
    grid-template-columns: repeat(12, minmax(57px, 1fr));
    margin: 0;
    column-gap: 48px;
    row-gap: 16px;

    .mini-card {
      width: 57px;
      height: 88px;
      background: rgba(230, 229, 231, 0.25);
      border-radius: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .mini-card-body {
        margin: 0;
        color: ${scarpaGray600};
      }

      .aconst-title {
        padding-bottom: 8px;
        font-size: 15px;
        line-height: 24px;
      }

      .count-body {
        font-weight: 700;
        font-size: 15px;
        line-height: 24px;
      }
    }
  }
`;
