import React, { useState, useEffect } from "react";
import { SwitchButtonWrapper } from "./style";

const SwitchButton = ({
  value,
  handleValue,
  idButton,
  labelActive,
  labelInactive,
  ...props
}) => {
  const [messageActive, setMessageActive] = useState("Si");
  const [messageInactive, setMessageInactive] = useState("No");

  const handleToggle = (e) => {
    handleValue(!value);
  };

  useEffect(() => {
    if (labelActive) {
      setMessageActive(labelActive);
    }
    if (labelInactive) {
      setMessageInactive(labelInactive);
    }
  }, [labelActive, labelInactive]);

  return (
    <SwitchButtonWrapper buttonOn={value}>
      <div className="container">
        <div className="title">
          <span>{props.text}</span>
        </div>
        <div>
          <input
            checked={value}
            onChange={handleToggle}
            className="react-switch-checkbox"
            id={idButton}
            type="checkbox"
          />
          <label className="react-switch-label" htmlFor={idButton}>
            <span className={`react-switch-button`}>
              {value ? messageActive : messageInactive}
            </span>
          </label>
        </div>
      </div>
    </SwitchButtonWrapper>
  );
};

SwitchButton.defaultProps = { idButton: "react-switch-new" };

export default SwitchButton;
