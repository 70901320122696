import React, { useEffect, useState } from "react";
import {
  SqrtCheckboxWrapper,
  StyledCheckbox,
  StyledFormGroup,
  StyledFormControlLabel,
  StyledInput,
  StyledInputAll,
} from "./style";
import { FormControl, Select } from "@material-ui/core";

const SquareCheckboxDropdown = (props) => {
  const {
    index,
    isAll,
    onChange,
    options,
    defaultValues,
    customClose,
    customCloseFn,
  } = props;
  const [selectedValues, setSelectedValues] = useState(
    defaultValues.length > 0 ? defaultValues : ["ninguno"]
  );

  const [open, setOpen] = useState(false);

  const handleOptions = async (item) => {
    if (item === "ninguno" && selectedValues.includes(item)) {
      return;
    } else if (item === "ninguno") {
      setSelectedValues(["ninguno"]);
      return;
    }

    if (selectedValues.length === 1 && selectedValues.includes(item)) {
      setSelectedValues(["ninguno"]);
      return;
    }

    const removedAll = selectedValues.filter((value) => value !== "ninguno");

    if (removedAll.includes(item)) {
      const newItems = removedAll.filter((value) => value !== item);
      setSelectedValues(newItems);
    } else {
      const newItems = [...removedAll, item];
      setSelectedValues(newItems);
    }
  };

  useEffect(() => {
    onChange(selectedValues);
    // eslint-disable-next-line
  }, [selectedValues]);

  useEffect(() => {
    if (customClose) {
      setOpen(false);
      customCloseFn(false);
    }
  }, [open, customClose, setOpen, customCloseFn, defaultValues]);

  useEffect(() => {
    if (defaultValues.includes("ninguno") || !defaultValues.length)
      handleOptions("ninguno");
    // eslint-disable-next-line
  }, [defaultValues]);

  return (
    <SqrtCheckboxWrapper>
      <div className="container-check">
        <div className="dropdow-container">
          <FormControl>
            <Select
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
              }}
              multiple
              native={false}
              open={open}
              value={Object.values(options)}
              id={`sqrt-checkbox-dropdown-${index}`}
              onOpen={() => setOpen(true)}
              input={!isAll ? <StyledInput /> : <StyledInputAll />}
              renderValue={() =>
                selectedValues.length === 1
                  ? selectedValues[0]
                  : `${selectedValues[0]} + ${+selectedValues.length - 1}`
              }
            >
              <StyledFormGroup aria-label="position">
                {options &&
                  Object.entries(options).map(([key, item], idx) => {
                    return (
                      <StyledFormControlLabel
                        label={item}
                        labelPlacement="end"
                        control={
                          <StyledCheckbox
                            onChange={() => {
                              handleOptions(key);
                            }}
                            value={item}
                            checked={
                              selectedValues.includes(key) ? true : false
                            }
                            key={`${idx}-${Math.random()}`}
                          />
                        }
                      />
                    );
                  })}
                {props.children}
              </StyledFormGroup>
            </Select>
          </FormControl>
        </div>
      </div>
    </SqrtCheckboxWrapper>
  );
};

export default SquareCheckboxDropdown;
