import styled from "styled-components";
import {
  electricPurple800,
  tomatoCoral500,
  archiaRegular,
  grey,
  size,
} from "../../StylesConstants";

export const TabsContainerWrapper = styled.section`
  width: 100%;

  .MuiTabs-root {
    background-color: #fcfdff;
    border-bottom: 1px solid ${grey};
    border-top: 1px solid ${grey};
    padding-top: 16px;
  }
  .MuiTabs-flexContainer {
    padding-bottom: 11px;
  }
  .MuiButtonBase-root.MuiTab-root {
    font-family: ${archiaRegular};
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    outline: 0;
    text-transform: none;
  }
  .MuiTab-textColorPrimary.Mui-selected {
    font-weight: 600;
    color: ${electricPurple800};
    outline: 0;
    text-transform: none;
  }
  .PrivateTabIndicator-root-35 {
    height: 4px;
  }
  .MuiTabs-indicator {
    background-color: ${tomatoCoral500};
  }

  @media (min-width: ${size.tablet}) {
    .MuiTabs-root {
      padding-top: 16px;
    }
  }

  @media (min-width: ${size.desktop}) {
    .tab-panel {
      background-color: #fcfdff;
    }

    .MuiTabs-scroller {
      margin-bottom: 0px;
    }
  }

  @media (min-width: ${size.desktopBigger}) {
    .MuiTabs-root {
      display: grid;
      justify-items: center;
    }

    .MuiTabs-scroller {
      margin-bottom: 0px;

      min-width: 1254px;
    }
  }
`;

export const TabPannelWrapper = styled.section`
  padding-top: 32px;
  padding-left: 16px;
  padding-right: 16px;
  background-color: #fcfdff;

  @media (min-width: ${size.tablet}) {
    padding-top: 25px;
    padding-left: 24px;
    padding-right: 24px;
  }
  @media (min-width: ${size.desktop}) {
    padding-top: 32px;
    margin: auto;
  }
  @media (min-width: ${size.desktopBigger}) {
  }
  @media (min-width: ${size.desktopMaxBigger}) {
  }
`;
