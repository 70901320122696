export const tableHeadCells = [
  {
    value: "area",
    label: "Area",
    type: "area",
  },
  {
    value: "garajes",
    label: "Garajes",
  },
  {
    value: "banos",
    label: "Baños",
  },
  {
    value: "piso",
    label: "Piso",
  },
  {
    value: "ascensores",
    label: "Ascensores",
  },
  {
    value: "fuente",
    label: "Fuente",
  },
  {
    value: "ask_price",
    type: "price",
    label: "Ask Price",
  },
  {
    value: "precio_manual",
    type: "price",
    label: "Precio Manual",
  },
  {
    value: "precioManualComite",
    type: "price",
    label: "Precio Manual Comité",
  },
  {
    value: "fechaPrecioManual",
    type: "date",
    label: "Fecha Precio Manual",
  },
  {
    value: "precioFinalDeCierre",
    type: "price",
    label: "Precio Final de Cierre",
  },
];
