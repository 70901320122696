import { selectedRowsActionTypes } from "../actions/selectedRows.actions";
import { addManyRows, extractUsableRowData } from "../utils/selectedRows.utils";

const INITIAL_STATE = [];

const selectedRowsReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case selectedRowsActionTypes.SET_ROWS:
      return addManyRows({ state, payload, flags: { tipo_consulta: 2 } });
    case selectedRowsActionTypes.ADD_ZONE_ROW: {
      const cleanedRow = extractUsableRowData(payload);
      return [...state, { ...cleanedRow, tipo_consulta: 3 }];
    }
    case selectedRowsActionTypes.ADD_MANY_ZONE_ROWS:
      return addManyRows({ state, payload, flags: { tipo_consulta: 3 } });
    case selectedRowsActionTypes.ADD_LOT_ROW: {
      const cleanedRow = extractUsableRowData(payload);
      return [...state, { ...cleanedRow, tipo_consulta: 1 }];
    }
    case selectedRowsActionTypes.ADD_MANY_LOT_ROWS:
      return addManyRows({ state, payload, flags: { tipo_consulta: 1 } });
    case selectedRowsActionTypes.ADD_ROW_EXTERNAL: {
      const cleanedRow = extractUsableRowData(payload);
      return [...state, { ...cleanedRow, external: true }];
    }
    case selectedRowsActionTypes.ADD_MANY_EXTERNAL_ROWS:
      return addManyRows({ state, payload, flags: { external: true } });
    case selectedRowsActionTypes.REMOVE_ROW: {
      const byPayloadId = (row) => row.id !== payload;
      return state.filter(byPayloadId);
    }
    case selectedRowsActionTypes.REMOVE_MANY_ROWS: {
      const payloadIds = payload.map((row) => row.id);
      const byPayloadIds = (row) => !payloadIds.includes(row.id);
      return state.filter(byPayloadIds);
    }
    case selectedRowsActionTypes.REMOVE_ALL_ZONE_ROWS: {
      const byTipoConsulta3 = (row) => row.tipo_consulta !== 3;
      return state.filter(byTipoConsulta3);
    }
    case selectedRowsActionTypes.REMOVE_ALL_LOT_ROWS: {
      const byTipoConsulta1 = (row) => row.tipo_consulta !== 1;
      return state.filter(byTipoConsulta1);
    }
    case selectedRowsActionTypes.REMOVE_ALL_EXTERNAL_ROWS: {
      const byExternalFlag = (row) => !row.external;
      return state.filter(byExternalFlag);
    }
    case selectedRowsActionTypes.REMOVE_ROWS_BY_IDS: {
      const byIds = (row) => !payload.includes(row.id);
      return state.filter(byIds);
    }
    case selectedRowsActionTypes.RESET_ROWS:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default selectedRowsReducer;
