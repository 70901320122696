import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import { PaginatorWrapper } from "./style";
import { isMobile } from "react-device-detect";

const HabiPagination = (props) => {
  const [perPage, setPerPage] = useState({
    currentPage: 1,
    totalItemsCount: 100,
    itemsCountPerPage: 20,
  });

  useEffect(() => {
    setPerPage(props.paginator);
  }, [props.paginator]);

  const handlePageChange = (pageNumber) => {
    setPerPage({ ...perPage, currentPage: pageNumber });
    props.handlePageChange(pageNumber);
  };

  return (
    <PaginatorWrapper className="text-center">
      <Pagination
        activePage={perPage.currentPage}
        itemsCountPerPage={perPage.itemsCountPerPage}
        totalItemsCount={perPage.totalItemsCount}
        pageRangeDisplayed={isMobile ? 3 : 5}
        onChange={handlePageChange}
      />
    </PaginatorWrapper>
  );
};

export default HabiPagination;
