import styled from "styled-components";
import {
  size,
  purple,
  black,
  white,
  error,
  scarpaGray700,
  scarpaGray500,
} from "../../StylesConstants";

export const InputWrapper = styled.div`
  min-height: 40px;
`;

export const TitleForm = styled.div`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${scarpaGray700};
  justify-content: left;
  @media screen and (min-width: ${size.tablet}) {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  @media screen and (min-width: ${size.desktop}) {
    justify-content: center;
    align-items: center;
  }
`;
export const ZoneToggle = styled.div`
  width: 100%;
  display: grid;
  justify-content: left;
  @media screen and (min-width: ${size.tablet}) {
    justify-content: center;
    align-items: center;
  }
  @media screen and (min-width: ${size.desktop}) {
    justify-content: center;
    align-items: center;
  }
`;
export const Note = styled.div`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: ${scarpaGray500};
  margin-top: 16px;
  margin-bottom: 40px;
`;
export const ToggleWrapper = styled.div`
  legend {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    display: flex;
    color: ${scarpaGray700};
    margin-bottom: 8px;
  }
  .radio-container {
    float: left;
    width: 90px;
    max-width: 90px;
    height: 40px;
  }
  .radio-container-col-one {
    float: left;
    width: 100px;
    max-width: 100px;
    height: 40px;
  }
  .radio-container:first-child {
    border-right: 1px solid #d3d2d4;
    input:checked + label {
      border-left: 2px solid ${purple};
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    .error-radio {
      input:checked + label {
        border-left: 2px solid ${error};
      }
    }
  }
  .radio-container:last-child {
    border-left: 1px solid #d3d2d4;
    input:checked + label {
      border-right: 2px solid ${purple};
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    .error-radio {
      input:checked + label {
        border-right: 2px solid ${error};
      }
    }
  }
  .switch-toggle {
    float: left;
    background: #fcfcfc;
    border: 1px solid #d3d2d4;
    border-radius: 8px;
  }
  .radio-container input {
    position: absolute;
    opacity: 0;
  }
  .radio-container input + label {
    float: left;
    cursor: pointer;
    width: 100%;
    height: 40px;
    text-align: center;
    font-size: 16px;
    color: ${black};
    padding-top: 8px;
  }
  .error {
    display: flex;
    width: 100%;
    padding-top: 15px;
  }
  .radio-container {
    input:checked + label {
      background: ${purple};
      color: ${white};
      font-weight: bold;
    }
    .error-radio {
      input:checked + label {
        background: ${error};
      }
    }
  }
`;
