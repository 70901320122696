import styled from "styled-components";

export const CommentContainerWrapper = styled.section`
  .comment-button {
    margin-bottom: 20px;
  }
  .MuiSvgIcon-root {
    margin-right: 5px;
  }
  .field-container {
    display: grid;
    .comment-save-button {
      justify-self: end;
    }
  }
`;
