import React from "react";
import { connect } from "react-redux";
import { updatePricingDataAction } from "../../redux/actions/pricingData.actions";
import { selectApartment } from "../../redux/selectors/apartment.selectors";
import { selectPricingData } from "../../redux/selectors/pricingData.selectors";
import TablePricingManual from "./tablePricingManual";

const TablePricingManualContainer = (props) => {
  const headerList = [
    "NID",
    "Conjunto",
    "Fuente",
    "Dirección",
    "Área",
    "Last ask price",
    "Last ask price por m",
    "Pricing por m",
    "Ask Price",
  ];

  const { apartment, updatePricigData, pricingData } = props;

  return (
    <TablePricingManual
      headers={headerList}
      apartment={apartment}
      updatePricingData={updatePricigData}
      pricingData={pricingData}
    />
  );
};

const mapStateToProps = (state) => ({
  apartment: selectApartment(state),
  pricingData: selectPricingData(state),
});

const mapDispatchToProps = (dispatch) => ({
  updatePricigData: (kv) => dispatch(updatePricingDataAction(kv)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TablePricingManualContainer);
