import { userDataActionTypes } from "../actions/userData.actions";

const INITIAL_STATE = {};

const userDataReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case userDataActionTypes.UPDATE_USER_DATA: {
      return { ...state, ...action.payload };
    }
    case userDataActionTypes.RESET_USER_DATA: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default userDataReducer;
