import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import LinkRoundedIcon from "@material-ui/icons/LinkRounded";
import { getPropertyPhotosId } from "../../queries/queries";
import { StyledLink, StyledLinkContainer } from "./LinkPhotos.style";

const LinkPhotos = () => {
  const { apartment } = useSelector((state) => state);
  const { nid } = apartment;
  const [driveId, setDriveId] = useState();

  const { data } = useQuery(getPropertyPhotosId({ nid: nid }));

  useEffect(() => {
    if (nid && data) {
      setDriveId(data.getPropertyPhotosId.driveId);
    }
  }, [nid, data]);

  const url = `https://drive.google.com/drive/folders/${driveId}`;
  return (
    <StyledLinkContainer>
      {driveId !== "" && (
        <StyledLink target="_blank" rel="noopener noreferrer" href={url}>
          <LinkRoundedIcon className="link-icon" />
          Fotos Avalúo {nid}
        </StyledLink>
      )}
    </StyledLinkContainer>
  );
};

export default LinkPhotos;
