import { pricingDataActionTypes } from "../actions/pricingData.actions";

const INITIAL_STATE = { percentil: 30, coeficiente: 0 };

const pricingDataReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case pricingDataActionTypes.UPDATE_PRICING_DATA_VALUE:
      return { ...state, ...payload };
    case pricingDataActionTypes.REMOVE_PRICING_DATA_VALUE: {
      const { [payload]: omittedValue, ...rest } = state;
      return rest;
    }
    case pricingDataActionTypes.SET_PRICING_DATA_VALUES:
      return payload;
    case pricingDataActionTypes.RESET_PRICING_DATA_VALUES:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default pricingDataReducer;
