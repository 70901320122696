export const zoneList = (dataZone) => {
  if (!dataZone) return;

  const zoneObject = dataZone.reduce(
    (acc, cur) => ({ ...acc, [cur.label]: cur.id }),
    { Todas: 0 }
  );
  return zoneObject;
};

export const cityList = (dataCity) => {
  if (!dataCity) return;

  const cityObject = dataCity.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.label]: cur.id,
    }),
    {}
  );
  return cityObject;
};

export const headerList = [
  "Fecha de Creación",
  "NID",
  "Conjunto",
  "Fuente",
  "Tipo Inmueble",
  "Dirección",
  "Last_ask_price",
  " ",
];

export const options = {
  0: "Todas",
  3: "Web",
  1: "Ventana",
  7: "Estudio Inmueble",
  20: "CRM",
  29: "Línea telefónica Medellin",
  28: "Ventana-Scraping",
  4: "Web Scraping",
  30: "Centro de experiencia Medellin",
  33: "Habi constructora",
  34: "Línea telefónica Cali",
  35: "Comercial",
  36: "Centro experiencia Bogotá",
  38: "Centro de experiencia Cali",
  39: "Broker",
  40: "Form-mkt",
  47: "MKT",
};

export const headerDiscount = [" ", "Nombre", "Prioridad", " ", " "];

export const headerBucket = [" ", "Ciudad", "Zona Mediana", " "];
