import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery } from "@apollo/client";
import { getPropertyInfo, saveHabiProperty } from "../../queries/queries";
import Button from "../ButtonV2/Button";
import {
  createDataToSave,
  highOptions,
  renderName,
  renderNameB,
  typeInput,
} from "./LeadInfo.utils";
import {
  ErrorMessage,
  StyledContainer,
  StyledContent,
  StyledGroupInput,
  StyledInput,
  StyledTitle,
} from "./style";
import { clearPolygonAction } from "../../redux/actions/mapPolygon.actions";
import { setApartmentAction } from "../../redux/actions/apartment.actions";
import DotsLoading from "../DotsLoading/dotsLoading";
import RoundedDropdown from "../RoundedDropdown/roundedDropdown";
import Currency from "../Currency/Currency";
import LinkPhotos from "../LinkPhotos/LinkPhotos";

const LeadInfo = (props) => {
  const [input, setInput] = useState({});
  const [highName, setHighName] = useState();
  const [highIds, setHighIds] = useState();
  const { apartment } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [saveProperty] = useMutation(saveHabiProperty);
  const [err, setErr] = useState(false);

  const { loading, error, data, refetch } = useQuery(
    getPropertyInfo({ nid: apartment.nid })
  );

  useEffect(() => {
    if (input && Object.keys(input).length > 0) {
      setHighName(input.prima_altura === "si" ? "Si" : "No");
      setHighIds(input.prima_altura === "si" ? "si" : "no");
    } else {
      setInput(data?.getPropertyInfo[0]);
    }
  }, [data, input]);

  const handleChange = async (event) => {
    setInput({ ...input, [event.target.name]: +event.target.value });
  };

  const handleCurrency = async (key, val) => {
    setInput({ ...input, [key]: +val });
  };

  const handleSubmit = async (dataToSave) => {
    const data = createDataToSave({ ...dataToSave, prima_altura: highIds });
    saveProperty({
      variables: { ...data },
    })
      .then((res) => {
        refetch().then((res) => {
          setInput(res.data?.getPropertyInfo[0]);
          dispatch(clearPolygonAction());
          dispatch(
            setApartmentAction({
              ...apartment,
              area: dataToSave.area,
              habitaciones: dataToSave.num_habitaciones,
              banos: dataToSave.banos,
              piso: dataToSave.num_piso,
              garajes: dataToSave.garajes,
              ascensores: dataToSave.num_ascensores,
              antiguedad: dataToSave.anos_antiguedad,
            })
          );
        });
      })
      .catch((err) => setErr(err ?? false));
  };

  return (
    <>
      {error && <pre>Un error ha occurido.</pre>}
      {input && (
        <StyledContainer>
          {loading && <DotsLoading />}
          <StyledTitle>Información del Registro</StyledTitle>
          <StyledContent>
            {Object.entries(input ?? {}).map(([key, val], idx) => {
              if (Object.keys(renderName).includes(key))
                return (
                  <StyledGroupInput key={`group-lead-input-${idx}`}>
                    <span className="label-item">{renderName[key]}</span>
                    {typeInput[key] !== "select" &&
                      typeInput[key] !== "currency" && (
                        <StyledInput
                          required={true}
                          type="number"
                          key={`lead-input-${idx}`}
                          defaultValue={input[key] ?? ""}
                          name={key}
                          onChange={(event) => {
                            handleChange(event);
                          }}
                        />
                      )}
                    {typeInput[key] === "currency" && (
                      <Currency
                        key={`currency-input-${idx}`}
                        id={`currency-input-${idx}`}
                        defaultValue={input[key]}
                        name={key}
                        setFieldValue={handleCurrency}
                      />
                    )}
                    {typeInput[key] === "select" && (
                      <RoundedDropdown
                        idDropdown={`select-${key}`}
                        option={highOptions}
                        setOption={setHighName}
                        title={highName}
                        setFilter={setHighIds}
                      />
                    )}
                  </StyledGroupInput>
                );
              else return <></>;
            })}
            <StyledGroupInput>
            <span className="label-item">Agente de pricing 2</span>
              <StyledInput
                required={true}
                type="text"
                key={`lead-input-agent`}
                placeholder="agente"
                name="agente_pricing_2"
                onChange={(event) => {
                  setInput({
                    ...input,
                    [event.target.name]: event.target.value,
                  });
                }}
              />
            </StyledGroupInput>
          </StyledContent>
          <StyledTitle>Valores Visita Operativa</StyledTitle>
          <StyledContent>
            {Object.entries(input ?? {}).map(([key, val], idx) => {
              if (Object.keys(renderNameB).includes(key))
                return (
                  <StyledGroupInput key={`group-lead-bi-input-${idx}`}>
                    <span className="label-item">{renderNameB[key]}</span>
                    <StyledInput
                      disabled={
                        key !== "v_o_area_construida_confirmado" ? true : false
                      }
                      required={true}
                      type="number"
                      key={`lead-bi-input-${idx}`}
                      defaultValue={input[key] ?? ""}
                      name={key}
                      onChange={(event) => {
                        handleChange(event);
                      }}
                    />
                  </StyledGroupInput>
                );
              else return <></>;
            })}
            <LinkPhotos />
          </StyledContent>
          {err && (
            <ErrorMessage error={err}>
              Todos los campos son requeridos
            </ErrorMessage>
          )}
          <Button
            size="medium"
            style={{ margin: "16px" }}
            label="Guardar"
            onClick={() => handleSubmit(input)}
          />
        </StyledContainer>
      )}
    </>
  );
};

export default LeadInfo;
