import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { removeCoefficientAction } from "../../redux/actions/coeffientRows.actions";
import { addManualRowDataAction } from "../../redux/actions/manualRowData.actions";
import { getCoefficient } from "../../services/pricingApi";
import { CoefficientWrapper } from "./CoefficientInfo.style";

const CoefficientInfo = ({rowsDataForMutation}) => {
  const dispatch = useDispatch();
  const {selectedRows, coefficient} = useSelector(state => state)
  const [dataCoefficient, setDataCoefficient] = useState({});

  useEffect(() => {
    if (selectedRows.length > 0 || coefficient) {
      getCoefficient({ table: rowsDataForMutation })
        .then((response) => {
          setDataCoefficient(response.data);
          response.data.table.forEach((row) => {
            const { id, ...rest } = row;
            dispatch(
              addManualRowDataAction({
                rowId: id,
                data: rest,
              })
            );
          });
        })
        .catch((error) => console.log(error))
        .finally(() => dispatch(removeCoefficientAction()));
    }
    // eslint-disable-next-line
  }, [selectedRows, dispatch, coefficient]);

  return (
    <CoefficientWrapper>
      <div className="coefficient">
        <span className="title-coefficient">Coeficiente actual:</span>
        <span
          className={
            dataCoefficient.coefficient > 0.09
              ? "high-value"
              : "value-coefficient"
          }
        >
          {dataCoefficient.coefficient}
        </span>
      </div>
      <div className="coefficient">
        <span className="title-coefficient">Coeficiente proyectado:</span>
        <span className="value-coefficient">
          {dataCoefficient.coefficient_proy}
        </span>
      </div>
      <div className="coefficient">
        <span className="title-coefficient">Media:</span>
        <span className="value-coefficient">
          {Number(dataCoefficient.mean).toFixed(2)}
        </span>
      </div>
      <div className="coefficient">
        <span className="title-coefficient">Mediana:</span>
        <span className="value-coefficient">{dataCoefficient.median}</span>
      </div>
    </CoefficientWrapper>
  );
};

export default CoefficientInfo;
