import React, { useRef } from "react";
import { connect } from "react-redux";
import { useQuery } from "@apollo/client";
import { getComparableCoordinates } from "../../queries/queries";
import MapComparables from "./MapComparables.compontent";
import { removeApartmentAction } from "../../redux/actions/apartment.actions";
import { handleApiLoaded } from "./MapComparables.utils";
import {
  clearPolygonAction,
  setPolygonAction,
} from "../../redux/actions/mapPolygon.actions";
import { selectHasValidPolygon } from "../../redux/selectors/mapPolygon.selectors";
import { useHistory } from "react-router";
import { updateUserDataAction } from "../../redux/actions/userData.actions";

const MapComparablesContainer = (props) => {
  const buttonRef = useRef(null);

  const {
    removeApartment,
    apartment,
    setPolygon,
    clearPolygon,
    hasValidPolygon,
    userData,
    updateUser,
  } = props;

  const { inmuebleID, latitud, longitud } = apartment;

  const center = { lat: latitud, lng: longitud };

  const { error, data } = useQuery(
    getComparableCoordinates({
      inmuebleId: inmuebleID,
      lat: latitud,
      lng: longitud,
    })
  );

  const comparableCoords = data?.getComparableCoordinates ?? [];

  const history = useHistory();

  if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>;

  const closeMapHandler = () => {
    clearPolygon();
    removeApartment();
    if (userData.role === "ventas") {
      updateUser({
        nid: null,
      });
      history.push("/simulador");
    }
  };

  return (
    <MapComparables
      center={center}
      comparableCoords={comparableCoords}
      isMarkerShown
      closeMapHandler={closeMapHandler}
      handleApiLoaded={handleApiLoaded}
      setPolygon={setPolygon}
      clearPolygon={clearPolygon}
      buttonRef={buttonRef}
      hasValidPolygon={hasValidPolygon}
    />
  );
};

const mapStateToProps = (state) => ({
  apartment: state.apartment,
  hasValidPolygon: selectHasValidPolygon(state),
  userData: state.userData,
});

const mapDispatchToProps = (dispatch) => ({
  removeApartment: () => dispatch(removeApartmentAction()),
  setPolygon: (coordArr) => dispatch(setPolygonAction(coordArr)),
  clearPolygon: () => dispatch(clearPolygonAction()),
  updateUser: (data) => dispatch(updateUserDataAction(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MapComparablesContainer);
