import styled from "styled-components";
import { nightBlue000, scarpaGray300, scarpaGray700 } from "../../StylesConstants";

export const OverlayWrapper = styled.div`
  .overlay {
    height: 0%;
    width: 100%;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    background-color: ${nightBlue000};
    overflow-y: auto;
    transition: 0.5s;

    .container-call {
      display: flex;
      justify-content: center;
    }
  }

  .overlay-open {
    width: 100% !important;
    height: 100% !important;
  }

  .overlay-close {
    width: 0% !important;
    height: 0% !important;
  }

  .header {
    display: grid;
    grid-template-columns: 1fr;
    padding: 14px 16px;
    .logo-overlay {
      justify-self: baseline;
    }

    .close-overlay {
      justify-self: end;
      cursor: pointer;
      &:hover {
        color: ${scarpaGray300};
      }
    }
  }

  .overlay .closebtn {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    color: ${scarpaGray700};
  }
`;
