import React, { useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import CheckBoxWrapper from "./style";
import { useEffect } from "react";

const CheckBoxDropdown = ({
  option,
  setOption,
  title,
  text,
  idDropdown,
  setIds,
  ids,
  ...props
}) => {
  const [selectedValues, setSelectedValues] = useState(ids ? ids : [0]);
  const [newTitle, setNewTitle] = useState(title ? title : ["Todas"]);

  const handleOptions = (key, item) => {
    if (
      key === 0 &&
      selectedValues.includes(key) &&
      item === "Todas" &&
      newTitle.includes(item)
    ) {
      return;
    } else if (key === 0 && item === "Todas") {
      setSelectedValues([0]);
      setNewTitle(["Todas"]);
      return;
    }

    if (
      selectedValues.length === 1 &&
      selectedValues.includes(key) &&
      newTitle.length === 1 &&
      newTitle.includes(item)
    ) {
      setSelectedValues([0]);
      setNewTitle(["Todas"]);
      return;
    }

    const removedTodas = selectedValues.filter((value) => value !== 0);
    const removedItems = newTitle.filter((value) => value !== "Todas");

    if (removedTodas.includes(key) && removedItems.includes(item)) {
      const newKeys = removedTodas.filter((value) => value !== key);
      const newItems = removedItems.filter((value) => value !== item);
      setSelectedValues(newKeys);
      setNewTitle(newItems);
    } else {
      const newKeys = [...removedTodas, key];
      const newItems = [...removedItems, item];
      setSelectedValues(newKeys);
      setNewTitle(newItems);
    }
  };

  useEffect(() => {
    setOption(newTitle);
    setIds(selectedValues);
  }, [newTitle, setOption, selectedValues, setIds]);

  return (
    <CheckBoxWrapper>
      <div className="container-check">
        <span>{text}</span>
        <div className="dropdow-container">
          <Dropdown drop="down">
            <Dropdown.Menu key={idDropdown} id={`check-dropdown-${idDropdown}`}>
              {option &&
                Object.entries(option).map(([key, item], index) => {
                  return (
                    <Form.Check
                      type="checkbox"
                      className="check-box"
                      aria-label="option 1"
                      onChange={() => {
                        handleOptions(+key, item);
                      }}
                      label={item}
                      checked={selectedValues.includes(+key) ? true : false}
                      key={index}
                    />
                  );
                })}
            </Dropdown.Menu>
            <Dropdown.Toggle id="check-custom">
              {selectedValues.length === 1
                ? title[0]
                : `${title[0]} + ${+selectedValues.length - 1}`}
              <ExpandMoreRoundedIcon className="icon-arrow" />
            </Dropdown.Toggle>
          </Dropdown>
        </div>
      </div>
    </CheckBoxWrapper>
  );
};

CheckBoxDropdown.defaultProps = { idDropdown: "check-dropdown" };

export default CheckBoxDropdown;
