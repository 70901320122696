import { mapPolygonActionTypes } from "../actions/mapPolygon.actions";

const INITIAL_STATE = { selected: false, polygon: [] };

const mapPolygonReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case mapPolygonActionTypes.SET_POLYGON_COORD_ARR:
      return { ...state, polygon: [...payload] };
    case mapPolygonActionTypes.SELECT_POLYGON:
      return { ...state, selected: true };
    case mapPolygonActionTypes.CLEAR_POLYGON:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default mapPolygonReducer;
