import styled from 'styled-components';
import {
  white,
  size,
  error,
  grey,
  black,
  hintPerano100,
  electricPurple600,
} from '../../StylesConstants';

const ButtonWrapper = styled.div`
  button {
    border: none;
    border-radius: 100px;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    width: 134px;
    height: 40px;
    text-align: center;
    text-decoration: none !important;

    &.primary {
      border: none;
      color: ${electricPurple600};
      background-color: ${hintPerano100};

      span {
        color: ${electricPurple600};
        vertical-align: middle;
      }

      &:hover {
        color: ${white};
        background-color: ${electricPurple600};
        span {
          color: ${white};
          vertical-align: middle;
        }
      }
    }

    &.secondary {
      border: 2px solid ${grey};
      box-sizing: border-box;
      color: ${black};
      background-color: ${white};
      &:hover {
        background-color: ${grey};
      }
    }

    &.error {
      border: none;
      box-sizing: border-box;
      color: ${white};
      background-color: ${error};
    }

    &.disabled {
      border: none;
      box-sizing: border-box;
      color: ${white};
      background-color: ${grey};
      cursor: not-allowed;
    }

    &:hover {
      text-decoration: underline;
    }

    &:focus {
      outline: none;
    }
    
  }

  @media screen and (min-width: ${size.tablet}) {
    button {
      font-size: 18px;
      line-height: 21px;
    }
  }

  @media screen and (min-width: ${size.desktop}) {
    button {
      font-size: 16px;
    }
  }
`;

export default ButtonWrapper;
