import React from "react";
import { StandarizationCardsWrapper } from "./style";

const StandarizationTypeCards = (props) => {
  const { typeStandarization } = props;

  return (
    <StandarizationCardsWrapper>
      <div className="column-cards">
        {typeStandarization &&
          typeStandarization.map((type, idx) => (
            <div className="mini-card" key={`${idx}-${Math.random()}`}>
              <p className="mini-card-body count-body">{type}</p>
            </div>
          ))}
      </div>
    </StandarizationCardsWrapper>
  );
};

export default StandarizationTypeCards;
