import React from "react";
import { TypologyListWrapper } from "./style";

const TypologyList = ({ dataTypology, ...props }) => {
  return (
    <TypologyListWrapper>
      <h6 className="title">Tipología de inmuebles catastro m²</h6>
      <div className="column-cards">
        {dataTypology &&
          dataTypology.map((typology) => (
            <div className="mini-card">
              <p className="mini-card-body aconst-title">
                {typology.aconst} m²
              </p>
              <p className="mini-card-body count-body">{typology.count}</p>
            </div>
          ))}
      </div>
    </TypologyListWrapper>
  );
};

export default TypologyList;
