import React, { useState } from 'react';
import { SearchWrapper } from './style';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import { useEffect } from 'react';

const SearchFilter = (props) => {

  const {inputValue, setInputValue, placeholder, disabled} = props;
  const [isActive, setActive] = useState(false)

  const handleChange = (event) => {
    setInputValue(event.target.value);
  }

  const handleClean = () => {
    setInputValue("");
  }

  useEffect(() => {
    if (inputValue) {
      setActive(true);
    } else {
      setActive(false)
    }
  }, [inputValue])

  return (
    <SearchWrapper >
      <div
        className="search-container"
      >
        <SearchRoundedIcon className="icon" />
        <input
          value={inputValue}
          onChange={handleChange}
          type="number"
          placeholder={placeholder}
        />
        {isActive && !disabled && (
          <button
            onClick={handleClean}
            type="button"
            id="reset-button"
            className="reset"
          >
            <span>Borrar</span>
          </button>
        )}
      </div>
    </SearchWrapper>
  )
};

export default SearchFilter;