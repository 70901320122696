export const renderName = {
  area: "Área",
  num_habitaciones: "Habitaciones",
  banos: "Baños",
  num_piso: "Piso",
  garajes: "Garajes",
  num_ascensores: "Ascensores",
  anos_antiguedad: "Antiguedad",
  porcentaje_venta_conjunto: "% de Venta",
  ap_comite: "Número de aptos. conjunto",
  valor_admon: "Vr Admon.",
  prima_altura: "Prima de Altura",
};

export const renderNameB = {
  v_o_area_construida_confirmado: "Área",
  v_o_num_hab_confirmadas_confirmado: "Habitaciones",
  v_o_num_banos_confirmados_confirmado: "Baños",
  v_o_piso_confirmado: "Pisos",
  v_o_ascensor_confirmado: "Ascensores",
  v_o_garaje_confirmado: "Garajes",
  v_o_tipo_de_garaje: "Tipo de garaje",
};

export const typeInput = {
  area: "int",
  num_habitaciones: "int",
  banos: "int",
  num_piso: "int",
  garajes: "int",
  num_ascensores: "int",
  anos_antiguedad: "int",
  porcentaje_venta_conjunto: "float",
  ap_comite: "int",
  valor_admon: "currency",
  prima_altura: "select",
};

export const highOptions = {
  Si: "si",
  No: "no",
};

export const createDataToSave = (data) => ({
  nid: data.nid,
  inmueble_id: data.inmueble_id,
  lote_id: data.lote_id,
  historico_pricing_id: data.historico_pricing_id,
  area: data.area,
  num_habitaciones: data.num_habitaciones,
  banos: data.banos,
  num_piso: data.num_piso,
  garajes: data.garajes,
  num_ascensores: data.num_ascensores,
  anos_antiguedad: data.anos_antiguedad,
  ap_comite: data.ap_comite,
  valor_admon: data.valor_admon,
  porcentaje_venta_conjunto: data.porcentaje_venta_conjunto,
  prima_altura: data.prima_altura,
  agente_pricing_2: data.agente_pricing_2,
});
