import styled from "styled-components";
import { error } from "../../StylesConstants";

export const StarContainer = styled.div`
  display: flex;
  align-items: center;

  img {
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      transform: scale(1.2);
    }
  }
`;

export const ErrorMessage = styled.div`
  color: ${error};
  font-size: 12px;
  line-height: 12px;
  margin: 8px;
`;
