import styled from "styled-components";
import {
  bogotaBlue000,
  scarpaGray200,
  scarpaGray400,
  scarpaGray600,
} from "../../StylesConstants";
export const LogCommentWrapper = styled.section`
  .MuiTimeline-root  {
    padding-left: 0;
  }
  .MuiTimelineItem-content {
    display: grid;
    grid-template: 1fr / 150px 1fr;
    background-color: ${bogotaBlue000};
    .date-comment {
      display: grid;
      align-content: center;
      color: ${scarpaGray400};
      font-size: 13px;
      line-height: 16px;
    }
    .text-comment {
      display: grid;
      align-content: center;
      color: ${scarpaGray600};
      font-size: 13px;
      line-height: 16px;
    }
  }

  .MuiTimelineItem-missingOppositeContent:before {
    flex: 0;
  }
  .MuiTimelineSeparator-root {
    margin-left: -30px;
  }
  .MuiTimelineConnector-root {
    border-left: 1px dashed ${scarpaGray200};
    background-color: transparent;
  }
`;
