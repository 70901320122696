import styled from "styled-components";
import {
  archiaRegular,
  bogotaBlue100,
  brightTurquoise600,
  scarpaGray100,
  scarpaGray400,
  scarpaGray500,
  scarpaGray600,
} from "../../StylesConstants";

export const CurrentLeadTableWrapper = styled.div`
  .table-container {
    font-family: ${archiaRegular};
    padding: 24px 24px 0;

    .table {
      margin: 0;
    }

    thead {
      background-color: ${scarpaGray100};
      font-weight: bold;
      font-size: 12px;
      color: ${scarpaGray500};
      tr {
        height: 24px;
        th {
          vertical-align: middle;
          text-align: center;
          &.dateCreation {
            width: 170px;
          }
        }
      }
    }
    tbody {
      font-size: 12px;
      color: ${scarpaGray600};
      background-color: ${bogotaBlue100};
      border-bottom: 1px solid ${scarpaGray100};
      tr {
        height: 45px;
        td {
          font-weight: normal;
          vertical-align: middle;
          padding: 0 16px;
          text-align: center;
          .hour {
            color: ${scarpaGray400};
            padding-left: 8px;
          }
          .link {
            color: ${brightTurquoise600};
            font-weight: 600;
            font-size: 12px;
          }
        }
      }
    }
  }
  .modal-close {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }
  .table-buttons {
    display: flex;
  }
`;

export const FlagContainer = styled.div`
  display: inline;

  .activeFlag {
    color: red;
  }
  .inactiveFlag {
    color: transparent;
  }
  .inactiveFlag:hover {
    color: red;
  }
`;

export const TableContainer = styled.div`
  .title-tables {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  .table-content {
    margin: 1rem 0px;
    width: 100%;
    height: 300px;
  }
`;
