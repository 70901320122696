import React from "react";
import Loader from "@habi/habi-react-components/dist/Loader/loader";
import { DotsLoadingWrapper } from "./style";

const DotsLoading = (props) => {
  return (
    <DotsLoadingWrapper>
      <Loader />
    </DotsLoadingWrapper>
  );
};

export default DotsLoading;
