import styled from "styled-components";
import { createTheme } from "@material-ui/core";
import {
  archiaRegular,
  brightTurquoise000,
  brightTurquoise500,
  nightBlue000,
  scarpaGray100,
  scarpaGray300,
  scarpaGray500,
  tomatoCoral000,
  tomatoCoral500,
} from "../../StylesConstants";

export const StyledLargeInput = createTheme({
  overrides: {
    MuiFormHelperText: {
      root: {
        color: `${tomatoCoral500}`,
        fontFamily: `${archiaRegular}`,
        marginTop: "0",
        fontWeight: "600",
        marginBottom: "40px",
      },
    },
  },
});

export const StyledInput = styled.div`
  width: 100%;
  height: 56px;
  border-radius: 8px;
  border: ${(props) =>
    props.error
      ? `2px solid ${tomatoCoral500}`
      : props.interaction
      ? `2px solid ${brightTurquoise500}`
      : `1px solid ${scarpaGray100}`};
  background-color: ${(props) =>
    props.error
      ? tomatoCoral000
      : props.interaction
      ? brightTurquoise000
      : nightBlue000};
  &:hover {
    background-color: ${brightTurquoise000};
    border-radius: "8px";
    border: 2px solid ${brightTurquoise500};
  }
  .input-format-currency {
    height: 56px;
    width: 100%;
    color: ${scarpaGray500};
    font-family: ${archiaRegular};
    font-size: 15px;
    outline: none;
    border: none;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 16px;
    background-color: transparent;
    &::placeholder {
      color: ${scarpaGray300};
    }
  }
`;
