import { apartmentActionTypes } from "../actions/apartment.actions";

const INITIAL_STATE = {};

const apartmentReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case apartmentActionTypes.SET_APARTMENT:
      return payload;
    case apartmentActionTypes.REMOVE_APARTMENT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default apartmentReducer;
