export const normalizationOptions = {
  ninguno: "Ninguno",
  banos: "Baños",
  garajes: "Garajes",
  num_piso: "Piso",
  area: "Area",
  height_premium: "Prima Altura",
};

export const headManualTable = [
  {
    id: "area",
    numeric: true,
    disablePadding: true,
    label: "Área",
  },
  {
    id: "area_catastro",
    numeric: true,
    disablePadding: true,
    label: "Área Catastro",
  },
  { id: "garajes", numeric: true, disablePadding: true, label: "Garajes" },
  { id: "banos", numeric: true, disablePadding: true, label: "Baños" },
  { id: "num_piso", numeric: true, disablePadding: true, label: "Piso" },
  {
    id: "num_ascensores",
    numeric: true,
    disablePadding: true,
    label: "Ascensores",
  },
  {
    id: "anos_antiguedad",
    numeric: true,
    disablePadding: true,
    label: "Antiguedad",
  },
  {
    id: "fuente",
    numeric: false,
    disablePadding: false,
    label: "Fuente",
  },
  {
    id: "tipo_inmueble",
    numeric: false,
    disablePadding: true,
    label: "Tipo Inmueble",
  },
  {
    id: "last_ask_price",
    numeric: true,
    disablePadding: true,
    label: "Ask Price",
  },
  { id: "valormt2", numeric: true, disablePadding: true, label: "Valor m2" },
  {
    id: "estado",
    numeric: false,
    disablePadding: true,
    label: "Estado del negocio",
  },
  {
    id: "obra_gris",
    numeric: false,
    disablePadding: true,
    label: "Obra gris",
  },
  {
    id: "quality",
    numeric: false,
    disablePadding: true,
    label: "Calidad",
  },
  {
    id: "type_standardization",
    numeric: false,
    disablePadding: true,
    label: "Normalizar",
  },
  {
    id: "comparable",
    numeric: false,
    disablePadding: true,
    label: "Comparable",
  },
  {
    id: "remove",
    numeric: false,
    disablePadding: false,
    label: "Borrar",
  },
];

export const headManualTableDetail = [
  {
    id: "pid",
    numeric: true,
    disablePadding: true,
    label: "Pid",
  },
  {
    id: "nid",
    numeric: true,
    disablePadding: true,
    label: "Nid",
  },
  {
    id: "vetustez",
    numeric: true,
    disablePadding: true,
    label: "Vetustez",
  },
  {
    id: "fuente_id",
    numeric: true,
    disablePadding: true,
    label: "Fuente Id",
  },
  {
    id: "idconjunto",
    numeric: true,
    disablePadding: true,
    label: "conjunto Id",
  },  {
    id: "num_apartamento",
    numeric: false,
    disablePadding: true,
    label: "Num Apartamento",
  },
  {
    id: "direccion_homologada",
    numeric: false,
    disablePadding: true,
    label: "Direccion Homologada",
  },
  {
    id: "estrato_auto",
    numeric: false,
    disablePadding: true,
    label: "Estrato Auto",
  },
  {
    id: "estrato_catastro",
    numeric: false,
    disablePadding: true,
    label: "Estrato Catastro",
  },
  {
    id: "telefono",
    numeric: false,
    disablePadding: true,
    label: "Telefono",
  },
  {
    id: "nombre_o_inmobiliaria",
    numeric: false,
    disablePadding: true,
    label: "Nombre Inmobiliaria",
  },
  {
    id: "ciudad_id",
    numeric: false,
    disablePadding: true,
    label: "Ciudad Id",
  },
  {
    id: "fecha_creacion",
    numeric: false,
    disablePadding: true,
    label: "Fecha Creacion",
  },
  {
    id: "latitud",
    numeric: true,
    disablePadding: true,
    label: "Latitud",
  },
  {
    id: "longitud",
    numeric: true,
    disablePadding: true,
    label: "Longitud",
  },
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "Id",
  },
  {
    id: "tipo_consulta",
    numeric: true,
    disablePadding: true,
    label: "Tipo Consulta",
  },
  {
    id: "conjunto_edificio",
    numeric: false,
    disablePadding: true,
    label: "Conjunto Edificio",
  },
  {
    id: "estado_id",
    numeric: true,
    disablePadding: true,
    label: "estado Id",
  },
  {
    id: "tipo_inmueble_id",
    numeric: true,
    disablePadding: true,
    label: "Tipo Inmueble Id",
  },
];
