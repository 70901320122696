import { combineReducers } from "redux";

import userDataReducer from "./reducers/userData.reducer";
import selectedRowsReducer from "./reducers/selectedRows.reducer";
import apartmentReducer from "./reducers/apartment.reducer";
import pricingDataReducer from "./reducers/pricingData.reducer";
import warningsReducer from "./reducers/warnings.reducer";
import mapPolygonReducer from "./reducers/mapPolygon.reducer";
import manualRowDataReducer from "./reducers/manualRowData.reducer";
import propertiesCountReducer from "./reducers/propertiesCount.reducer";
import flagRowDataReducer from "./reducers/flagSelectionData.reducer";
import standarizationReducer from "./reducers/standarizationData.reducer";
import coefficientReducer from "./reducers/coefficientRows.reducer";

export const rootReducer = combineReducers({
  userData: userDataReducer,
  selectedRows: selectedRowsReducer,
  apartment: apartmentReducer,
  pricingData: pricingDataReducer,
  warnings: warningsReducer,
  mapPolygon: mapPolygonReducer,
  manualRowData: manualRowDataReducer,
  propertiesCount: propertiesCountReducer,
  flagRowData: flagRowDataReducer,
  standarization: standarizationReducer,
  coefficient: coefficientReducer,
});
