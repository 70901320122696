import { createSelector } from "reselect";

const selectState = (state) => state;

export const selectUserData = createSelector(
  [selectState],
  (state) => state.userData
);
export const selectUserEmail = createSelector(
  [selectUserData],
  (userData) => userData.email
);
