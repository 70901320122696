import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { useMutation } from "@apollo/client";
import { TaskWrapper } from "./style";
import Header from "../../components/Header/header";
import { makeStyles } from "@material-ui/core/styles";
import { saveTaskEngine } from "../../queries/queries";
import {
  Card,
  CardContent,
  TextField,
  Button,
  FormControl,
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { Toggle, ToggleGroup } from "../../components/Toggle/toogle";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 275,
    maxWidth: 800,
  },
}));

const EditTask = (props) => {
  const classes = useStyles();
  const row = props.location?.state.row;
  const [data, setData] = useState({});
  const [defaultFlag, setDefaultFlag] = useState(row.flag || 0);

  useEffect(() => {
    setData(row);
  }, [row]);

  const handleChange = (event, name) => {
    event.preventDefault();
    setData({ ...data, [name]: event.target.value });
  };

  const handleChangeNumber = (event, name) => {
    event.preventDefault();
    setData({ ...data, [name]: +event.target.value });
  };

  const [sTaskEngine] = useMutation(saveTaskEngine);

  const saveTask = async (data) => {
    sTaskEngine({
      variables: {
        id: data.id,
        name: data.name,
        task: data.task,
        value: data.value,
        priority: data.priority,
        flag: defaultFlag,
      },
    })
      .then(() => cancelTask())
      .catch((err) => {
        console.log(`Error in saveTask saveProcess: ${err.message}`);
      });
  };

  const cancelTask = async () => {
    props.history.push({
      pathname: "/dashboard",
      state: {
        type: "discount",
      },
    });
  };

  return (
    <TaskWrapper>
      <Header className="header" />
      <div>
        <div className="title">
          <h2>Motor de Reglas</h2>
        </div>
        <Card className="card">
          <CardContent className="cardContent">
            <FormControl variant="outlined" className={classes.formControl}>
              <TextField
                error={!data.name ? true : false}
                variant="outlined"
                id="idName"
                label="Nombre de la regla"
                required={true}
                size="medium"
                fullWidth
                autoComplete="off"
                defaultValue={row.name ? row.name : ""}
                className="text-file"
                name="name"
                onChange={(event) => handleChange(event, "name")}
              />
              <TextField
                error={!data.task ? true : false}
                variant="outlined"
                id="idTask"
                label="Condicion de la regla"
                required
                size="medium"
                fullWidth
                autoComplete="off"
                rows="3"
                multiline
                defaultValue={row.task ? row.task : ""}
                className="text-file"
                name="task"
                onChange={(event) => handleChange(event, "task")}
              />
              <TextField
                error={!data.value ? true : false}
                variant="outlined"
                id="idValue"
                label="Valor de la regla"
                required
                size="medium"
                fullWidth
                autoComplete="off"
                type="number"
                defaultValue={row.value ? row.value : ""}
                className="text-file"
                name="value"
                onChange={(event) => handleChangeNumber(event, "value")}
              />
              <TextField
                error={!data.priority ? true : false}
                variant="outlined"
                id="idPriority"
                label="Prioridad de la regla"
                required
                size="medium"
                fullWidth
                autoComplete="off"
                type="number"
                defaultValue={row.priority ? row.priority : ""}
                className="text-file"
                name="priority"
                onChange={(event) => handleChangeNumber(event, "priority")}
              />
              <div className="source-question">
                <span className="question">¿Es esta la regla por defecto?</span>
                <Formik
                  enableReinitialize
                  initialValues={{
                    defaultFlag: defaultFlag,
                  }}
                >
                  {({ values, errors, touched, setFieldValue }) => (
                    <Form>
                      <ToggleGroup
                        id="defaultFlag"
                        name="defaultFlag"
                        value={values.defaultFlag}
                        error={errors.defaultFlag}
                        touched={touched.defaultFlag}
                        setFieldValue={setFieldValue}
                        default={0}
                      >
                        <div className={`radio-container`}>
                          <Field
                            component={Toggle}
                            name="defaultFlag"
                            id={"si"}
                            label={"Sí"}
                            value={1}
                            default={0}
                            values={values}
                            error={errors.defaultFlag}
                            touched={touched.defaultFlag}
                            setFieldValue={setFieldValue}
                            setFlag={setDefaultFlag}
                          />
                        </div>
                        <div className={`radio-container`}>
                          <Field
                            component={Toggle}
                            name="defaultFlag"
                            id={"no"}
                            label={"No"}
                            value={0}
                            default={0}
                            values={values}
                            error={errors.defaultFlag}
                            touched={touched.defaultFlag}
                            setFieldValue={setFieldValue}
                            setFlag={setDefaultFlag}
                          />
                        </div>
                      </ToggleGroup>
                    </Form>
                  )}
                </Formik>
              </div>
              <div className="save-button">
                <label htmlFor="contained-button-file">
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    component="span"
                    onClick={() => {
                      saveTask(data);
                    }}
                  >
                    Guardar
                  </Button>
                </label>
                <label htmlFor="contained-button-file">
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    component="span"
                    onClick={() => {
                      cancelTask(data);
                    }}
                  >
                    Cancelar
                  </Button>
                </label>
              </div>
            </FormControl>
          </CardContent>
        </Card>
      </div>
    </TaskWrapper>
  );
};

export default withRouter(EditTask);
