import React from "react";
import { connect } from "react-redux";
import { useQuery } from "@apollo/client";
import { getSourcesByPolygon } from "../../../queries/queries";
import { selectPolygonAction } from "../../../redux/actions/mapPolygon.actions";
import { selectPolygonPoints } from "../../../redux/selectors/mapPolygon.selectors";
import { selectApartment } from "../../../redux/selectors/apartment.selectors";
import { createPolygonWKT } from "../../../utils/generalUtils";
import ComparablesSummary from "./ComparablesSummary.component";

const ComparablesSummaryContainer = (props) => {
  const { buttonRef, selectPolygon, polygon, apartment } = props;

  const { loading, error, data } = useQuery(
    getSourcesByPolygon({
      inmuebleId: apartment.inmuebleID,
      polygon: createPolygonWKT(polygon),
      area: apartment.area,
      year: apartment.antiguedad,
      garage: apartment.garajes,
      typePropertyId: [apartment.tipoInmueble],
      elevator: apartment.ascensores !== 0 ? 1 : 0,
    })
  );

  const sources = data?.getSourcesByPolygon ?? [];

  return (
    <ComparablesSummary
      loading={loading}
      error={error}
      sources={sources}
      buttonRef={buttonRef}
      selectPolygon={selectPolygon}
    />
  );
};

const mapStateToProps = (state) => ({
  polygon: selectPolygonPoints(state),
  apartment: selectApartment(state),
});

const mapDispatchToProps = (dispatch) => ({
  selectPolygon: () => dispatch(selectPolygonAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComparablesSummaryContainer);
