import { map, filter, pipe } from "../../utils/generalUtils";

export const extractUsableRowData = (row) => ({
  pid: row.pid || 0,
  nid: row.nid || 0,
  area: row.area,
  area_catastro: row.area_catastro,
  num_piso: row.num_piso,
  garajes: row.garajes,
  num_ascensores: row.num_ascensores,
  vetustez: row.vetustez,
  banos: row.banos,
  fuente_id: row.fuente_id,
  last_ask_price: row.last_ask_price,
  valormt2: row.valormt2,
  idconjunto: row.idconjunto,
  num_apartamento: row.num_apartamento,
  direccion_homologada: row.direccion_homologada,
  estrato_auto: row.estrato_auto,
  estrato_catastro: row.estrato_catastro,
  telefono: row.telefono,
  nombre_o_inmobiliaria: row.nombre_o_inmobiliaria,
  ciudad_id: row.ciudad_id,
  fecha_creacion: row.fecha_creacion,
  latitud: row.latitud,
  longitud: row.longitud,
  id: row.id,
  fuente: row.fuente,
  tipo_consulta: row.tipo_consulta,
  label_inmueble: row.label_inmueble,
  anos_antiguedad: row.anos_antiguedad,
  conjunto_edificio: row.conjunto_edificio,
  estado_id: row.estado_id,
  estado: row.estado,
  tipo_inmueble_id: row.tipo_inmueble_id,
  obra_gris: row.obra_gris,
  quality: row.quality || 0,
  type_standardization: row.type_standardization || [],
  delete: row.delete || false,
});

export const createManualRowData = (selectedRows, manualRowData) =>
  selectedRows.map((row) => ({
    ...extractUsableRowData(row),
    ...manualRowData[row.id],
  }));

export const addManyRows = ({ state, payload, flags = {} }) => {
  const rows = state;
  const selectedRowsIds = rows.map((row) => row.id);
  const addFlags = (row) => ({ ...row, ...flags });
  const cleanAndAddFlags = pipe(extractUsableRowData, addFlags);
  const byAlreadySelected = (row) => !selectedRowsIds.includes(row.id);
  const processRows = pipe(filter(byAlreadySelected), map(cleanAndAddFlags));
  const filteredAndCleanedRows = processRows(payload);
  if (filteredAndCleanedRows.length === 0) return state;
  return [...rows, ...filteredAndCleanedRows];
};
