import styled from "styled-components";
import {
  electricPurple600,
  scarpaGray100,
  scarpaGray000,
  archiaRegular,
  electricPurple800,
  scarpaGray400,
  scarpaGray600,
} from "../../StylesConstants";

export const SwitchButtonWrapper = styled.div`
  .container {
    display: grid;
    grid-template-columns: auto 80px;
    font-family: ${archiaRegular};
    font-size: 13px;
    text-align: center;
    font-weight: 600;
    width: fit-content;
    padding: 0px;

    .title {
      font-size: 15px;
      color: ${scarpaGray600};
      margin: 5px;
      display: flex;
      align-items: center;
    }

    .react-switch-checkbox {
      height: 0;
      width: 0;
      visibility: hidden;
    }

    .react-switch-label {
      width: 60px;
      height: 32px;
      background: ${(props) =>
        props.buttonOn ? `${electricPurple600}` : `${scarpaGray100}`};
      border-radius: 100px;
      position: relative;
      transition: background-color 0.2s;
      color: ${scarpaGray400};
      margin: 0.25rem 0 0 0;
    }

    .react-switch-label .react-switch-button {
      position: absolute;
      top: 3px;
      left: 3px;
      width: 26px;
      height: 26px;
      padding: 3.5px;
      border-radius: 45px;
      transition: 0.2s;
      background: ${scarpaGray000};
    }

    .react-switch-checkbox:checked + .react-switch-label .react-switch-button {
      left: calc(100% - 2px);
      transform: translateX(-100%);
      color: ${electricPurple800};
    }
  }
`;

SwitchButtonWrapper.defaultProps = { buttonOn: false };
