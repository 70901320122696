import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getSimulatorNid } from "../../queries/queries";
import SearchFilter from "../SearchFilter/searchFilter";
import SimulatorTable from "../SimulatorTable/SimulatorTable";
import { SimulatorHomeWrapper } from "./SimulatorHome.style";
import { setApartmentAction } from "../../redux/actions/apartment.actions";

const SimulatorHome = () => {
  const { userData } = useSelector((state) => state);
  const [inputValue, setInputValue] = useState(
    userData.nid ? userData.nid : ""
  );
  const history = useHistory();

  const dispatch = useDispatch();

  const { data } = useQuery(getSimulatorNid, {
    variables: { nid: +inputValue },
  });

  useEffect(() => {
    if (userData.nid && data) {
      if (data.getPricingSimulator.count > 0)
        dispatch(setApartmentAction(data.getPricingSimulator.result[0]));
      else history.push("/simulador");
    }
  }, [data, dispatch, history, userData.nid]);

  return (
    <SimulatorHomeWrapper>
      <h1 className="title">Simulador</h1>
      <div className="search">
        <SearchFilter inputValue={inputValue} setInputValue={setInputValue} placeholder="Buscar por NID" />
      </div>
      {inputValue && (
        <SimulatorTable dataTable={data?.getPricingSimulator.result} />
      )}
    </SimulatorHomeWrapper>
  );
};

export default SimulatorHome;
