import { createSelector } from "reselect";

const selectWarnings = (state) => state.warnings;

export const selectWarningMessages = createSelector(
  [selectWarnings],
  (warnings) => warnings.warningMessages
);

export const selectHasWarnings = createSelector(
  [selectWarningMessages],
  (warningMessages) => (warningMessages.length !== 0 ? true : false)
);

export const selectWarningRowIds = createSelector(
  [selectWarnings],
  (warnings) => warnings.rowIds
);

export const selectHasWarningRowIds = createSelector(
  [selectWarnings],
  (warnings) => (warnings.rowIds.length !== 0 ? true : false)
);
