import styled from "styled-components";
import {
  white,
  scarpaGray700,
  scarpaGray600,
  scarpaGray000,
  scarpaGray100,
  electricPurple500,
  electricPurple600,
  goldenYellow000,
  scarpaGray900,
  scarpaGray200,
} from "../../StylesConstants";
import Button from "../Button/button";
import ScrollbarWrapper from "@habi/habi-react-components/dist/ScrollbarWrapper/ScrollbarWrapper";

export const ModalContainer = styled.div`
  width: 948px;
  max-height: calc(100vh - 128px);
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 300;
  background-color: ${white};
  transform: translate(-50%, -50%);
  border-radius: 8px;
  padding: 56px 48px 40px 88px;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 300;
`;

export const CerrarX = styled.div`
  position: relative;
  padding: 12px;

  div {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -56px;
    right: -48px;
    width: 40px;
    height: 40px;
    background-color: ${scarpaGray100}4D;
    border-radius: 50%;
    cursor: pointer;
    color: ${scarpaGray900};
    font-size: 32px;
    margin: 16px;

    &:hover {
      background-color: ${scarpaGray200}4D;
    }
  }
`;

export const Title = styled.h1`
  font-family: "Muli Black";
  font-size: 20px;
  line-height: 28px;
  color: ${scarpaGray700};
  margin-bottom: 24px;
`;

export const WarningBanner = styled.div`
  width: calc(100% - 32px);
  background: ${goldenYellow000};
  border: 2px solid rgba(240, 151, 0, 0.5);
  border-radius: 8px;
  padding: 24px;
  margin-right: 32px;
  margin-bottom: 32px;
`;

export const WarningGrid = styled.div`
  display: grid;
  grid-template-columns: 48px auto;
  grid-template-areas: "sidebar main";

  .sidebar {
    grid-area: sidebar;
  }

  .main {
    grid-area: main;

    h2 {
      font-family: "Muli Black";
      font-size: 16px;
      line-height: 24px;
      color: ${scarpaGray700};
      margin-bottom: 4px;
    }

    p {
      font-family: "Open Sans";
      font-size: 13px;
      line-height: 24px;
      margin: unset;
    }
  }
`;

export const StyledScrollbarWrapper = styled(ScrollbarWrapper)`
  max-height: calc(100vh - 438px);
  overflow: auto;
  padding-right: 32px;
`;

export const WarningMessageList = styled.ul`
  margin: unset;
  padding: unset;
  list-style: none;

  li {
    display: flex;
    font-family: "Open Sans";
    font-size: 16px;
    line-height: 28px;
    color: ${scarpaGray600};
    margin-bottom: 16px;

    .arrows {
      font-family: "Open Sans";
      font-weight: bold;
      font-size: 16px;
      line-height: 28px;
      color: ${scarpaGray700};
      margin-right: 16px;
    }
  }
`;

export const ValidationQuestion = styled.h2`
  margin-bottom: 40px;
  font-family: "Muli Black";
  font-size: 16px;
  line-height: 24px;
  color: ${scarpaGray700};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledButton = styled(Button)`
  max-height: unset;
  height: 48px;
  font-family: "Open Sans SemiBold";
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  margin-left: 24px;

  button {
    height: 48px;
    width: fit-content;
    padding: 10px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
  }

  #continuar {
    color: ${scarpaGray600};
    background: ${scarpaGray000};
    border: 1px solid ${scarpaGray100};
    box-sizing: border-box;

    &:hover {
      background-color: ${scarpaGray100};
    }
  }

  #corregir {
    background-color: ${electricPurple500};
    color: ${white};

    &:hover {
      background-color: ${electricPurple600};
    }
  }
`;
