import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Layout } from "../styleApp/style";
import LogIn from "../pages/LogIn/login";
import AuthenticatedRoute from "../components/Auth/authenticatedRoute";
import Dashboard from "../pages/Dashboard/dashboard";
import PricingManual from "../pages/PricingManual/pricingManual";
import EditTask from "../pages/EditTaks/editTask";
import LeadInfo from "../components/LeadInfo/LeadInfo";
import Simulator from "../pages/Simulator/Simulator";
import EditBucket from "../pages/EditBucket/editBucket";

const Routes = ({ dataPricingTable, refetchData, ...propsRoute }) => {
  const [isAuthenticated, userHasAuthenticated] = useState(false);

  const setAuth = (value) => {
    userHasAuthenticated(value);
  };
  return (
    <Router>
      <Layout>
        <Switch>
          <Route exact path="/test">
            <LeadInfo />
          </Route>
          <Route exact path="/">
            <LogIn setAuth={setAuth} />
          </Route>
          <AuthenticatedRoute
            path="/dashboard"
            component={Dashboard}
            appProps={{ isAuthenticated, dataPricingTable, refetchData }}
          />
          <AuthenticatedRoute
            path="/pricingManual"
            component={PricingManual}
            appProps={{ isAuthenticated, dataPricingTable, refetchData }}
          />
          <AuthenticatedRoute
            path="/editTask"
            component={EditTask}
            appProps={{ isAuthenticated, refetchData }}
          />
          <AuthenticatedRoute
            path="/simulador"
            component={Simulator}
            appProps={{ isAuthenticated, refetchData }}
          />
          <AuthenticatedRoute
            path="/editBucket"
            component={EditBucket}
            appProps={{ isAuthenticated, refetchData }}
          />
        </Switch>
      </Layout>
    </Router>
  );
};

export default Routes;
