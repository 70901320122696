import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useMutation } from "@apollo/client";
import ApolloClient from "apollo-boost";
import { getDiscountFormAskPrice } from "../../queries/queries";
import { validatePricingSubmit } from "../../redux/utils/warnings.utlis";

import ResultFields from "./resultFields";

import {
  selectDerivedValues,
  selectPricingData,
  selectFinalDerivedValues,
  selectHasLoadedFile,
  selectPercentil,
} from "../../redux/selectors/pricingData.selectors";
import { selectHasWarnings } from "../../redux/selectors/warnings.selectors";
import { selectApartment } from "../../redux/selectors/apartment.selectors";
import {
  resetPricingDataAction,
  updatePricingDataAction,
} from "../../redux/actions/pricingData.actions";
import {
  selectHasExternalRows,
  selectRowsDataForMutations,
} from "../../redux/selectors/selectedRows.selectors";
import { removeApartmentAction } from "../../redux/actions/apartment.actions";
import { clearPolygonAction } from "../../redux/actions/mapPolygon.actions";
import { setWarningsAction } from "../../redux/actions/warnings.actions";
import {
  savePricingData,
  saveCommentData,
  savePricingDataFinal,
  saveHabiFlagData,
} from "../../queries/queries";
import { selectUserEmail } from "../../redux/selectors/userData.selectors";
import { clearManualRowDataAction } from "../../redux/actions/manualRowData.actions";
import { clearFlagRowDataAction } from "../../redux/actions/flagSelectionData.actions";
import { fetchDataAxios } from "../../utils/fetchRequest";
import { buildData, findPricingType } from "./resultFields.utils";

const url =
  process.env.REACT_APP_FORM_API_URL_V2 +
  process.env.REACT_APP_ENDPOINT_GRAPHQL_BASE_PATH +
  process.env.REACT_APP_GRAPHQL_ENDPOINT;
const rootUrlForms =
  process.env.REACT_APP_FORM_API_URL_V2 +
  process.env.REACT_APP_ENDPOINT_FORMS_API_BASE_PATH_CONTAINER;
const endpointPricingManual = process.env.REACT_APP_PRICING_MANUAL_URL_ENDPOINT;
const urlGetPricingManual = rootUrlForms + endpointPricingManual;
const apiKeyForms = process.env.REACT_APP_FORMS_API_KEY_CONTAINER;

const client = new ApolloClient({
  uri: url,
  // uri: "http://localhost:4000/graphql",
  headers: {
    "x-api-key": process.env.REACT_APP_GRAPHQL_API_KEY,
  },
});

const ResultFieldsContainer = (props) => {
  const {
    formValues,
    apartment,
    derivedValues,
    updatePricingData,
    finalDerivedValues,
    email,
    rowsDataForMutation,
    removeApartment,
    clearPolygon,
    resetPricingData,
    flagTab,
    setRefresh,
    hasLoadedFile,
    hasWarnings,
    setWarnings,
    clearManualRowData,
    starPricing,
    rowsFlag,
    clearFlagRowData,
    mapPolygon,
    typeTab,
    percentil,
    hasPrice,
    setHasPrice,
  } = props;

  const final = flagTab;

  const [saveHabiPrice] = useMutation(savePricingData);
  const [saveComment] = useMutation(saveCommentData);
  const [saveHabiHandbookPrice] = useMutation(savePricingDataFinal);
  const [saveHabiFlag] = useMutation(saveHabiFlagData);
  const [askPriceManual, setAskPriceManual] = useState(0);

  const calculatePrecioHabiFinal = async (event) => {
    event.preventDefault();
    if (!formValues.ask_price_final) return;
    const { data } = await client.query({
      query: getDiscountFormAskPrice,
      variables: {
        askPrice: parseInt(formValues.ask_price_final),
        inmuebleId: parseInt(apartment.inmuebleID),
        nid: parseInt(apartment.nid),
      },
    });
    const { pricingManual } = data.getDiscount;
    updatePricingData({ precio_habi_final: pricingManual });
  };

  const saveData = async () => {
    saveHabiPrice({
      variables: {
        askPrice: formValues.ask_price,
        precioHabi: formValues.precio_habi,
        inmuebleId: apartment.inmuebleID,
        nid: apartment.nid,
        prioridad: apartment.prioridad,
        fuente: apartment.fuente,
        fechaCreacion: apartment.fechaCreacion,
        comparable: JSON.stringify(buildData(rowsDataForMutation)) || "",
        originalData: JSON.stringify(apartment) || "",
        agent: email,
        rowsFlag: JSON.stringify(rowsFlag) || "",
        mapPolygon: JSON.stringify(mapPolygon.polygon) || "",
        typePricing: findPricingType(typeTab),
      },
    })
      .then(() => setRefresh(true))
      .catch((err) => {
        console.log(`Error in saveData saveProcess: ${err.message}`);
      });
    saveComment({
      variables: {
        inmuebleId: apartment.inmuebleID,
        commentary: formValues.comentario,
        url: "",
        agent: email,
        askPrice: formValues.ask_price,
        fechaCreacion: apartment.fechaCreacion,
        nid: apartment.nid,
        prioridad: apartment.prioridad,
        responseQuestion: 0,
        flag: 0,
        tipoInmuebleId: apartment.tipoInmueble,
        numPiso: apartment.piso,
        areaMetropolitanaId: apartment.areaMetropolitanaId,
        finalCommentary: 0,
      },
    }).catch((err) => {
      console.log(`Error in saveData saveComment: ${err.message}`);
    });
    removeApartment();
    clearPolygon();
    resetPricingData();
    clearManualRowData();
    clearFlagRowData();
  };
  const saveDataFinal = () => {
    saveHabiHandbookPrice({
      variables: {
        askPrice: formValues.ask_price_final,
        precioHabi: formValues.precio_habi_final,
        inmuebleId: apartment.inmuebleID,
        nid: apartment.nid,
        prioridad: apartment.prioridad,
        fuente: apartment.fuente,
        fechaCreacion: apartment.fechaCreacion,
        comparable: JSON.stringify(buildData(rowsDataForMutation)) || "",
        originalData: JSON.stringify(apartment) || "",
        agent: email,
        rowsFlag: JSON.stringify(rowsFlag) || "",
        mapPolygon: JSON.stringify(mapPolygon.polygon) || "",
        precio_zona: formValues.precio_zona || 0,
        nivel_confianza: formValues.nivel_confianza || 0,
        consistencia_conjunto: formValues.consistencia_conjunto || 0,
        typePricing: 8,
      },
    }).catch((err) => {
      console.log(
        `Error in saveDataFinal saveHabiHandbookPrice: ${err.message}`
      );
    });
    saveComment({
      variables: {
        inmuebleId: apartment.inmuebleID,
        commentary: formValues.comentario,
        url: "",
        agent: email,
        askPrice: formValues.ask_price_final,
        fechaCreacion: apartment.fechaCreacion,
        nid: apartment.nid,
        prioridad: apartment.prioridad,
        responseQuestion: 0,
        flag: 0,
        tipoInmuebleId: apartment.tipoInmueble,
        numPiso: apartment.piso,
        areaMetropolitanaId: apartment.areaMetropolitanaId,
        finalCommentary: 1,
      },
    }).catch((err) => {
      console.log(`Error in saveDataFinal saveComment: ${err.message}`);
    });
    removeApartment();
    clearPolygon();
    resetPricingData();
    clearManualRowData();
    clearFlagRowData();
  };

  const saveDataOutliers = () => {
    saveHabiFlag({
      variables: {
        rowsFlag: JSON.stringify(rowsFlag) || "",
        agent: email,
      },
    }).catch((err) => {
      console.log(`Error in saveDataOutliers saveHabiFlag: ${err.message}`);
    });
  };

  const getNewPricing = async (data) => {
    let responseDataPricingManual = 0;
    try {
      const responsePricingManual = fetchDataAxios(urlGetPricingManual, {
        method: "POST",
        headers: {
          "x-api-key": apiKeyForms,
          "Content-Type": "application/json",
        },
        data: data,
      });
      responseDataPricingManual = await responsePricingManual;
      return responseDataPricingManual;
    } catch (err) {
      console.log(`Error in getManualPricing: ${err.message}`);
    }
  };

  const calculateNewPrice = async (value) => {
    try {
      if (value.toString().length >= 7) {
        const dataToSend = {
          propertyId: apartment.inmuebleID,
          listProperty: buildData(rowsDataForMutation),
          type: "round_askprice",
          percentil: percentil,
          ask_price: value,
        };
        const responseDataPricing = await getNewPricing(dataToSend);
        setHasPrice(false);
        updatePricingData({
          ask_price: responseDataPricing.ask_price_habi,
          precio_habi: responseDataPricing.precio_manual,
          valor_m2: responseDataPricing.valormt2,
        });
      }
    } catch (error) {
      console.log(`Error in modifyAskPrice message: ${error.message}`);
    } finally {
      setHasPrice(true);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => calculateNewPrice(askPriceManual), 500);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line
  }, [askPriceManual]);

  const modifyAskPrice = (value) => {
    if (hasPrice) {
      updatePricingData({
        ask_price: value,
      });
      setAskPriceManual(value);
    }
    setHasPrice(true);
  };

  const isValidInicial = () =>
    formValues.ask_price && formValues?.comentario?.length > 4 ? true : false;

  const isValidFinal = () =>
    formValues.ask_price_final &&
    formValues.precio_habi_final &&
    hasLoadedFile &&
    formValues?.comentario?.length > 4
      ? true
      : false;

  const isValid = () => {
    if (final) return isValidFinal();
    return isValidInicial();
  };

  const pricingValidation = () => {
    const validationRes = validatePricingSubmit({
      formValues,
      apartment,
      selectedRows: rowsDataForMutation,
      starPricing,
    });
    if (validationRes.length > 0) {
      setWarnings(validationRes);
      return false;
    }
    return true;
  };

  const handleSubmit = ({ validate = true }) => {
    const validationPassed = validate ? pricingValidation() : true;
    if (!validationPassed) return;
    if (final) saveDataFinal();
    if (!final) saveData();
  };

  const isValidForPricingFinal = formValues.ask_price_final > 10000000;

  return (
    <ResultFields
      formValues={formValues}
      derivedValues={derivedValues}
      finalDerivedValues={finalDerivedValues}
      updatePricingData={updatePricingData}
      calculatePrecioHabiFinal={calculatePrecioHabiFinal}
      handleSubmit={handleSubmit}
      final={final}
      isValid={isValid()}
      isValidForPricingFinal={isValidForPricingFinal}
      hasWarnings={hasWarnings}
      handleSubmitOutliers={saveDataOutliers}
      handleChangeAskPrice={modifyAskPrice}
      hasManualPrice={hasPrice}
    />
  );
};

const mapStateToProps = (state) => ({
  formValues: selectPricingData(state),
  derivedValues: selectDerivedValues(state),
  finalDerivedValues: selectFinalDerivedValues(state),
  apartment: selectApartment(state),
  email: selectUserEmail(state),
  rowsDataForMutation: selectRowsDataForMutations(state),
  hasExternalRows: selectHasExternalRows(state),
  hasLoadedFile: selectHasLoadedFile(state),
  hasWarnings: selectHasWarnings(state),
  rowsFlag: state.flagRowData,
  mapPolygon: state.mapPolygon,
  percentil: selectPercentil(state),
});

const mapDispatchToProps = (dispatch) => ({
  updatePricingData: (kv) => dispatch(updatePricingDataAction(kv)),
  resetPricingData: () => dispatch(resetPricingDataAction()),
  removeApartment: () => dispatch(removeApartmentAction()),
  clearPolygon: () => dispatch(clearPolygonAction()),
  clearManualRowData: () => dispatch(clearManualRowDataAction()),
  setWarnings: (warningMessages) =>
    dispatch(setWarningsAction(warningMessages)),
  clearFlagRowData: () => dispatch(clearFlagRowDataAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResultFieldsContainer);
