import { createSelector } from "reselect";

const selectState = (state) => state;

export const selectApartment = createSelector(
  [selectState],
  (state) => state.apartment
);

export const selectHasApartment = createSelector(
  [selectApartment],
  (apartment) => (apartment.inmuebleID ? true : false)
);

export const selectInmuebleID = createSelector(
  [selectApartment],
  (apartment) => apartment.inmuebleID
);
