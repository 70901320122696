import styled from "styled-components";
import {
  archiaRegular,
  brightTurquoise000,
  brightTurquoise500,
  brightTurquoise600,
  electricPurple500,
  scarpaGray000,
  scarpaGray200,
  scarpaGray600,
  scarpaGray300,
} from "../../StylesConstants";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputBase,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

export const SqrtCheckboxWrapper = styled.div`
  .container-check {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 8px;
    justify-items: start;
    align-items: center;
    font-family: ${archiaRegular};
    padding: 0px;
    span {
      font-size: 15px;
      font-weight: 600;
      color: ${scarpaGray600};
    }

    .dropdow-container {
      display: flex;
      align-items: center;
      position: relative;
    }

    .dropdown-menu {
      border: none;
      box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05),
        0px 8px 16px rgba(48, 49, 51, 0.1);
      border-radius: 8px;
      max-height: 250px;
      overflow-y: auto;
      padding: 19px 0px;
      ::-webkit-scrollbar {
        display: none;
      }
      .subtitle {
        font-size: 11px;
        font-weight: 400;
      }
    }

    .check-box {
      padding: 8px 19px;
      color: ${scarpaGray600};
      font-size: 16px;
      text-decoration: none;
      text-align: start;

      &:hover {
        background-color: ${brightTurquoise000};
      }

      &:focus {
        background-color: ${brightTurquoise500};
      }
    }

    .switch-toggle {
      .container {
        padding: 0px;
        margin: 0 19px;
        .react-switch-label {
          width: 32px;
          height: 22px;
          .react-switch-button {
            font-size: 11px;
            width: 16px;
            height: 16px;
            padding: 0px;
          }
        }
      }
    }

    .form-check [type="checkbox"] {
      width: 18px;
      height: 18px;
      margin: 0px 10px 0px 0px;
      position: relative;
    }

    .dropdown-toggle::after {
      content: none;
    }
  }
`;

export const StyledCheckbox = withStyles((theme) => ({
  root: {
    color: `${scarpaGray300}`,
    "&$checked": {
      color: `${electricPurple500}`,
    },
    margin: "0",
    padding: "2px 8px",
  },
  checked: {},
}))(Checkbox);

export const StyledFormGroup = withStyles(() => ({
  root: {
    padding: "0 12px",
    rowGap: "4px",
  },
}))(FormGroup);

export const StyledFormControlLabel = withStyles(() => ({
  root: {
    marginBottom: "0",
  },
}))(FormControlLabel);

export const StyledInput = withStyles(() => ({
  root: {
    backgroundColor: `${scarpaGray000}`,
    border: `2px solid ${scarpaGray200}`,
    borderRadius: "8px",
    padding: "0 8px",
    fontSize: "11px",
    color: `${scarpaGray600}`,
    width: "100px",
    height: "32px",
    textAlign: "start",

    "&:hover": {
      backgroundColor: `${brightTurquoise000}`,
      border: `2px solid ${brightTurquoise600}`,
    },

    "&:focus": {
      backgroundColor: `${brightTurquoise000}`,
      boxShadow: "none",
      border: `2px solid ${brightTurquoise600}`,
    },
  },
  input: {
    underline: {
      "&:before": {
        content: "none",
      },
      "&:after": {
        content: "none",
      },
    },
  },
}))(InputBase);

export const StyledInputAll = withStyles(() => ({
  root: {
    backgroundColor: "transparent",
    border: "none",
    borderRadius: "100px",
    padding: "8px 16px",
    fontSize: "16px",
    color: `${scarpaGray600}`,
    minWidth: "104px",
    height: "40px",
    textDecoration: "underline",
    textAlign: "center",

    "&:hover": {
      backgroundColor: `${brightTurquoise000}`,
      border: `2px solid ${brightTurquoise600}`,
    },

    "&:focus": {
      backgroundColor: `${brightTurquoise000}`,
      boxShadow: "none",
      border: `2px solid ${brightTurquoise600}`,
    },
  },
  input: {
    underline: {
      "&:before": {
        content: "none",
      },
      "&:after": {
        content: "none",
      },
    },
  },
  MuiSelect: {
    select: { paddingRight: "16px" },
  },
}))(InputBase);
