import { coefficientActionTypes } from "../actions/coeffientRows.actions";

const INITIAL_STATE = false;

const coefficientReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case coefficientActionTypes.SET_COEFFICIENT:
      return payload;
    case coefficientActionTypes.REMOVE_COEFFICIENT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default coefficientReducer;
