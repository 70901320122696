export const buildData = (data) => {
  const dataToSend = {};
  for (let idx = 0; idx < data.length; idx++) {
    for (let [key, val] of Object.entries(data[idx])) {
      dataToSend[key] = { ...dataToSend[key] };
      dataToSend[key][`${idx}`] = val;
    }
  }
  return dataToSend;
};

export const findPricingType = (type) => {
  if (type === "pricing") return 4;
  if (type === "repricing") return 5;
  if (type === "reactivado") return 6;
  if (type === "gestionsinpricing") return 7;
  return null;
};
