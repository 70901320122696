import React, { useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import { FormHelperText, ThemeProvider } from "@material-ui/core";
import { StyledInput, StyledLargeInput } from "./CurrencyInput.style";

const Currency = ({
  name,
  error,
  id,
  defaultValue,
  setFieldValue,
  ...props
}) => {
  const [valueCurrency, setValueCurrency] = useState("");
  const [interaction, setInteraction] = useState(false);
  useEffect(() => {
    if (defaultValue) {
      setValueCurrency(defaultValue);
    }
  }, [defaultValue]);

  const handleBlur = () => {
    setInteraction(false);
  };

  return (
    <StyledInput
      error={error ? true : false}
      interaction={interaction}
      onClick={() => setInteraction(!interaction)}
      onBlur={handleBlur}
    >
      <ThemeProvider theme={StyledLargeInput}>
        <NumberFormat
          className="input-format-currency"
          defaultValue={defaultValue}
          id={`input-format-currency-${name}`}
          maxLength={13}
          name={name}
          placeholder="$ 0,000,000"
          prefix={"$ "}
          onValueChange={(values) => {
            setValueCurrency(values.floatValue);
            setFieldValue(name, values.floatValue);
          }}
          thousandSeparator
          value={valueCurrency}
        />
        {error && (
          <FormHelperText className="input-feedback">
            *campo requerido
          </FormHelperText>
        )}
      </ThemeProvider>
    </StyledInput>
  );
};
export default Currency;
