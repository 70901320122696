import styled from "styled-components";
import { scarpaGray200, electricPurple700 } from "../../StylesConstants";

export const CalculateAmountsWrapper = styled.section`
  display: grid;
  grid-template: 1fr / 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
  button {
    width: 250px;
    height: 56px;
  }
  button.primary {
    border: 1px solid ${scarpaGray200};
    color: ${electricPurple700};
    background-color: #ffffff;
    font-size: 13px;
  }
`;
