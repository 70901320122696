import React, { 
  useEffect, 
  useState 
}                               from "react";
import { 
  connect,
  useSelector 
}                               from "react-redux";
import RoomRoundedIcon          from "@material-ui/icons/RoomRounded";
import { selectApartment }      from "../../redux/selectors/apartment.selectors";
import FlagRoundedIcon          from "@material-ui/icons/FlagRounded";
import { addFlagRowDataAction } from "../../redux/actions/flagSelectionData.actions";
import Button                   from '../ButtonV2/Button';
import Modal                    from '@mui/material/Modal';
import Box                      from '@mui/material/Box';
import StandarizationContainer  from '../Standarization/StandarizationContainer';
import CloseIcon                from "@material-ui/icons/Close";
import axios                    from 'axios';
import { DataGrid }             from '@mui/x-data-grid';
import { 
  useMutation, 
  useQuery,
}                               from "@apollo/client";
import { 
  CurrentLeadTableWrapper, 
  FlagContainer, 
  TableContainer 
}                               from "./style";
import {
  saveHabiFlagData,
  getListStandardization
}                               from "../../queries/queries";

const CurrentLeadTable = ({ apartment, addFlagRow, rowsFlag, type }) => {

  const [saveHabiFlag]                      = useMutation(saveHabiFlagData);
  const { userData }                        = useSelector((state) => state); 
  const [isFlag, setIsFlag]                 = useState(false);
  const [showModal, setShowModal]           = useState(false);
  const [colabData, setColabData]           = useState(null);
  const [isLoading, setIsLoading]           = useState(false);
  const [dataTable, setDataTable]           = useState([]);
  const {standarization}                    = useSelector((state)=> state);
  const [showModalColab, setShowModalColab] = useState(false);
  
  const headers = [
    "NID",
    "Ciudad",
    "Zona Mediana",
    "Área",
    "Área catastro",
    "Garaje",
    "Baños",
    "Piso",
    "Ascensor",
    "Antigüedad",
    "Dirección",
    "Tipo Inmueble",
    "Ask price",
    "Obra Gris",
    "Piscina",
    "Cuarto útil",
    "Tipo VIS",
  ];

  const url = `https://maps.google.com/?q=${apartment.latitud},${apartment.longitud}`;
  

  useEffect(() => {
    if (apartment.area_flag) {
      let tempObj = {
        area_flag: apartment.area,
        varareaFlag: apartment.area_flag,
      };
      addFlagRow({ rowId: apartment.inmuebleID, data: tempObj });
    }
    if (apartment.garaje_flag) {
      let tempObj = {
        garaje_flag: apartment.garajes,
        vargarajeFlag: apartment.garaje_flag,
      };
      addFlagRow({ rowId: apartment.inmuebleID, data: tempObj });
    }
    if (apartment.bano_flag) {
      let tempObj = {
        bano_flag: apartment.banos,
        varbanoFlag: apartment.bano_flag,
      };
      addFlagRow({ rowId: apartment.inmuebleID, data: tempObj });
    }
    if (apartment.piso_flag) {
      let tempObj = {
        piso_flag: apartment.piso,
        varpisoFlag: apartment.piso_flag,
      };
      addFlagRow({ rowId: apartment.inmuebleID, data: tempObj });
    }
    if (apartment.ascensor_flag) {
      let tempObj = {
        ascensor_flag: apartment.ascensores,
        varascensorFlag: apartment.ascensor_flag,
      };
      addFlagRow({ rowId: apartment.inmuebleID, data: tempObj });
    }
    if (apartment.antiguedad_flag) {
      let tempObj = {
        antiguedad_flag: apartment.antiguedad,
        varantiguedadFlag: apartment.antiguedad_flag,
      };
      addFlagRow({ rowId: apartment.inmuebleID, data: tempObj });
    }
    if (apartment.conjunto_edificio_flag) {
      let tempObj = {
        conjunto_edificio_flag: apartment.conjunto_edificio,
        varconjuntoEdificioFlag: apartment.conjunto_edificio_flag,
      };
      addFlagRow({ rowId: apartment.inmuebleID, data: tempObj });
    }
    if (apartment.tipo_inmueble_id_flag) {
      let tempObj = {
        tipo_inmueble_id_flag: apartment.tipo_inmueble_id,
        vartipoInmuebleIdFlag: apartment.tipo_inmueble_id_flag,
      };
      addFlagRow({ rowId: apartment.inmuebleID, data: tempObj });
    }
    if (apartment.valorm2_flag) {
      let tempObj = {
        valorm2_flag: apartment.valormt2,
        varvalorM2Flag: apartment.valorm2_flag,
      };
      addFlagRow({ rowId: apartment.inmuebleID, data: tempObj });
    }
  }, [apartment, addFlagRow]);

  const saveDataOutliers = (objectFlag,id) => {

    let objectData        = {};
    let firstKey          = id;
    let firstKeyValue     = objectFlag;
    objectData[firstKey]  = firstKeyValue;

    setDataTable(data?.getListStandardization?.result);
    saveHabiFlag({
      variables: {
        rowsFlag: JSON.stringify(objectData) || "",
        agent: userData.email,
      },
    }).catch((err) => {
      console.log(`Error in saveDataOutliers saveHabiFlag: ${err.message}`);
    });
  };


  const getFlagProperty = (id, name, value, varname) => {
    let tempObj = {};
    
    if (rowsFlag?.[id]?.[varname] === 1) {
      tempObj = {
        [name]: null,
        [varname]: 2,
      };
    } else {
      tempObj = {
        [name]: value,
        [varname]: 1,
      };
    }

    let objectFlag = { 
      rowId: id, 
      data: tempObj 
    }

    addFlagRow(objectFlag);
    saveDataOutliers(tempObj, id)
  };

  useEffect(() => {
    let flags = rowsFlag[apartment.inmuebleID];
    if (
      flags && (
        (flags?.["varareaFlag"] === 1) ||
        (flags?.["varantiguedadFlag"] === 1) ||
        (flags?.["varascensorFlag"] === 1) ||
        (flags?.["varbanoFlag"] === 1) ||
        (flags?.["vargarajeFlag"] === 1) ||
        (flags?.["varpisoFlag"] === 1)
      )
    ) {
      setIsFlag(true);
    } else {
      setIsFlag(false);
    }
  }, [rowsFlag, apartment]);

  
  const { loading, data, refetch } = useQuery(
    getListStandardization({
      init: 1,
      perPage: 10,
      nid: apartment.nid,
    })
  );

  useEffect( ( ) => {
    if(showModal){
      refetch();
    }
    setDataTable(data?.getListStandardization?.result);
  },[showModal]);


  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    bgcolor: 'white',
    borderRadius: '8px',
    boxShadow: 12,
    p: 2,
    color: 'black',
    height: '660px',
  };

  const modalStyleColab = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "95%",
    bgcolor: 'white',
    borderRadius: '8px',
    boxShadow: 12,
    p: 2,
    color: 'black',
    height: '660px',
  };

  const modalClose = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    cursor: 'pointer',
  };

  const tableWrapper = {
    width: '100%',
    height: '90%',
    overflow: 'auto',
    margin: '2rem 0 1rem 0',
  };

  const getInformationTypology = () => {
    setIsLoading(true);
    let url = `https://approvaltooling-g7m25ztakq-uk.a.run.app/get_tipologia?nid=8187201331`;
    axios.get(url, {headers: {
      "api-key": 'OhUmdYx5Vp8IugnOyDWRI83mfy1KKmse5IcqswmH',
    }}).then(response => {
      let tables = response.data.data.info_fuentes;
      let tablesMap = [];
      for (const property in tables) {
        let obj = {
          name: property,
          value: tables[property],
        }
        tablesMap.push(obj);
      }
      setColabData(tablesMap);
      setShowModalColab(true);
      setIsLoading(false);
    });
  }

  const RenderTableColab = ({ data }) => {
    const table = data.value;
    var rowsTable = [];
    var columnsTable = [];

    if (table.hasOwnProperty("tabla")) {
      table.tabla.columns.map((column, index) => {
        columnsTable.push({
          field: column,
          headerName: column.toUpperCase(),
          flex: 1,
        });
      });

      table.tabla.data.map((row, i) => {
        let tmpRow = {id: i};
        row.map((item, index) => {
          tmpRow[table.tabla.columns[index]] = item;
        });
        rowsTable.push(tmpRow);
      });
    }

    return (
      <TableContainer>
        {table.hasOwnProperty("tabla") &&
          <>
            <div className="title-tables">{table.label}</div>
            <div className="table-content">
              <DataGrid
                rows={rowsTable}
                columns={columnsTable}
                pageSize={5}
                rowsPerPageOptions={[5]}
                density="compact"
                sx={{
                  border: 'none',
                  '& .MuiDataGrid-iconSeparator': {
                    display: 'none',
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    background: '#CCCAFD',
                    fontSize: '11px',
                    fontFamily: 'Open Sans Bold',
                  },
                  '& .MuiDataGrid-row:nth-child(even)': {
                    background: '#F6FCFF',
                  },
                  '& .MuiDataGrid-row': {
                    fontSize: '11px',
                    fontFamily: 'Open Sans Bold',
                  },
                  '& .MuiDataGrid-cell': {
                    borderBottom: 'none',
                  },
                  '& .MuiDataGrid-cell: first-child': {
                    color: '#6301CC',
                  }
                }}
              />
            </div>
          </>
        }
      </TableContainer>
    );
  }
  
 

  return (
    <CurrentLeadTableWrapper>
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              {headers.map((item, index) => {
                if (item === "Fecha de Creación") {
                  return (
                    <th className="dateCreation" key={index}>
                      {item}
                    </th>
                  );
                }
                return <th key={index}>{item}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            <tr key={apartment.inmuebleID}>
              <td>{apartment.nid}</td>
              <td>{apartment.labelCity}</td>
              <td>{apartment.labelZonaMediana}</td>
              <td>
                {standarization?.area_normalizado ? standarization?.area_normalizado :  apartment.area }m<sup>2</sup>
                <FlagContainer
                  onClick={() =>
                    getFlagProperty(
                      apartment.inmuebleID,
                      "area_flag",
                      apartment.area,
                      "varareaFlag"
                    )
                  }
                >
                  <FlagRoundedIcon
                    className={`${
                      rowsFlag?.[apartment.inmuebleID]?.[
                        "varareaFlag"
                      ] === 1
                        ? "activeFlag"
                        : "inactiveFlag"
                    }`}
                    fontSize="small"
                  />
                </FlagContainer>
              </td>
              <td>
                {apartment.area_catastro ? (
                  <>
                    {apartment.area_catastro} m<sup>2</sup>
                  </>
                ) : (
                  "N/A"
                )}
              </td>
              <td>
                {standarization?.garaje_normalizado ? standarization?.garaje_normalizado :  apartment.garajes }
                <FlagContainer
                  onClick={() =>
                    getFlagProperty(
                      apartment.inmuebleID,
                      "garaje_flag",
                      apartment.garajes,
                      "vargarajeFlag"
                    )
                  }
                >
                  <FlagRoundedIcon
                    className={`${
                      rowsFlag?.[apartment.inmuebleID]?.["vargarajeFlag"] === 1
                        ? "activeFlag"
                        : "inactiveFlag"
                    }`}
                    fontSize="small"
                  />
                </FlagContainer>
              </td>
              <td>
              {standarization?.bano_normalizado ? standarization?.bano_normalizado :  apartment.banos }
                <FlagContainer
                  onClick={() =>
                    getFlagProperty(
                      apartment.inmuebleID,
                      "bano_flag",
                      apartment.banos,
                      "varbanoFlag"
                    )
                  }
                >
                  <FlagRoundedIcon
                    className={`${
                      rowsFlag?.[apartment.inmuebleID]?.["varbanoFlag"] === 1
                        ? "activeFlag"
                        : "inactiveFlag"
                    }`}
                    fontSize="small"
                  />
                </FlagContainer>
              </td>
              <td>
                {standarization?.piso_normalizado ? standarization?.piso_normalizado :  apartment.piso }
                <FlagContainer
                  onClick={() =>
                    getFlagProperty(
                      apartment.inmuebleID,
                      "piso_flag",
                      apartment.piso,
                      "varpisoFlag"
                    )
                  }
                >
                  <FlagRoundedIcon
                    className={`${
                      rowsFlag?.[apartment.inmuebleID]?.["varpisoFlag"] === 1
                        ? "activeFlag"
                        : "inactiveFlag"
                    }`}
                    fontSize="small"
                  />
                </FlagContainer>
              </td>
              <td>
                {standarization?.ascensor_normalizado ? standarization?.ascensor_normalizado :  apartment.ascensores }
                <FlagContainer
                  onClick={() =>
                    getFlagProperty(
                      apartment.inmuebleID,
                      "ascensor_flag",
                      apartment.ascensores,
                      "varascensorFlag"
                    )
                  }
                >
                  <FlagRoundedIcon
                    className={`${
                      rowsFlag?.[apartment.inmuebleID]?.["varascensorFlag"] ===
                      1
                        ? "activeFlag"
                        : "inactiveFlag"
                    }`}
                    fontSize="small"
                  />
                </FlagContainer>
              </td>
              <td>
                {standarization?.antiguedad_normalizado ? standarization?.antiguedad_normalizado :  apartment.antiguedad }
                <FlagContainer
                  onClick={() =>
                    getFlagProperty(
                      apartment.inmuebleID,
                      "antiguedad_flag",
                      apartment.antiguedad,
                      "varantiguedadFlag"
                    )
                  }
                >
                  <FlagRoundedIcon
                    className={`${
                      rowsFlag?.[apartment.inmuebleID]?.[
                        "varantiguedadFlag"
                      ] === 1
                        ? "activeFlag"
                        : "inactiveFlag"
                    }`}
                    fontSize="small"
                  />
                </FlagContainer>
              </td>
              <td>
                <a target="_blank" rel="noopener noreferrer" href={url} className="link">
                  <RoomRoundedIcon />
                  {apartment.direccion}
                </a>
              </td>
              <td>
                {apartment.labelInmueble}
                <FlagContainer
                  onClick={() =>
                    getFlagProperty(
                      apartment.inmuebleID,
                      "tipo_inmueble_id_flag",
                      apartment.tipo_inmueble_id,
                      "vartipoInmuebleIdFlag"
                    )
                  }
                >
                  <FlagRoundedIcon
                    className={`${
                      rowsFlag?.[apartment.inmuebleID]?.[
                        "vartipoInmuebleIdFlag"
                      ] === 1
                        ? "activeFlag"
                        : "inactiveFlag"
                    }`}
                    fontSize="small"
                  />
                </FlagContainer>
              </td>
              <td>
                {`$ ${new Intl.NumberFormat().format(
                  apartment.lastAskPrice ?? 0
                )}`}
              </td>
              <td>
                {apartment.obra_gris === 1
                  ? "Si"
                  : apartment.obra_gris === 0
                  ? "No"
                  : "N/A"}
              </td>
              <td>
                {apartment.piscina === 1
                  ? "Si"
                  : apartment.piscina === 0
                  ? "No"
                  : "N/A"}
              </td>
              <td>
                {apartment.deposito === 1
                  ? "Si"
                  : apartment.deposito === 0
                  ? "No"
                  : "N/A"}
              </td>
              <td>{apartment.vis_type}</td>
            </tr>
          </tbody>
        </table>
        <div className="table-buttons">
          {type !== "endpricing" &&
            <Button
              style={{margin: '0.5rem'}}
              label="Editar información"
              onClick={() => {setShowModal(true)} }
              buttonType="secondary"
              disabled={!isFlag}
            />
          }
          <Button
            style={{margin: '0.5rem'}}
            label="Información tipología de conjunto"
            onClick={getInformationTypology}
            buttonType="secondary"
            disabled={isLoading}
          />
        </div>
      </div>

      {/* Editar información */}
      <Modal
        open={showModal}
        onClose={() => {setShowModal(false)}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <div style={modalClose} onClick={() => {setShowModal(false)}}>
            <CloseIcon />
          </div>
          <StandarizationContainer nid={apartment.nid} external={true} />
        </Box>
      </Modal>

      {/* Colab */}
      <Modal
        open={showModalColab}
        onClose={() => {setShowModalColab(false)}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyleColab}>
          <div style={modalClose} onClick={() => {setShowModalColab(false)}}>
            <CloseIcon />
          </div>
          <div style={tableWrapper}>
            { colabData && colabData.map((item, index) => {
              return <RenderTableColab data={item} key={index} />;
            })}
          </div>
        </Box>
      </Modal>
    </CurrentLeadTableWrapper>
  );
};

const mapStateToProps = (state) => ({
  apartment: selectApartment(state),
  rowsFlag: state.flagRowData,
});

const mapDispatchToProps = (dispatch) => ({
  addFlagRow: (fieldData) => dispatch(addFlagRowDataAction(fieldData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CurrentLeadTable);
