import React from "react";
import { PricingTableManualWrapper, AskPriceTh } from "./style";
import { Formik, Form } from "formik";
import CurrencyInput from "../CurrencyInput/currencyInput";

const TablePricingManual = (props) => {
  const { pricingData, updatePricingData, apartment } = props;

  const handleChange = ({ floatValue }) => {
    if (!floatValue) return;
    updatePricingData({ ask_price: floatValue * apartment.area });
  };

  const formatNumber = (number) =>
    number && new Intl.NumberFormat().format(Math.round(number));

  return (
    <>
      <PricingTableManualWrapper>
        <div className="table-container">
          <table className="table">
            <thead>
              <tr>
                {props.headers.map((item) => {
                  if (item === "Fecha de Creación") {
                    return <th className="dateCreation">{item}</th>;
                  }
                  if (
                    item === "Last ask price por m" ||
                    item === "Pricing por m"
                  ) {
                    return (
                      <th>
                        {item}
                        <sup>2</sup>
                      </th>
                    );
                  }
                  return <th>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              <tr key={apartment.inmuebleID}>
                <th>{Number(apartment.nid)}</th>
                <th>{apartment.proyecto}</th>
                <th>{apartment.fuente}</th>
                <th>{apartment.direccion}</th>
                <th>
                  {`${apartment.area} m`}
                  <sup>2</sup>
                </th>
                <th>
                  {apartment.lastAskPrice !== null
                    ? "$ " + formatNumber(apartment.lastAskPrice)
                    : ""}
                </th>
                <th>
                  {apartment.lastAskPrice !== null
                    ? "$ " +
                      formatNumber(apartment.lastAskPrice / apartment.area)
                    : ""}
                </th>
                <th>
                  <Formik
                    enableReinitialize
                    onSubmit={(values) => props.saveData(values)}
                  >
                    {(propsForm) => (
                      <Form id="formFilter">
                        <CurrencyInput
                          name="ask_price"
                          errors={propsForm.errors}
                          touched={propsForm.touched}
                          id="ask_price"
                          className="input-price"
                          value={Math.round(
                            pricingData.ask_price / apartment.area
                          )}
                          onValueChange={handleChange}
                          maxLength={11}
                        />
                      </Form>
                    )}
                  </Formik>
                </th>
                <AskPriceTh>{`$${
                  formatNumber(pricingData.ask_price) || 0
                }`}</AskPriceTh>
              </tr>
            </tbody>
          </table>
        </div>
      </PricingTableManualWrapper>
    </>
  );
};

export default TablePricingManual;
