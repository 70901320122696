import React, { useEffect, useState } from "react";
import {
  InputWrapper,
  ToggleWrapper,
  Note,
  TitleForm,
  ZoneToggle,
} from "./style";

const InputFeedback = ({ error }) =>
  error ? <div className="error">{error}</div> : null;

export const Toggle = ({
  field: { name, value, onChange, onBlur },
  id,
  label,
  ...props
}) => {
  const [selected, setSelected] = useState(props.default);

  const propsValuesName = props.values[name];

  useEffect(() => {
    if (propsValuesName !== undefined) {
      setSelected(propsValuesName);
      if (
        propsValuesName !== null &&
        propsValuesName.toString() === props.value.toString()
      ) {
        setSelected(true);
      } else {
        setSelected(false);
      }
    }
  }, [propsValuesName, name, props.value, props.values]);

  const handleClick = (setFieldVal, name, val) => {
    setFieldVal(name, val);
    props.setFlag(val);
  };

  return (
    <InputWrapper
      className={`${props.error && props.touched ? "error-radio" : ""} `}
    >
      <input
        name={name}
        id={`${id}${name}`}
        type="radio"
        value={props.value}
        checked={selected}
        onChange={() => handleClick(props.setFieldValue, name, props.value)}
        onBlur={onBlur}
        {...props}
      />
      <label className="radio-name" htmlFor={`${id}${name}`}>
        {label}
      </label>
    </InputWrapper>
  );
};
// Radio group
export const ToggleGroup = ({
  value,
  error,
  touched,
  id,
  label,
  description,
  children,
  ...props
}) => {
  useEffect(() => {
    if (value === undefined) {
      props.setFieldValue(props.default);
    }
  }, [props, value]);

  return (
    <ToggleWrapper>
      <fieldset id={id}>
        <TitleForm>{label}</TitleForm>
        {description && <Note>{description}</Note>}
        <ZoneToggle>
          <div className="switch-toggle">{children}</div>
        </ZoneToggle>
        {touched && <InputFeedback error={error} />}
      </fieldset>
    </ToggleWrapper>
  );
};
