import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useQuery } from "@apollo/client";
import { getCompareExternalSources } from "../../queries/queries";
import SelectedTable from "../SelectedTable/selectedTable";
import SelectedTableToolBar from "../SelectedTable/selectedTableToolBar";
import { selectApartment } from "../../redux/selectors/apartment.selectors";
import {
  addExternalRowAction,
  addManyExternalRowAction,
  removeManyRowsAction,
  removeRowAction,
  removeAllExternalRowsAction,
} from "../../redux/actions/selectedRows.actions";
import {
  elevatorGarageOptions,
  optionsArea,
  optionsDateLead,
  optionsProperty,
  optionsYears
} from "../../utils/optionsUtilities";
import { selectHasExternalRows } from "../../redux/selectors/selectedRows.selectors";
import { selectPricingData } from "../../redux/selectors/pricingData.selectors";
import { validateOnSelection } from "../../redux/utils/warnings.utlis";
import { setWarningsAndIdsAction } from "../../redux/actions/warnings.actions";

const ExternalSourcesContainer = (props) => {
  const [compareByExternalSource, setCompareByExternalSource] = useState([]);
  const {
    addExternalRow,
    removeRow,
    apartment,
    addManyExternalRows,
    removeManyRows,
    sourceOptions,
    hasExternalRows,
    removeAllExtrenalRows,
    formValues,
    setWarningsAndIds,
  } = props;

  const { longitud, latitud, area, antiguedad } = apartment;

  const [radioZona, setRadioZona] = useState(440);

  const [optionArea, setOptionArea] = useState("+/- 10 m²");
  const [filterArea, setFilterArea] = useState(10);

  const [garageName, setGarageName] = useState("Todos");
  const [garageIds, setGarageIds] = useState(0);

  const [elevatorName, setElevatorName] = useState("Todos");
  const [elevatorIds, setElevatorIds] = useState(0);

  const [sourceName, setSourceName] = useState([
    "CC",
    "FR",
    "M2",
    "PP",
    "haus",
  ]);

  const [sourceIds, setSourceIds] = useState([8, 9, 13, 14, 34]);

  const [dateLeadName, setDateLeadName] = useState("Menos de 6 meses");
  const [filterDate, setFilterDate] = useState(6);

  const [propertyName, setPropertyName] = useState([
    `${apartment.labelInmueble}`,
  ]);
  const [propertyIds, setPropertyIds] = useState([+apartment.tipoInmueble]);

  const [optionYears, setOptionYears] = useState("+/- 5 años");
  const [filterYear, setFilterYear] = useState(5);

  const { loading, error, data } = useQuery(
    getCompareExternalSources(
      longitud,
      latitud,
      radioZona,
      area,
      filterArea,
      antiguedad,
      filterYear,
      garageIds,
      elevatorIds,
      sourceIds,
      filterDate,
      propertyIds
    )
  );

  useEffect(() => {
    if (!data) return;
    setCompareByExternalSource(data.getComparareByExternalSources);
  }, [data]);

  useEffect(() => {
    if (hasExternalRows && loading) removeAllExtrenalRows();
  }, [loading, hasExternalRows, removeAllExtrenalRows]);

  const externalRowHandler = (row, isSelected) => {
    if (isSelected) return removeRow(row.id);
    addExternalRow(row);
    const validationRes = validateOnSelection({
      formValues,
      apartment,
      selectedRows: [row],
    });
    if (validationRes.warningMessages.length === 0) return;
    setWarningsAndIds(validationRes);
  };

  const addManyRowsHandler = ({ rows, noRowsSelected }) => () => {
    if (!noRowsSelected) return removeManyRows(rows);
    addManyExternalRows(rows);
    const validationRes = validateOnSelection({
      formValues,
      apartment,
      selectedRows: rows,
    });
    if (validationRes.warningMessages.length === 0) return;
    setWarningsAndIds(validationRes);
  };

  return (
    <SelectedTable
      title="Comparables por otras fuentes"
      rows={compareByExternalSource}
      rowSelectionHandler={externalRowHandler}
      addManyRowsHandler={addManyRowsHandler}
      yearsCompare={+antiguedad}
      loading={loading}
      error={error}
      type={4}
    >
      <SelectedTableToolBar
        title="Comparables por otras fuentes"
        optionsArea={optionsArea}
        setOptionArea={setOptionArea}
        textArea={optionArea}
        setFilterArea={setFilterArea}
        garageOptions={elevatorGarageOptions}
        garageName={garageName}
        setGarageName={setGarageName}
        garageIds={garageIds}
        setGarageIds={setGarageIds}
        elevatorOptions={elevatorGarageOptions}
        elevatorName={elevatorName}
        setElevatorName={setElevatorName}
        elevatorIds={elevatorIds}
        setElevatorIds={setElevatorIds}
        sourceOptions={sourceOptions}
        sourceName={sourceName}
        setSourceName={setSourceName}
        sourceIds={sourceIds}
        setSourceIds={setSourceIds}
        optionsDateLead={optionsDateLead}
        dateLeadName={dateLeadName}
        setDateLeadName={setDateLeadName}
        setFilterDate={setFilterDate}
        optionsProperty={optionsProperty}
        propertyName={propertyName}
        setPropertyName={setPropertyName}
        propertyIds={propertyIds}
        setPropertyIds={setPropertyIds}
        optionsYears={optionsYears}
        setOptionYears={setOptionYears}
        textYears={optionYears}
        setFilterYear={setFilterYear}
        externalZone={radioZona}
        handleExternalZone={setRadioZona}
      />
    </SelectedTable>
  );
};
const mapStateToProps = (state) => ({
  apartment: selectApartment(state),
  formValues: selectPricingData(state),
  hasExternalRows: selectHasExternalRows(state),
});

const mapDispatchToProps = (dispatch) => ({
  addExternalRow: (kv) => dispatch(addExternalRowAction(kv)),
  addManyExternalRows: (rows) => dispatch(addManyExternalRowAction(rows)),
  removeRow: (id) => dispatch(removeRowAction(id)),
  removeManyRows: (rows) => dispatch(removeManyRowsAction(rows)),
  removeAllExtrenalRows: () => dispatch(removeAllExternalRowsAction()),
  setWarningsAndIds: (payload) => dispatch(setWarningsAndIdsAction(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExternalSourcesContainer);
