import React from "react";
import { ResultFieldsWrapper, StyledInputResult } from "./style";
import Button from "../Button/button";
import NumberFormat from "react-number-format";
import WarningsModal from "../WarningsModal/WarningsModal";
import { useSelector } from "react-redux";

const ResultFields = (props) => {
  const {
    formValues,
    derivedValues,
    finalDerivedValues,
    updatePricingData,
    calculatePrecioHabiFinal,
    handleSubmit,
    final,
    isValid,
    hasWarnings,
    isValidForPricingFinal,
    handleSubmitOutliers,
    handleChangeAskPrice,
    hasManualPrice,
  } = props;
  const { userData } = useSelector((state) => state);

  const handleChange = (val) => {
    if (formValues?.precio_habi_final || formValues?.precio_habi_final !== 0) {
      updatePricingData({
        precio_habi_final: 0,
      });
    } else {
      updatePricingData({
        ask_price_final: val.floatValue,
      });
    }
  };

  return (
    <ResultFieldsWrapper>
      {!final && (
        <div className="field-container">
          <div className="field-price">
            <p>Valor m²</p>
            <NumberFormatContainer
              name="valor_m2"
              value={derivedValues.valor_m2 || null}
              disabled
            />
          </div>
          <div className="field-price">
            <p>Ask Price</p>
            <NumberFormatContainer
              name="ask_price"
              value={formValues.ask_price || null}
              disabled={hasManualPrice ? false : true}
              onValueChange={(val) => {
                handleChangeAskPrice(val.floatValue);
              }}
            />
          </div>
          <div className="field-price">
            <p>Redondeo Habi</p>
            <NumberFormatContainer
              name="redondeo_habi"
              value={derivedValues.redondeo_habi || null}
              disabled
            />
          </div>
          <div className="field-price">
            <p>Precio Habi</p>
            <NumberFormatContainer
              name="precio_habi"
              value={formValues.precio_habi || null}
              disabled
            />
          </div>
          {userData.role !== "ventas" && (
            <>
              <Button
                id="saveButton"
                typeClass={isValid ? "primary" : "disabled"}
                className="d-inline-block"
                label="Guardar"
                type="submit"
                onClick={handleSubmit}
                disabled={isValid ? false : true}
              />
              <Button
                id="saveOutliersButton"
                typeClass="primary"
                className="d-inline-block"
                label="Guardar Outliers"
                type="submit"
                onClick={handleSubmitOutliers}
              />
            </>
          )}
        </div>
      )}
      {final && (
        <div className="field-container-final">
          <div className="result-final">
            <div className="field-price">
              <p>Valor m² Final</p>
              <NumberFormatContainer
                name="valor_m2_final"
                value={finalDerivedValues.valor_m2_final || null}
                disabled
              />
            </div>
            <div className="field-price">
              <p>Ask Price Final</p>
              <NumberFormatContainer
                name="ask_price_final"
                value={formValues.ask_price_final || null}
                onValueChange={(val) => handleChange(val)}
              />
            </div>
            <div className="field-price">
              <p>Redondeo Habi Final</p>
              <NumberFormatContainer
                name="redondeo_habi_final"
                value={finalDerivedValues.redondeo_habi_final || null}
                disabled
              />
            </div>
            <div className="field-price">
              <p>Precio Habi Final</p>
              <NumberFormatContainer
                name="precio_habi_final"
                value={formValues.precio_habi_final || null}
                disabled
              />
            </div>
          </div>
          <div className="result-extra">
            <div className="field-price">
              <p>Zona</p>
              <NumberFormatContainer
                name="precio_zona"
                value={formValues.precio_zona || null}
                onValueChange={(val) => {
                  updatePricingData({
                    precio_zona: val.floatValue,
                  });
                }}
              />
            </div>
            <div className="field-price">
              <p>Nivel de confianza</p>
              <NumberFormat
                placeholder="00.00%"
                name="nivel_confianza"
                className="input-price"
                value={formValues.nivel_confianza || null}
                onValueChange={(val) => {
                  updatePricingData({
                    nivel_confianza: val.floatValue,
                  });
                }}
              />
            </div>
            <div className="field-price field-price-end">
              <p>Consistencia conjunto y zona</p>
              <StyledInputResult
                type="number"
                placeholder="0"
                key={`consistency-input`}
                name="consistencia_conjunto"
                onChange={(e) => {
                  updatePricingData({
                    consistencia_conjunto: +e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <Button
            id="calculateButton"
            className="d-inline-block"
            label="Calcular Precio"
            onClick={calculatePrecioHabiFinal}
            typeClass={isValidForPricingFinal ? "primary" : "disabled"}
            disabled={isValidForPricingFinal ? false : true}
          />
          <div style={{ height: "12px" }} />
          <Button
            id="saveButton"
            typeClass={isValid ? "primary" : "disabled"}
            className="d-inline-block"
            label="Guardar"
            type="submit"
            disabled={isValid ? false : true}
            onClick={handleSubmit}
          />
          <Button
            id="saveOutliersButton"
            typeClass="primary"
            className="d-inline-block"
            label="Guardar Outliers"
            type="submit"
            onClick={handleSubmitOutliers}
          />
        </div>
      )}
      {hasWarnings && <WarningsModal handleSubmit={handleSubmit} />}
    </ResultFieldsWrapper>
  );
};

const NumberFormatContainer = (props) => (
  <NumberFormat
    thousandSeparator={true}
    prefix={"$"}
    maxLength={13}
    placeholder="$000,000.000"
    type="text"
    className="input-price"
    name={props.name}
    value={props.value}
    disabled={props.disabled}
    onValueChange={props.onValueChange}
  />
);

export default ResultFields;
