import React from "react";
import mainMarker from "../../static/assets/icons/main-map-marker.png";
import comparableMarker from "../../static/assets/icons/comparable-marker.png";
import habiLogo from "../../static/assets/logo/habi.svg";
import GoogleMapReact from "google-map-react";
import ComparablesSummaryContainer from "./ComparablesSummary/ComparablesSummary.container";

import { CerrarX, HabiLogoContainer } from "./MapComparables.style";
import {
  muliExtraBoldFamily,
  nightBlue000,
  nightBlue500,
} from "../../StylesConstants";

export const MainMarker = () => (
  <img
    style={{ transform: "translate(-30px,-54px)" }}
    src={mainMarker}
    alt="main inmueble"
  />
);

export const ComparableMarker = () => (
  <img
    style={{ transform: "translate(-11px,-6px)" }}
    src={comparableMarker}
    alt="comparable marker"
  />
);

export const RadiousInfo = () => (
  <div
    style={{
      width: "64px",
      height: "32px",
      backgroundColor: `${nightBlue500}`,
      borderRadius: "8px",
      color: `${nightBlue000}`,
      fontFamily: `${muliExtraBoldFamily}`,
      fontSize: "14px",
      lineHeight: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      transform: "translateY(-330px)"
    }}
  >
    {" "}
    500 m{" "}
  </div>
);

const MapComparables = ({
  center,
  comparableCoords,
  closeMapHandler,
  handleApiLoaded,
  setPolygon,
  clearPolygon,
  buttonRef,
  hasValidPolygon,
}) => (
  <div style={{ height: "100vh", width: "100vw" }}>
    <HabiLogoContainer>
      <img src={habiLogo} alt="habi logo" />
    </HabiLogoContainer>
    <CerrarX>
      <div onClick={closeMapHandler}>&times;</div>
      <button hidden ref={buttonRef}></button>
    </CerrarX>
    {hasValidPolygon && <ComparablesSummaryContainer buttonRef={buttonRef} />}
    <GoogleMapReact
      bootstrapURLKeys={{
        key: [process.env.REACT_APP_GOOGLE_MAPS_KEY],
        libraries: ["drawing"],
      }}
      defaultCenter={center}
      defaultZoom={16.5}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) => {
        handleApiLoaded({
          map,
          maps,
          setPolygon,
          buttonRef,
          clearPolygon,
          center,
        });
      }}
    >
      {comparableCoords.map((point) => (
        <ComparableMarker
          lat={point.lat}
          lng={point.lng}
          key={`${Math.random()}${point.lng}${point.lat}`}
        />
      ))}
      <MainMarker lat={center.lat} lng={center.lng} />
      <RadiousInfo lat={center.lat} lng={center.lng} />
    </GoogleMapReact>
  </div>
);

export default MapComparables;
