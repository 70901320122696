import styled, { css } from "styled-components";
import {
  size,
  nightBlue000,
  nightBlue500,
  nightBlue700,
  nightBlue300,
  electricPurple500,
  electricPurple400,
  electricPurple700,
  scarpaGray000,
  scarpaGray200,
  scarpaGray100,
  scarpaGray300,
  skyBlue500,
} from "../../StylesConstants";
export const ImageAndIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const extraSmallImage = css`
  svg,
  img {
    margin-right: 8px;
    width: 8px;
    height: 8px;
  }
  .end {
    svg,
    img {
      margin-right: unset;
      margin-left: 8px;
    }
  }
`;
const extraLargeImg = css`
  svg,
  img {
    margin-right: 12px;
    width: 20px;
    height: 20px;
  }
  .end {
    svg,
    img {
      margin-right: unset;
      margin-left: 12px;
    }
  }
`;
const largeImg = css`
  svg,
  img {
    margin-right: 12px;
    width: 16px;
    height: 16px;
  }
  .end {
    svg,
    img {
      margin-right: unset;
      margin-left: 12px;
    }
  }
`;
const mediumImg = css`
  svg,
  img {
    margin-right: 12px;
    width: 16px;
    height: 16px;
  }
  .end {
    svg,
    img {
      margin-right: unset;
      margin-left: 12px;
    }
  }
`;
const smallImage = css`
  svg,
  img {
    margin-right: 8px;
    width: 16px;
    height: 16px;
  }
  .end {
    svg,
    img {
      margin-right: unset;
      margin-left: 8px;
    }
  }
`;
const baseClasses = {
  extraSmall: "extra-small",
  small: "small",
  medium: "medium",
  large: "large",
  extraLarge: "extra-large",
};
const tabletClasses = {
  extraSmall: "tablet-extra-small",
  small: "tablet-small",
  medium: "tablet-medium",
  large: "tablet-large",
  extraLarge: "tablet-extra-large",
};
const desktopClasses = {
  extraSmall: "desktop-extra-small",
  small: "desktop-small",
  medium: "desktop-medium",
  large: "desktop-large",
  extraLarge: "desktop-extra-large",
};
const createPrimarySizeClasses = (classNames) => css`
  &.${classNames.extraSmall} {
    height: 24px;
    padding: 4px 10px;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.02em;
    ${extraSmallImage}
  }
  &.${classNames.small} {
    height: 24px;
    padding: 5px 16px;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.04em;
    ${smallImage}
  }
  &.${classNames.medium} {
    height: 32px;
    padding: 9px 22px;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.04em;
    ${mediumImg}
  }
  &.${classNames.large} {
    height: 40px;
    padding: 13px 22px;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.04em;
    ${largeImg}
  }
  &.${classNames.extraLarge} {
    height: 44px;
    padding: 14px 22px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.04em;
    ${extraLargeImg}
  }
`;
export const ButtonPrimary = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: unset;
  text-align: center;
  color: ${nightBlue000};
  background: ${electricPurple500};
  border-radius: 100px;
  border: 2px solid ${electricPurple500};
  transition: 0.15s;
  font-family: "Muli Bold";
  path {
    fill: ${(props) => props.svgFill || nightBlue000};
  }
  @media screen and (min-width: 0px) {
    ${createPrimarySizeClasses(baseClasses)}
  }
  @media screen and (min-width: ${size.tablet}) {
    ${createPrimarySizeClasses(tabletClasses)}
  }
  @media screen and (min-width: ${size.desktop}) {
    ${createPrimarySizeClasses(desktopClasses)}
  }
  &:hover {
    background: ${electricPurple400};
  }
  &:active {
    background: ${electricPurple700};
  }
  &:disabled {
    background: ${scarpaGray100};
    color: ${scarpaGray300};
  }
  &:focus {
    border: 2px solid ${skyBlue500};
    outline: unset;
  }
`;
const createSecondarySizeClasses = (classNames) => css`
  &.${classNames.extraSmall} {
    height: 24px;
    padding: 5px 11px;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.02em;
    &:focus {
      padding: 3px 9px;
    }
    ${extraSmallImage}
  }
  &.${classNames.small} {
    height: 32px;
    padding: 6px 15px;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.04em;
    &:focus {
      padding: 5px 14px;
    }
    ${smallImage}
  }
  &.${classNames.medium} {
    height: 40px;
    padding: 10px 24px;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.04em;
    &:focus {
      padding: 9px 23px;
    }
    ${mediumImg}
  }
  &.${classNames.large} {
    height: 48px;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.04em;
    padding: 14px 23px;
    &:focus {
      padding: 13px 22px;
    }
    ${largeImg}
  }
  &.${classNames.extraLarge} {
    height: 56px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.04em;
    padding: 15px 22px;
    &:focus {
      padding: 14px 21px;
    }
    ${extraLargeImg}
  }
`;
export const ButtonSecondary = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: unset;
  text-align: center;
  color: ${nightBlue500};
  background: ${nightBlue000};
  border-radius: 100px;
  border: 1px solid ${scarpaGray100};
  transition: 0.15s;
  font-family: "Muli Bold";
  path {
    fill: ${(props) => props.svgFill || nightBlue500};
  }
  @media screen and (min-width: 0px) {
    ${createSecondarySizeClasses(baseClasses)}
  }
  @media screen and (min-width: ${size.tablet}) {
    ${createSecondarySizeClasses(tabletClasses)}
  }
  @media screen and (min-width: ${size.desktop}) {
    ${createSecondarySizeClasses(desktopClasses)}
  }
  &:hover {
    color: ${nightBlue000};
    background: ${nightBlue300};
  }
  &:active {
    color: ${nightBlue000};
    background: ${nightBlue700};
  }
  &:disabled {
    color: ${scarpaGray200};
    background: ${scarpaGray000};
    border: 1px solid ${scarpaGray200};
  }
  &:focus {
    padding: 4px 10px;
    border: 2px solid ${skyBlue500};
    outline: unset;
  }
`;
