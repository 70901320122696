export const optionsArea = {
  Todas: 0,
  "+/- 3 m²": 3,
  "+/- 5 m²": 5,
  "+/- 7 m²": 7,
  "+/- 10 m²": 10,
};

export const optionsDateLead = {
  Todas: 0,
  "Menos de 2 meses": 2,
  "Menos de 6 meses": 6,
  "Menos de 12 meses": 12,
};

export const optionsProperty = {
  0: "Todas",
  1: "Apartamento",
  2: "Casa",
  3: "Casa en Conjunto",
};

export const optionsYears = {
  Todas: 0,
  "+/- 3 años": 3,
  "+/- 5 años": 5,
  "+/- 7 años": 7,
  "+/- 10 años": 10,
};

export const optionsYearsExternalSource = {
  Todas: 0,
  "Menos de 1 año": 1,
  "De 1 a 8 años": 8,
  "De 9 a 15 años": 15,
  "De 16 a 30 años": 30,
  "Más de 30 años": 31,
};

export const elevatorGarageOptions = {
  Todos: 0,
  Si: 1,
  No: 2,
};
