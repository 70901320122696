import styled from "styled-components";
import {
  archiaRegular,
  electricPurple500,
  electricPurple600,
  hintPerano100,
  muliBoldFamily,
  muliExtraBoldFamily,
  nightBlue000,
  nightBlue400,
  scarpaGray000,
} from "../../StylesConstants";

export const LoginWrapper = styled.section`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr;
  width: 100vw;
  height: 100vh;
  background-color: ${scarpaGray000};
  align-items: center;
  .decoration {
    display: grid;
    grid-template-columns: 200px 200px;
    height: 100vh;
    position: absolute;
    width: 100vw;
    justify-content: space-between;
    img:nth-child(2n) {
      align-self: end;
    }
  }
  .content {
    grid-column: 5 / span 4;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;

    .login-container {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      row-gap: ${({chooseRole}) => chooseRole ? "1rem" : "3rem" };
      border-radius: 1.25rem;
      background-color: ${nightBlue000};
      z-index: 2;
      width: 24rem;
      height: 30.75rem;
      box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05),
        0px 4px 8px rgba(48, 49, 51, 0.1);

      .title {
        color: ${electricPurple600};
        font: 2.25rem ${muliExtraBoldFamily};
        margin: 0;
        line-height: 2rem;
        letter-spacing: 0.02em;
      }

      .logo-img {
        width: 3.5rem;
      }

      .role-buttons {
        .question {
          color: ${nightBlue400};
          font-family: ${archiaRegular};
          font-size: 16px;
          line-height: 24px;
        }
        .role {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          align-content: center;
          column-gap: 8px;
          cursor: pointer;
          border: 1px solid ${electricPurple500};
          box-sizing: border-box;
          border-radius: 8px;
          color: ${electricPurple500};
          padding: 8px 12px;
          margin-bottom: 16px;
          &:hover{
            background-color: ${hintPerano100};
          }
          span {
            font-family: ${muliBoldFamily};
            font-size: 1rem;
          }
        }
      }
    }
  }
`;
