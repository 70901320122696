import styled from "styled-components";
import { scarpaGray600 } from "../../StylesConstants";

export const TableTitleContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${scarpaGray600};
`;

export const TableTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  margin: 16px 24px;
`;

export const AverageM2Container = styled.div`
  margin: 16px 24px 16px auto;
  font-size: 14px;

  strong {
    font-family: "Muli ExtraBold";
  }
`;
