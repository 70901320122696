import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { connect } from "react-redux";
import {
  getSourcesFilters,
  getStarPricing,
  getStatusFilters,
} from "../../queries/queries";

import CalculateAmountsContainer from "../CalculateAmounts/calculateAmountsContainer";
import ResultFieldsContainer from "../ResultFields/resultFields.container";
import CurrentLeadTable from "../CurrentLeadTable/currentLeadTable";
import TypologyListContainer from "../TypologyList/typologyListContainer";
import CommentContainer from "../Comment/commentContainer";
import ByZoneContainer from "../ComparableByZone/byZoneContainer";
import ByLotContainer from "../ComparableByLot/byLotContainer";
import ManualTableContainer from "../ManualTable/manualTableContainer";
import ExternalSourcesContainer from "../ComparableByExternalSources/externalSourcesContainer";
import AlreadyPricedContainer from "../AlreadyPriced/AlreadyPriced.container";

import { selectPolygonSelected } from "../../redux/selectors/mapPolygon.selectors";

import { starPrincing } from "./comparable.utils";

import { ComparableWrapper } from "./style";
import LeadInfo from "../LeadInfo/LeadInfo";

const Comparable = ({
  polygonSelected,
  setRefresh,
  type,
  userData,
  ...props
}) => {
  const [sourceOptions, setSourceOptions] = useState({ 1: "Ventana" });
  const [optionsExternalSources, setOptionsExternalSources] = useState({
    2: "Inmobiliaria",
  });
  const [statusOptions, setStatusOptions] = useState({ 0: "Todas" });
  const [starPricing, setStarPricing] = useState({});

  const { data: sources } = useQuery(getSourcesFilters(1));
  const [hasPrice,setHasPrice] = useState(false);

  useEffect(() => {
    const sourceObj = sources?.getSources.reduce(
      (acc, source) => ({ ...acc, [source.id]: source.fuente }),
      { 0: "Todas" }
    );
    setSourceOptions(sourceObj);
  }, [sources]);

  const { data: externalSources } = useQuery(getSourcesFilters(2));

  useEffect(() => {
    const sourceObj = externalSources?.getSources.reduce(
      (acc, source) => ({ ...acc, [source.id]: source.fuente }),
      { 0: "Todas" }
    );
    setOptionsExternalSources(sourceObj);
  }, [externalSources]);

  const { data: status } = useQuery(getStatusFilters(null));

  useEffect(() => {
    const statusObj = status?.getStatus.reduce(
      (acc, status) => ({ ...acc, [status.id]: status.status }),
      { 0: "Todas" }
    );
    setStatusOptions(statusObj);
  }, [status]);

  const { data: dataStar } = useQuery(getStarPricing());

  useEffect(() => {
    const star = starPrincing(dataStar);
    return setStarPricing(star);
  }, [dataStar]);

  if (!polygonSelected) return <></>;

  return (
    <ComparableWrapper>
      <div className="fixed-table">
        <CurrentLeadTable type={type} />
      </div>
      {type === "endpricing" && (
        <div className="lead-info">
          <LeadInfo />
        </div>
      )}
      <div className="comp-conjunto">
        <ByLotContainer
          sourceOptions={sourceOptions}
          statusOptions={statusOptions}
        />
      </div>
      <div className="comp-zona">
        <ByZoneContainer
          sourceOptions={sourceOptions}
          statusOptions={statusOptions}
        />
      </div>
      <div className="already-priced">
        <AlreadyPricedContainer />
      </div>
      <div className="comp-external">
        <ExternalSourcesContainer sourceOptions={optionsExternalSources} />
      </div>
      <div className="manual-selection">
        <ManualTableContainer starPricing={starPricing} />
      </div>
      <div className="typology-list">
        <TypologyListContainer />
      </div>
      <div className="buttons">
        <CalculateAmountsContainer
          flagTab={props.flagTab}
          setHasPrice={setHasPrice}
        />
      </div>
      <div className="pricing-area">
        <ResultFieldsContainer
          flagTab={props.flagTab}
          setRefresh={setRefresh}
          starPricing={starPricing}
          typeTab={type}
          hasPrice={hasPrice}
          setHasPrice={setHasPrice}
        />
      </div>
      {userData.role !== "ventas" && (
        <div className="comments">
          <CommentContainer typePage={"dashboard"} />
        </div>
      )}
    </ComparableWrapper>
  );
};

const mapStateToProps = (state) => ({
  polygonSelected: selectPolygonSelected(state),
  userData: state.userData,
});

export default connect(mapStateToProps)(Comparable);
