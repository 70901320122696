import React from "react";
import {
  getBorderColor,
  getTitleColor,
  getBackgroundColor,
  getIcon,
  getTitle,
} from "./NotificationBanner.utils";
import { NotificationContainer } from "./NotificationBanner.style";

/**
 * @param {string} type - "info" | "warning" | "error" | "success" determines logo and color based on case.
 * @param {string} message - string that determines the main message for the banner.
 * @param {string} boldMessage - string that determines the bold text displayed AFTER the main message.
 * @param {*} any - Any prop passed will be used on the top level div. Any prop that works for a div will work here, such as onClick.
 *
 * @example
 *
 *  <NotificationBanner
      type="info"
      message="El resultado de este estudio está hecho por un algoritmo y los valores
          mostrados pueden variar."
    />  
 */
const NotificationBanner = (props) => {
  const { type, message, boldMessage } = props;

  const border = getBorderColor(type);
  const titleColor = getTitleColor(type);
  const backgroundColor = getBackgroundColor(type);
  const icon = getIcon(type);
  const title = getTitle(type);

  return (
    <NotificationContainer
      notificationBorder={border}
      titleColor={titleColor}
      backgroundColor={backgroundColor}
      {...props}
    >
      <div className="sidebar">{icon}</div>
      <div className="main">
        <h3>{title}</h3>
        <p>
          {message}
          <strong>{boldMessage}</strong>
        </p>
      </div>
    </NotificationContainer>
  );
};

export default NotificationBanner;
