export const userDataActionTypes = {
  UPDATE_USER_DATA: "UPDATE_USER_DATA",
  RESET_USER_DATA: "RESET_USER_DATA",
};

export const updateUserDataAction = (payload) => ({
  type: userDataActionTypes.UPDATE_USER_DATA,
  payload,
});

export const resetUserDataAction = () => ({
  type: userDataActionTypes.RESET_USER_DATA,
});
