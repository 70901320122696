import axios from "axios";

const rootUrl = process.env.REACT_APP_FORM_API_URL_V2;
const basePath = process.env.REACT_APP_ENDPOINT_FORMS_API_BASE_PATH;
const apiKey = process.env.REACT_APP_FORMS_API_KEY;

export const getStandardizationAll = async (data) => {
  const endpoint =
    process.env.REACT_APP_PRICING_STANDARDIZATION_ALL_URL_ENDPOINT;
  const url = rootUrl + basePath + endpoint;
  return axios.post(url, data, {
    headers: {
      "x-api-key": apiKey,
      "Content-Type": "application/json",
    },
  });
};

export const getCoefficient = async (data) => {
  const endporint = process.env.REACT_APP_PRICING_COEFFICIENT;
  const url = rootUrl + basePath + endporint;

  return axios.post(url, data, {
    headers: {
      "x-api-key": apiKey,
      "Content-Type": "application/json",
    },
  });
};
