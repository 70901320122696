import styled from "styled-components";
import {
  brightTurquoise000,
  electricPurple600,
  error,
  scarpaGray000,
  scarpaGray200,
  scarpaGray300,
  scarpaGray600,
  white,
  nightBlue100,
} from "../../StylesConstants";
import { withStyles } from "@material-ui/core/styles";
import { Table, Paper } from "@material-ui/core";

export const ToolWrapper = styled.div`
  width: 100%;
  .active {
    background: ${brightTurquoise000};
  }
  .wrapper {
    padding: 16px 0px;
    .filters {
      margin: 19px 0px;
      display: flex;
      flex-wrap: wrap;
      grid-template-columns: repeat(6, auto);
      width: fit-content;
      column-gap: 40px;
      .container {
        span {
          font-size: 13px;
        }
      }
      .container-check {
        span {
          font-size: 13px;
        }
        .btn-primary {
          border: none;
          border-color: none;
        }
      }
    }
    .filters-zona {
      display: flex;
      flex-wrap: wrap;
      margin: 19px 0px;

      .container {
        margin-right: 30px;
        margin-bottom: 12px;

        span {
          font-size: 13px;
        }
      }

      .container-check {
        span {
          font-size: 13px;
        }
        .btn-primary {
          border: none;
          border-color: none;
        }
      }

      .radio-container {
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        column-gap: 20px;
        height: 0;
        margin-top: 8px;
        margin-right: 30px;

        .title {
          font-size: 13px;
          font-weight: 600;
          color: ${scarpaGray600};
          margin-bottom: 0px;
        }
        .switch-toggle {
          column-gap: 20px;
          align-items: center;
          li {
            height: auto;
          }
        }
        label {
          font-size: 13px;
        }
      }
    }

    .manual-comparable {
      display: flex;
      align-items: center;
      flex-direction: row;
      column-gap: 16px;
      align-items: center;
      font-size: 13px;
      padding-top: 1rem;
      .container span {
        font-weight: 600;
        font-size: 13px;
        color: ${scarpaGray600};
      }

      .sales {
        text-align: center;
        color: ${scarpaGray600};
        border-right: 1px solid ${scarpaGray300};
        .title-sales {
          font-weight: 600;
        }
        .value-sales {
          padding: 16px 24px 16px 16px;
        }
        .donut-icon {
          font-size: 18px;
          margin-right: 6px;
        }
      }

      .percent {
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        .separator {
          border-right: 1px solid ${scarpaGray300};
          height: 24px;
        }
      }

      .standardization {
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        .title-standardization {
          font-weight: 600;
        }
      }

      .greater-than {
        color: ${error};
        padding: 16px 24px 16px 16px;
      }
    }
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 72px;
`;

export const NoResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  color: ${nightBlue100};
  text-transform: uppercase;
  letter-spacing: 0.2em;
  height: 72px;
  text-align: center;

  .top-message {
    font-family: "Open Sans ExtraBold";
    margin: unset;
  }

  .bottom-message {
    font-family: "Open Sans";
    margin: unset;
  }
`;

export const StarContainer = styled.div`
  margin: 0 auto;
  width: fit-content;
  margin-bottom: 24px;
`;

export const StyledButtonGroup = styled.div`
  height: 100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
`;

export const StyledPaper = withStyles(() => ({
  root: {
    maxWidth: "max-width: calc(100vw - 32px)",
    marginBottom: "16px",
  },
}))(Paper);

export const StyledTable = withStyles(() => ({
  root: {
    minWidth: "750px",
    maxWidth: "max-width: calc(100vw - 32px)",
  },
}))(Table);

export const ButtonRemove = styled.div`
  cursor: pointer;
  &:hover {
    color: ${scarpaGray300};
  }
`;

export const FlagContainer = styled.div`
  display: inline;

  .activeFlag {
    color: red;
  }
  .inactiveFlag {
    color: transparent;
  }
  .inactiveFlag:hover {
    color: red;
  }
`;

export const HiddenSorter = styled.span`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 20px;
  width: 1px;
`;

export const StyledMultipleCheckboxes = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 8px;
  .checkbox_section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    .check-label {
      margin: 0;
    }
  }
`;

export const StyledButton = styled.div`
  border: 1px solid ${scarpaGray200};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: ${white};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${scarpaGray600};
  &:hover {
    color: ${scarpaGray000};
    background-color: ${electricPurple600};
    margin: 0px;
  }
  .download-icon {
    font-size: 18px;
    color: ${electricPurple600};
  }
`;
