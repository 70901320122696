import { electricPurple500, scarpaGray700 } from "../../StylesConstants";

const createCoordArray = (pathArr) =>
  pathArr.map((point) => ({
    lat: point.lat(),
    lng: point.lng(),
  }));

export const handleApiLoaded = ({
  map,
  maps,
  setPolygon,
  buttonRef,
  clearPolygon,
  center,
}) => {
  map.setOptions({
    fullscreenControl: false,
  });

  const drawingManager = new maps.drawing.DrawingManager({
    drawingMode: maps.drawing.OverlayType.POLYGON,
    drawingControl: false,
    drawingControlOptions: {
      position: maps.ControlPosition.TOP_CENTER,
      drawingModes: [maps.drawing.OverlayType.POLYGON],
    },
    polygonOptions: {
      fillColor: electricPurple500,
      fillOpacity: 0.2,
      strokeColor: electricPurple500,
      strokeWeight: 3,
      clickable: true,
      editable: true,
      zIndex: 1,
    },
  });

  let polygon;

  const removePolygon = () => {
    if (!polygon) return;
    polygon.setMap(null);
    clearPolygon();
    drawingManager.setDrawingMode(maps.drawing.OverlayType.POLYGON);
  };

  buttonRef.current.addEventListener("click", removePolygon);

  drawingManager.setMap(map);

  maps.event.addListener(drawingManager, "overlaycomplete", (event) => {
    const pathArr = event.overlay.getPath().getArray();
    const coordArr = createCoordArray(pathArr);

    if (coordArr.length < 3) {
      event.overlay.setMap(null);
      return;
    }

    polygon = event.overlay;

    setPolygon(coordArr);

    drawingManager.setDrawingMode(null);

    event.overlay
      .getPath()
      .addListener("set_at", () => setPolygon(createCoordArray(pathArr)));

    event.overlay
      .getPath()
      .addListener("insert_at", () => setPolygon(createCoordArray(pathArr)));

    event.overlay
      .getPath()
      .addListener("remove_at", () => setPolygon(createCoordArray(pathArr)));
  });

  new maps.Circle({
    strokeColor: `${scarpaGray700}`,
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: `${scarpaGray700}`,
    fillOpacity: 0,
    map,
    center: center,
    radius: 500,
  });
};
