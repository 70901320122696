import React from "react";
import AttachMoneyRoundedIcon from "@material-ui/icons/AttachMoneyRounded";
import ArrowDownwardRoundedIcon from "@material-ui/icons/ArrowDownwardRounded";
import Overlay from "../overlay/overlay";
import { SimulatorTableWrapper } from "./SimulatorTable.style";
import { headerList } from "./SimulatorTable.utils";
import { HourFormat, MonthYearFormat } from "../../utils/dateFormat";
import { setApartmentAction } from "../../redux/actions/apartment.actions";
import Comparable from "../Comparable/comparable";
import Button from "../ButtonV2/Button";
import { useDispatch } from "react-redux";
import { resetPricingDataAction } from "../../redux/actions/pricingData.actions";
import { clearManualRowDataAction } from "../../redux/actions/manualRowData.actions";

const SimulatorTable = (props) => {
  const { dataTable } = props;
  const dispatch = useDispatch();

  return (
    <>
      <SimulatorTableWrapper>
        <div className="table-container">
          <table className="table">
            <thead>
              <tr>
                {headerList.map((item, index) => {
                  if (item === "Fecha de Creación") {
                    return (
                      <th className="dateCreation" key={index}>
                        {item}
                        <ArrowDownwardRoundedIcon className="icon-down-arrow" />
                      </th>
                    );
                  }
                  return <th key={index}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {dataTable?.map((apartment) => (
                <tr key={apartment.inmuebleID}>
                  <th>
                    {MonthYearFormat(apartment.fechaCreacion)}
                    <br />
                    <small>{HourFormat(apartment.fechaCreacion)}</small>
                  </th>
                  <th>{Number(apartment.nid)}</th>
                  <th>{apartment.proyecto}</th>
                  <th>{apartment.fuente}</th>
                  <th>{apartment.labelInmueble}</th>
                  <th>{apartment.direccion}</th>
                  <th>
                    {apartment.lastAskPrice !== null
                      ? "$ " +
                        new Intl.NumberFormat().format(apartment.lastAskPrice)
                      : ""}
                  </th>
                  <th>
                    <Button
                      buttonType="primary"
                      label="Price"
                      svgIcon={<AttachMoneyRoundedIcon />}
                      size="small"
                      onClick={() => {
                        dispatch(setApartmentAction(apartment));
                        dispatch(resetPricingDataAction());
                        dispatch(clearManualRowDataAction());
                      }}
                    />
                  </th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </SimulatorTableWrapper>
      <Overlay>
        <Comparable type={props.type} />
      </Overlay>
    </>
  );
};

export default SimulatorTable;
