import React, { useState, useEffect } from "react";
import Comment from "./comment";
import Button from "../Button/button";
import LogCommentContainer from "../LogComment/logCommentContainer";
import { Animated } from "react-animated-css";
import { connect } from "react-redux";
import CommentIcon from "@material-ui/icons/Comment";
import { CommentContainerWrapper } from "./style";
import { selectInmuebleID } from "../../redux/selectors/apartment.selectors";
import { selectUserEmail } from "../../redux/selectors/userData.selectors";
import { getHistoricoComentario } from "../../queries/queries";
import { useQuery } from "@apollo/client";

const CommentContainer = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [comments, setComments] = useState([]);

  const { inmuebleID, toggleRefetch } = props;

  const { data, refetch } = useQuery(getHistoricoComentario(inmuebleID));

  useEffect(() => {
    if (!data) return;
    setComments(data?.getHistoricoComentarioInmueble || data);
  }, [data]);

  useEffect(() => {
    refetch();
  }, [refetch, toggleRefetch]);

  const hideCommentField = (value) => {
    setIsVisible(!isVisible);
  };

  return (
    <>
      <CommentContainerWrapper>
        <Button
          id="button"
          typeClass="primary"
          className="comment-button"
          label="Comentar"
          type="button"
          onClick={hideCommentField}
        >
          <CommentIcon className="icon-comment" />
        </Button>
        <Animated
          isVisible={isVisible}
          animationIn="fadeIn"
          animationOut="fadeOut"
          animationInDuration={2000}
          animationOutDuration={1000}
        >
          {isVisible && <Comment />}
        </Animated>
        <LogCommentContainer commentaries={comments} />
      </CommentContainerWrapper>
    </>
  );
};

const mapStateToProps = (state) => ({
  email: selectUserEmail(state),
  inmuebleID: selectInmuebleID(state),
});

export default connect(mapStateToProps, null)(CommentContainer);
