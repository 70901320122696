import React from "react";
import { connect } from "react-redux";
import { selectPolygonSelected } from "../../redux/selectors/mapPolygon.selectors";
import { clearPolygonAction } from "../../redux/actions/mapPolygon.actions";
import { removeApartmentAction } from "../../redux/actions/apartment.actions";

import OverlayWrapper from "./style";
import { resetPricingDataAction } from "../../redux/actions/pricingData.actions";
import { useHistory } from "react-router";
import { updateUserDataAction } from "../../redux/actions/userData.actions";

const Overlay = (props) => {
  const {
    selectedPolygon,
    removeApartment,
    resetPricingData,
    clearPolygon,
    userData,
    updateUser,
  } = props;

  const history = useHistory();

  const handleCerrar = () => {
    clearPolygon();
    removeApartment();
    resetPricingData();
    if (userData.role === "ventas") {
      updateUser({
        nid: null,
      });
      history.push("/simulador");
    }
  };

  return (
    <OverlayWrapper>
      <div
        className={`overlay  overlay-open ${
          selectedPolygon ? "overlay-open" : "overlay-close"
        }`}
      >
        <div className="header">
          <span className="close-overlay closebtn" onClick={handleCerrar}>
            Cerrar &times;
          </span>
        </div>
        <div className="overlay-content">{props.children}</div>
      </div>
    </OverlayWrapper>
  );
};

const mapStateToProps = (state) => ({
  selectedPolygon: selectPolygonSelected(state),
  userData: state.userData,
});

const mapDispatchToProps = (dispatch) => ({
  removeApartment: () => dispatch(removeApartmentAction()),
  clearPolygon: () => dispatch(clearPolygonAction()),
  resetPricingData: () => dispatch(resetPricingDataAction()),
  updateUser: (data) => dispatch(updateUserDataAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Overlay);
