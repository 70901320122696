import React from "react";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";
import ArrowDropUpRoundedIcon from "@material-ui/icons/ArrowDropUpRounded";
import { MonthYearFormat, HourFormat } from "../../utils/dateFormat";
import { scarpaGray400 } from "../../StylesConstants";

export const getDisplayValue = ({ dataA, type }) => {
  const displayValue = dataA ?? "Sin Datos";
  if (!type || dataA === null) return displayValue;
  if (type === "price") {
    return `$ ${displayValue.toLocaleString("es-US")}`;
  }
  if (type === "area") {
    return (
      <>
        {displayValue} m<sup>2</sup>
      </>
    );
  }
  if (type === "date") {
    return (
      <>
        {MonthYearFormat(dataA)}
        <span style={{ color: scarpaGray400, paddingLeft: "8px" }}>
          {HourFormat(dataA)}
        </span>
      </>
    );
  }
};

export const getClassName = ({ ignoreComparison, dataA, dataB }) => {
  if (ignoreComparison) return;
  if (dataA > dataB) return "greater-than";
  if (dataA < dataB) return "less-than";
  return;
};

export const renderComparisonArrow = ({ className }) => {
  if (!className) return;
  if (className === "greater-than") return <ArrowDropUpRoundedIcon />;
  if (className === "less-than") return <ArrowDropDownRoundedIcon />;
};
