import { warningKeys, warnings } from "../data/warnings.data";
import { pipe } from "../../utils/generalUtils";

export const normalizeValidationData = ({
  formValues,
  selectedRows,
  apartment,
  starPricing
}) => ({
  ...formValues,
  comparables: selectedRows,
  apartment,
  starPricing: {...starPricing}
});

export const lessThan7ComparablesWarning = ({ comparables }) =>
  warnings.set(warningKeys.LESS_THAN_7_COMPARABLES, {
    message: `Solo has seleccionado ${comparables.length} comparables.`,
    question: "¿Estás seguro que no hay más?",
  });

export const possibleLastFloorWarning = ({ apartment }) =>
  warnings.set(warningKeys.POSSIBLE_LAST_FLOOR_NO_ELEVATOR, {
    message: `Estás haciendo un pricing de piso ${apartment.piso} sin ascensor.`,
  });

export const possibleQualityWarning = ({ starPricing }) =>
  warnings.set(warningKeys.SELECTED_MANUALLY_LESS_WEIGHT, {
    message: `Tus comparables cuentan con toda la calidad necesaria, recuerda que para continuar deben tener un puntaje mínimo de ${starPricing.valueNumber}.`,
});  

const dynamicWarningFns = [
  lessThan7ComparablesWarning,
  possibleLastFloorWarning,
  possibleQualityWarning,
];

export const generateDynamicWarnings = (validationData) => {
  dynamicWarningFns.forEach((fn) => fn(validationData));
  return validationData;
};

export const over20PercentPricing = (get) => ({ apartment, ask_price }) => {
  const twentyPercent = apartment.lastAskPrice * 0.2;
  const priceDifference = Math.abs(ask_price - apartment.lastAskPrice);
  if (twentyPercent < priceDifference && get === "messages")
    return warningKeys.OVER_20_PERCENT_VARIATION;
};

export const over20PercentPricingFinal = (get) => ({
  apartment,
  ask_price_final,
}) => {
  const twentyPercent = apartment.lastAskPrice * 0.2;
  const priceDifference = Math.abs(ask_price_final - apartment.lastAskPrice);
  if (twentyPercent < priceDifference && get === "messages")
    return warningKeys.OVER_20_PERCENT_VARIATION;
};

export const over20Percent = (get) => (validationData) =>
  validationData.ask_price_final
    ? over20PercentPricingFinal(get)(validationData)
    : over20PercentPricing(get)(validationData);

export const coeficientOver10 = (get) => ({ coeficiente }) =>
  coeficiente > 0.1 && get === "messages" && warningKeys.COEFICIENT_OVER_10;

export const lessThan7Comparables = (get) => ({ comparables }) =>
  comparables.length < 7 &&
  get === "messages" &&
  warningKeys.LESS_THAN_7_COMPARABLES;

export const getComparableIds = (comparable) => comparable.id;

export const halfComparablesByZone = (get) => ({ comparables }) => {
  const byZoneFuente = (comparable) => comparable.fuente === "zona";
  const filteredComparables = comparables.filter(byZoneFuente);
  const validation = filteredComparables.length > comparables.length / 2;
  if (validation && get === "messages")
    return warningKeys.HALF_COMPARABLES_BY_ZONE;
};

export const percentageSalesOver7 = (get) => ({ percentageSales }) =>
  percentageSales > 7 &&
  get === "messages" &&
  warningKeys.PERCENTAGE_SALES_OVER_7;

export const possibleLastFloorNoElevator = (get) => ({ apartment }) =>
  apartment.piso >= 5 &&
  apartment.ascensores < 1 &&
  get === "messages" &&
  warningKeys.POSSIBLE_LAST_FLOOR_NO_ELEVATOR;

export const differentGarageNumber = (get) => ({ comparables, apartment }) => {
  const byGarageQuantity = (comparable) =>
    comparable.garajes !== apartment.garajes;
  const filteredComparables = comparables.filter(byGarageQuantity);
  const validation = filteredComparables.length > 0;
  if (validation && get === "messages")
    return warningKeys.DIFFERENT_GARAGE_NUMBER;
  if (validation && get === "ids")
    return filteredComparables.map(getComparableIds);
};

export const differentBathroomNumber = (get) => ({
  comparables,
  apartment,
}) => {
  const byBathroomQuantity = (comparable) =>
    comparable.banos !== apartment.banos;
  const filteredComparables = comparables.filter(byBathroomQuantity);
  const validation = filteredComparables.length > 0;
  if (validation && get === "messages")
    return warningKeys.DIFFERENT_BATHROOM_NUMBER;
  if (validation && get === "ids")
    return filteredComparables.map(getComparableIds);
};

const casaOCasaConjunto = ["Casa", "casa con conjunto cerrado"];

export const comparingHouseToApto = (get) => ({ comparables, apartment }) => {
  if (!casaOCasaConjunto.includes(apartment.labelInmueble)) return;
  const isApto = (comparable) => comparable.labelInmueble === "Apartamento";
  const filteredComparables = comparables.filter(isApto);
  const validation = filteredComparables.length > 0;
  if (validation && get === "messages")
    return warningKeys.COMPARING_HOUSE_TO_APTO;
  if (validation && get === "ids")
    return filteredComparables.map(getComparableIds);
};

export const comparingAptoToHouse = (get) => ({ comparables, apartment }) => {
  if (apartment.labelInmueble !== "Apartamento") return;
  const isCasa = (comparable) =>
    casaOCasaConjunto.includes(comparable.labelInmueble);
  const filteredComparables = comparables.filter(isCasa);
  const validation = filteredComparables.length > 0;
  if (validation && get === "messages")
    return warningKeys.COMPARING_APTO_TO_HOUSE;
  if (validation && get === "ids")
    return filteredComparables.map(getComparableIds);
};

export const noElevator = (get) => ({ apartment }) =>
  apartment.ascensores < 1 && get === "messages" && warningKeys.NO_ELEVATOR;


  
export const lessRakingComparable = ({ comparables }) => {
  let flagQuality = false;
  let totalQuality = 0;
  comparables.forEach((row) => {
    if (row.quality === 0) flagQuality = true;
    totalQuality += row.quality  
  });
  return [flagQuality, totalQuality];
};

export const lessRakingComparableflagQualityWarning = (get) => ({ comparables }) => {
  const [flagQuality] = lessRakingComparable({comparables});
  if (flagQuality && get === "messages") return warningKeys.SELECTED_MANUALLY_HAS_NO_WEIGHT;
};

export const lessRakingComparableflagTotalQualityWarning = (get) => ({ comparables, starPricing }) => {
  const [, totalQuality] = lessRakingComparable({comparables});
  if (totalQuality < starPricing.valueNumber && get === "messages") return warningKeys.SELECTED_MANUALLY_LESS_WEIGHT;
};


const submitValidations = [
  over20Percent,
  coeficientOver10,
  lessThan7Comparables,
  halfComparablesByZone,
  percentageSalesOver7,
  possibleLastFloorNoElevator,
  noElevator,
  lessRakingComparableflagQualityWarning,
  lessRakingComparableflagTotalQualityWarning,
];

const liveValidations = [
  differentGarageNumber,
  differentBathroomNumber,
  comparingHouseToApto,
  comparingAptoToHouse,
];

export const getValidationMessages = (validations) => (validationData) =>
  validations.reduce((messages, validation) => {
    const result = validation("messages")(validationData) ?? false;
    return result ? [...messages, warnings.get(result)] : messages;
  }, []);

export const getSubmitValidationMessages = getValidationMessages(
  submitValidations
);

export const getLiveValidationMessages = getValidationMessages(liveValidations);

export const getValidationIds = (validations) => (validationData) => {
  const allIds = validations.reduce((ids, validation) => {
    const result = validation("ids")(validationData) ?? false;
    return result ? [...ids, ...result] : ids;
  }, []);
  const idSet = new Set(allIds);
  return [...idSet];
};

export const getLiveValidationIds = getValidationIds(liveValidations);

export const validatePricingSubmit = pipe(
  normalizeValidationData,
  generateDynamicWarnings,
  getSubmitValidationMessages
);

export const getLiveValidationMessagesAndIds = (validationData) => ({
  warningMessages: getLiveValidationMessages(validationData),
  rowIds: getLiveValidationIds(validationData),
});

export const validateOnSelection = pipe(
  normalizeValidationData,
  getLiveValidationMessagesAndIds
);
