import styled from "styled-components";
import {
  scarpaGray600,
  scarpaGray200,
  scarpaGray400,
} from "../../StylesConstants";

const CalendarWrapper = styled.div`
  display: flex;
  align-items: center;

  span {
    font-size: 15px;
    font-weight: 600;
    color: ${scarpaGray600};
    margin: 0 16px;
  }

  .calendar-input {
    border: 1px ${scarpaGray200} solid;
    min-width: 240px;
    height: 40px;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    align-items: center;
    font-size: 13px;

    .calendar-icon {
      margin: 0 16px;
    }

    .input-tag {
      outline: none;
      border: none;
    }
  }

  .Calendar {
    width: 220px;
    min-height: 280px;
  }

  .Calendar__header {
    padding: 16px 0;
  }

  .Calendar__monthSelector {
    padding: 0 10px 0 0;
    padding-bottom: 0;
  }

  .Calendar__monthArrowWrapper.-right {
    padding: 16px 0;
    transform: rotate(-90deg) scale(0.5);
  }

  .Calendar__monthArrowWrapper.-left {
    padding: 16px 0;
    transform: rotate(90deg) scale(0.5);
  }

  .Calendar__weekDays {
    color: ${scarpaGray400};
    padding: 0 16px;
  }

  .Calendar__section {
    padding: 16px;
  }

  .Calendar__sectionWrapper {
    min-height: 210px;
  }

  .Calendar__day.-ltr {
    font-size: 12px;
  }

  .Calendar__day.-ltr.-selectedStart,
  .Calendar__day.-ltr.-selectedEnd {
    border-radius: 8px;
    width: 24px;
  }

  .DatePicker__calendarArrow {
    margin: 0;
  }
`;

export default CalendarWrapper;
