import { standarizationActionTypes } from "../actions/standarizationData.actions";

const INITIAL_STATE = {
  area_normalizado: null,
  garaje_normalizado: null,
  bano_normalizado: null,
  piso_normalizado: null,
  ascensor_normalizado: null,
  antiguedad_normalizado: null,
  valor_m2_normalizado: null,
  tipo_inmueble_id_normalizado: null,
  nombre_conjunto_normalizado: null,
};

const standarizationReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case standarizationActionTypes.SET_PROP_STANDARIZATION:
      return { ...state, ...payload };
    case standarizationActionTypes.REMOVE_PROP_STANDARIZATION:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default standarizationReducer;
