export const flagSelectionDataActionTypes = {
    ADD_FLAG_ROW_DATA: "ADD_FLAG_ROW_DATA",
    CLEAR_FLAG_ROW_DATA: "CLEAR_FLAG_ROW_DATA",
  };
  
  export const addFlagRowDataAction = (fieldData) => ({
    type: flagSelectionDataActionTypes.ADD_FLAG_ROW_DATA,
    payload: fieldData,
  });
  
  export const clearFlagRowDataAction = () => ({
    type: flagSelectionDataActionTypes.CLEAR_FLAG_ROW_DATA,
  });
  