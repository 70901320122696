import React, { useState } from "react";
import SearchFilter from "../SearchFilter/searchFilter";
import StandarizationListing from "./StandarizationListing";
import { StandarizationWrapper } from "./style";

const StandarizationContainer = ({ nid="", external=false }) => {
  const [inputValue, setInputValue] = useState("");

  React.useEffect(() => {
    setInputValue(nid);
  }, [nid]);

  return (
    <StandarizationWrapper>
      <div className="header">
        {!external &&
          <h1>Normalización</h1>
        ||
          <h1>Editar información</h1>
        }
      </div>
      {!external &&
        <div className="filters">
          <SearchFilter inputValue={inputValue} setInputValue={setInputValue} />
        </div>
      }
      <div className="content-table">
        <StandarizationListing inputValue={+inputValue} />
      </div>
    </StandarizationWrapper>
  );
};

export default StandarizationContainer;
