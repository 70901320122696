export const manualRowDataActionTypes = {
  ADD_MANUAL_ROW_DATA: "ADD_MANUAL_ROW_DATA",
  CLEAR_MANUAL_ROW_DATA: "CLEAR_MANUAL_ROW_DATA",
  REMOVE_MANUAL_ROW_DATA_ID: "REMOVE_MANUAL_ROW_DATA_ID",
};

export const addManualRowDataAction = (fieldData) => ({
  type: manualRowDataActionTypes.ADD_MANUAL_ROW_DATA,
  payload: fieldData,
});

export const clearManualRowDataAction = () => ({
  type: manualRowDataActionTypes.CLEAR_MANUAL_ROW_DATA,
});

export const removeManualRowDataIdAction = (id) => ({
  type: manualRowDataActionTypes.REMOVE_MANUAL_ROW_DATA_ID,
  payload: id,
});
