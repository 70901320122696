import styled from "styled-components";
import {
  brightTurquoise000,
  scarpaGray600,
  scarpaGray500,
  brightTurquoise500,
  archiaRegular,
  scarpaGray700,
  scarpaGray000,
} from "../../StylesConstants";

const CheckBoxWrapper = styled.div`
  .container-check {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 8px;
    justify-items: start;
    align-items: center;
    font-family: ${archiaRegular};
    padding: 0px;
    span {
      font-size: 15px;
      font-weight: 600;
      color: ${scarpaGray600};
    }

    .dropdow-container {
      display: flex;
      align-items: center;
      position: relative;

      .btn-primary {
        background-color: ${scarpaGray000};
        border: 2px solid ${scarpaGray700};
        color: ${scarpaGray600};
        border-radius: 100px;
        padding: 8px 16px;
        padding-right: 33px;
        font-size: 15px;
        color: ${scarpaGray600};
        min-width: 130px;
        height: 40px;
        text-decoration: underline;
        text-align: center;

        &:hover {
          border: none;
          background-color: ${brightTurquoise000};
        }

        &:focus {
          background-color: ${brightTurquoise000};
          box-shadow: none;
          border: 2px solid ${brightTurquoise500};
        }

        &.dropdown-toggle:focus {
          box-shadow: none;
          color: ${scarpaGray600};
        }
      }

      .icon-arrow {
        transform: translateY(-50%);
        position: absolute;
        right: 10px;
        top: 50%;
        color: ${scarpaGray500};
      }
    }

    .dropdown-menu {
      border: none;
      box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05),
        0px 8px 16px rgba(48, 49, 51, 0.1);
      border-radius: 8px;
      width: 300px;
      min-height: 235px;
      max-height: 235px;
      overflow-y: auto;
      padding: 19px 0px;
      ::-webkit-scrollbar {
        display: none;
      }
      .subtitle {
        font-size: 11px;
        font-weight: 400;
      }
    }

    .check-box {
      padding: 8px 19px;
      color: ${scarpaGray600};
      font-size: 16px;
      text-decoration: none;
      text-align: start;

      &:hover {
        background-color: ${brightTurquoise000};
      }

      &:focus {
        background-color: ${brightTurquoise500};
      }
    }

    .switch-toggle {
      .container {
        padding: 0px;
        margin: 0 19px;
        .react-switch-label {
          width: 32px;
          height: 22px;
          .react-switch-button {
            font-size: 11px;
            width: 16px;
            height: 16px;
            padding: 0px;
          }
        }
      }
    }

    .form-check [type="checkbox"] {
      width: 18px;
      height: 18px;
      margin: 0px 10px 0px 0px;
      position: relative;
    }

    .dropdown-toggle::after {
      content: none;
    }
  }
`;

export default CheckBoxWrapper;
