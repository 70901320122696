import styled from "styled-components";
import {
  archiaBoldFamily,
  archiaRegular,
  bogotaBlue100,
  brightTurquoise000,
  brightTurquoise100,
  brightTurquoise600,
  electricPurple500,
  electricPurple800,
  muliBoldFamily,
  nightBlue000,
  scarpaGray000,
  scarpaGray100,
  scarpaGray200,
  scarpaGray300,
  scarpaGray400,
  scarpaGray500,
  scarpaGray600,
  skyBlue100,
  tomatoCoral500,
} from "../../StylesConstants";
import { withStyles } from "@material-ui/core/styles";
import {
  InputAdornment,
  InputBase,
  SvgIcon,
  Table,
  TableCell,
  TableRow,
} from "@material-ui/core";

export const StandarizationWrapper = styled.section`
  width: 100%;
  display: grid;
  grid-template-areas:
    "head"
    "filter"
    "content-table";
  grid-template-columns: repeat(8, 1fr);
  row-gap: 16px;

  .header {
    grid-area: head;
    grid-column: 1/9;
    height: 60px;
  }

  .filters {
    grid-area: filter;
    grid-column: 4/6;
    height: 40px;
    display: grid;
    row-gap: 10px;
    padding: 0;
    margin: 0 auto;
  }

  .content-table {
    grid-column: 1/9;
  }
`;

export const StandarizationListingWrapper = styled.div`
  font-family: ${archiaRegular};
  thead {
    background-color: ${scarpaGray100};
    font-weight: bold;
    font-size: 13px;
    color: ${scarpaGray500};
    th {
      vertical-align: middle;
      padding: 16px;
      &.dateCreation {
        width: 170px;
      }
      .icon-down-arrow {
        color: ${electricPurple500};
        font-size: 20px;
      }
    }
  }

  tbody {
    font-size: 15px;
    color: ${scarpaGray600};
    tr {
      height: 64px;
      &:hover {
        background-color: ${bogotaBlue100};
      }
      th {
        font-weight: normal;
        vertical-align: middle;
        padding: 0 16px;
      }
    }
  }
`;

export const StandarizationCardsWrapper = styled.div`
  .column-cards {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 12px 0;

    .mini-card {
      width: 48px;
      height: 48px;
      margin-right: 12px;
      background: ${skyBlue100};
      border-radius: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .mini-card-body {
        margin: 0;
        color: ${scarpaGray600};
      }

      .aconst-title {
        padding-bottom: 8px;
        font-size: 15px;
        line-height: 24px;
      }

      .count-body {
        font-weight: 700;
        font-size: 15px;
        line-height: 24px;
      }
    }
  }
`;

export const StandarizationEditWrapper = styled.div`
  font-family: ${muliBoldFamily};
  .title {
    font-weight: 600;
    color: ${electricPurple800};
    outline: 0;
    text-transform: none;
    font-family: ${archiaRegular};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 24px;
    border-bottom: 2px solid ${tomatoCoral500};
    padding: 16px;
    width: 240px;
    margin: 0 auto 16px;
  }
  .container {
    .btns {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: `${nightBlue000}`,
    color: `${scarpaGray600}`,
    fontFamily: `${archiaBoldFamily}`,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

export const StyledTableRow = withStyles(() => ({
  root: {
    "&:hover": {
      backgroundColor: `${brightTurquoise100}`,
    },
  },
}))(TableRow);

export const StyledTable = withStyles(() => ({
  root: {
    minWidth: 700,
  },
}))(Table);

export const StyledInput = withStyles(() => ({
  root: {
    backgroundColor: `${scarpaGray000}`,
    border: `2px solid ${scarpaGray200}`,
    borderRadius: "8px",
    padding: "0 8px",
    fontSize: "16px",
    color: `${scarpaGray600}`,
    width: "40%",
    height: "44px",
    textAlign: "start",

    "&:hover": {
      backgroundColor: `${brightTurquoise000}`,
      border: `2px solid ${brightTurquoise600}`,
    },

    "&:focus": {
      backgroundColor: `${brightTurquoise000}`,
      boxShadow: "none",
      border: `2px solid ${brightTurquoise600}`,
    },
  },
  input: {
    "&[type=number]": {
      "-moz-appearance": "textfield",
    },
    "&::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    underline: {
      "&:before": {
        content: "none",
      },
      "&:after": {
        content: "none",
      },
    },
  },
  error: {
    border: `2px solid ${tomatoCoral500}`,
  },
}))(InputBase);

export const StyledAdornment = withStyles(() => ({
  root: {
    color: `${scarpaGray400}`,
  },
}))(InputAdornment);

export const StyledSVGIcon = withStyles(() => ({
  root: {
    width: "20px",
    height: "20px",
  },
}))(SvgIcon);

export const ButtonEdit = styled.div`
  cursor: pointer;
  &:hover {
    color: ${scarpaGray300};
  }
`;

export const ErrorMessage = styled.p`
  font-size: 10px;
  color: ${tomatoCoral500};
  font-family: ${archiaRegular};
  margin: 0;
`;
