import React, { useEffect, useState } from "react";
import { connect,useDispatch }        from "react-redux";
import {
  ErrorMessage,
  StandarizationEditWrapper,
  StyledAdornment,
  StyledInput,
  StyledSVGIcon,
  StyledTable,
  StyledTableCell,
  StyledTableRow,
} from "./style";
import {
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import {
  createRowForMutation,
  getKeyByValue,
  headersComponent,
  renderNames,
  savedNames,
  typeInput,
} from "./Standarization.utils";
import Button                       from "../ButtonV2/Button";
import AttachMoneyRoundedIcon       from "@material-ui/icons/AttachMoneyRounded";
import CancelRoundedIcon            from "@material-ui/icons/CancelRounded";
import DialpadRoundedIcon           from "@material-ui/icons/DialpadRounded";
import FormatQuoteRoundedIcon       from "@material-ui/icons/FormatQuoteRounded";
import SaveRoundedIcon              from "@material-ui/icons/SaveRounded";
import { setStandarizationAction }  from "../../redux/actions/standarizationData.actions";
import { saveStandardization }      from "../../queries/queries";
import { useMutation }              from "@apollo/client";

const StandarizationEditForm = (props) => {


  const { standarization, setStandarization, cancel, email, refetch } = props;
  const dispatch              = useDispatch();
  const [errors, setErrors]   = useState({});
  const [saveStandarizeData]  = useMutation(saveStandardization);
  

  const handleChange = async (event) => {
    setStandarization({ [event.target.name]: event.target.value });
  };

  const handleSubmit = async () => {
    let obj = errors;
    Object.keys(errors).forEach((key) => {
      if (!standarization[key]) {
        obj = { ...obj, [key]: true };
      } else {
        obj = { ...obj, [key]: false };
      }
    });
    setErrors(obj);
    const couldContinue = Object.keys(obj).every((k) => !obj[k]);
    if (couldContinue) {
      const objToSave = createRowForMutation(standarization, email);
      dispatch(setStandarizationAction(objToSave));
      saveStandarizeData({ variables: { ...objToSave } })
        .then((res) => {
          if (res.data.saveStandardization.statusCode) {
            cancel();
            refetch();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  useEffect(() => {
    let err = {};
    standarization.customName.forEach((name) => {
      err = { ...err, [getKeyByValue(savedNames, name)]: false };
    });
    setErrors(err);
  }, [standarization.customName]);

  return (
    <StandarizationEditWrapper>
      <h2 className="title">NID: {standarization.nid}</h2>
      <div className="container">
        <TableContainer component={Paper}>
          <StyledTable aria-label="customized table">
            <TableHead>
              <TableRow>
                {headersComponent.map((header, idx) => (
                  <StyledTableCell align="center" key={`${idx}-head-cell`}>
                    {header}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {standarization.customName.map((val, idx) => (
                <StyledTableRow key={`${getKeyByValue(savedNames, val)}-row`}>
                  <StyledTableCell align="center" component="th" scope="row">
                    {val}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {val !== "valor m2"
                      ? standarization[getKeyByValue(renderNames, val)]
                      : "$ " +
                        new Intl.NumberFormat().format(
                          standarization[getKeyByValue(renderNames, val)] ?? 0
                        )}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <StyledInput
                      required={true}
                      type={
                        typeInput[getKeyByValue(savedNames, val)] === "Str"
                          ? "text"
                          : "number"
                      }
                      key={`${getKeyByValue(savedNames, val)}-input`}
                      value={
                        standarization[getKeyByValue(savedNames, val)] ?? ""
                      }
                      name={getKeyByValue(savedNames, val)}
                      onChange={(event) => {
                        handleChange(event);
                      }}
                      error={errors[getKeyByValue(savedNames, val)]}
                      startAdornment={
                        <StyledAdornment position="start">
                          {typeInput[getKeyByValue(savedNames, val)] ===
                          "Str" ? (
                            <StyledSVGIcon component={FormatQuoteRoundedIcon} />
                          ) : typeInput[getKeyByValue(savedNames, val)] ===
                            "Currency" ? (
                            <StyledSVGIcon component={AttachMoneyRoundedIcon} />
                          ) : (
                            <StyledSVGIcon component={DialpadRoundedIcon} />
                          )}
                        </StyledAdornment>
                      }
                    />
                    {errors[getKeyByValue(savedNames, val)] && (
                      <ErrorMessage>*Campo requerido</ErrorMessage>
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </StyledTable>
        </TableContainer>
        <div className="btns">
          <Button
            size="medium"
            style={{ margin: "16px" }}
            svgIcon={<SaveRoundedIcon />}
            label="Guardar"
            onClick={() => handleSubmit()}
          />
          <Button
            size="medium"
            buttonType="secondary"
            style={{ margin: "16px" }}
            svgIcon={<CancelRoundedIcon />}
            label="Cancelar"
            onClick={() => cancel()}
          />
        </div>
      </div>
    </StandarizationEditWrapper>
  );
};

const mapStateToProps = (state) => ({
  standarization: state.standarization,
  email: state.userData.email,
});

const mapDispatchToProps = (dispatch) => ({
  setStandarization: (row) => dispatch(setStandarizationAction(row)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StandarizationEditForm);
