import styled from "styled-components";
import {
  hintPerano100,
  electricPurple600,
  archiaBoldFamily,
} from "../../StylesConstants";

export const LoadPhotosWrapper = styled.div`

  input[type="file"] {
    opacity: 0;
    width: 140px;
    position: absolute;
  }
  .upload-button {
    display: grid;
    grid-template: 1fr/41px 113px;
    align-items: center;
    justify-items: center;
    background-color: ${hintPerano100};
    width: 162px;
    height: 40px;
    margin-right: 50px;
    border-radius: 100px;
    color: ${electricPurple600};
    font-size: 16px;
    font-family: ${archiaBoldFamily};
  }
`;
