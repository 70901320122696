import React, { useEffect, useState } from "react";
import { InputWrapper } from "./style";

// Radio input
const RadioButton = ({
  field: { name, value, onChange, onBlur },
  id,
  label,
  ...props
}) => {
  const [selected, setSelected] = useState(props.default);

  useEffect(() => {
    if (props.values[name] !== undefined) {
      setSelected(props.values[name]);
      if (
        props.values[name] !== null &&
        props.values[name].toString() === props.value.toString()
      ) {
        setSelected(true);
      } else {
        setSelected(false);
      }
    }
    // eslint-disable-next-line
  }, [props.values[name]]);

  return (
    <InputWrapper
      className={`${props.error && props.touched ? "error-radio" : ""} `}
    >
      <label className="radio-name" htmlFor={`${id}${name}`}>
        <input
          name={name}
          id={`${id}${name}`}
          type="radio"
          value={props.value}
          checked={selected}
          onChange={() => props.setFieldValue(name, props.value)}
          onBlur={onBlur}
          {...props}
        />
        {label}
        <div className="check"/>
      </label>
    </InputWrapper>
  );
};
export default RadioButton;
