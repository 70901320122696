import React, { useState } from "react";
import { InputWrapper } from "./style";
import { ErrorMessage } from "formik";
import NumberFormat from "react-number-format";

const CurrencyInput = ({
  name,
  errors,
  label,
  className,
  touched,
  maxLength,
  ...props
}) => {
  const [state] = useState({ focus: false, label: false });
  const [valueCurrency, setValueCurrency] = useState("");

  return (
    <InputWrapper>
      <div className={`form-group ${className}`}>
        <label
          htmlFor={name}
          className={`form-control  ${
            errors[name] && touched[name] ? " is-invalid" : ""
          } ${props.disabled ? " disabled" : ""} ${
            state.label ? "" : " no-label"
          }`}
        >
          {state.label ? label : ""} {props.required && state.label ? "*" : ""}
          <div className="input-field">
            <NumberFormat
              className="input-number-format"
              thousandSeparator={true}
              prefix={"$"}
              maxLength={maxLength ?? 13}
              placeholder="$000,000.000"
              value={valueCurrency}
              onValueChange={(vals) => setValueCurrency(vals.floatValue)}
              {...props}
            />
          </div>
        </label>
        <ErrorMessage
          name={name}
          component="div"
          className="invalid-feedback"
        />
      </div>
    </InputWrapper>
  );
};

export default CurrencyInput;
