import styled from 'styled-components';
import { electricPurple500, scarpaGray600, scarpaGray300, size } from '../../StylesConstants';

export const InputWrapper = styled.li`
  color: #aaaaaa;
  display: block;
  width: 100%;
  height: 100px;
  max-height: 56px;
  align-items: center;

  input[type='radio'] {
    position: absolute;
    visibility: hidden;
  }

  label {
    grid-template-areas: 'radio label';
    grid-template-columns: 40px 1fr;
    display: grid;
    color: ${scarpaGray600};
    grid-area: label;
    font-weight: normal;
    height: 30px;
    cursor: pointer;
    -webkit-transition: all 0.25s linear;
    margin: 0;
    font-size: 16px;
    line-height: 28px;
  }

  &:hover label {
    color: ${scarpaGray600};
  }

  .check {
    grid-area: radio;
    border: 2px solid ${scarpaGray300};
    border-radius: 100%;
    height: 25px;
    width: 25px;
    transition: border 0.25s linear;
    -webkit-transition: border 0.25s linear;
  }

  &:hover .check {
    border: 2px solid ${electricPurple500};
  }

  .check::before {
    display: block;
    content: '';
    border-radius: 100%;
    height: 17px;
    width: 17px;
    margin: auto;
    position: relative;
    top: 2px;
    transition: background 0.25s linear;
    -webkit-transition: background 0.25s linear;
  }

  input[type='radio']:checked ~ .check {
    border: 2px solid ${electricPurple500};
  }

  input[type='radio']:checked ~ .check::before {
    background: ${electricPurple500};
  }

  input[type='radio']:checked ~ label {
    color: ${scarpaGray600};
  }

  @media screen and (min-width: ${size.tablet}) {
  }
  @media screen and (min-width: ${size.desktop}) {
    label {
      color: ${scarpaGray600};
      grid-area: label;
      font-weight: 300;
      font-size: 1.35em;
      height: 30px;
      cursor: pointer;
      -webkit-transition: all 0.25s linear;
      margin: 0;
    }
  }
`;

export const RadioWrapper = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: auto;

  .switch-toggle {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;
