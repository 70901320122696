import React, { useEffect, useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "./tabPanel";
import { TabsContainerWrapper } from "./style";
import HomeList from "../HomeList/homeList";
import { useSelector } from "react-redux";
import SimulatorHome from "../SimulatorHome/SimulatorHome";

const scrollableProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
};

const TabsContainer = (data) => {
  const [value, setValue] = useState(0);
  const [flagTab, setFlagTab] = useState(false);
  const { userData } = useSelector((state) => state);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (value === 4) {
      setFlagTab(true);
    } else {
      setFlagTab(false);
    }
  }, [value, flagTab]);

  return (
    <TabsContainerWrapper className="tabs-inside-container">
      {userData.role !== "ventas" && userData.role !== "analytics" && (
        <>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Pricing" {...scrollableProps(0)} />
            <Tab label="Repricing" {...scrollableProps(1)} />
            <Tab label="Reactivado Pricing" {...scrollableProps(2)} />
            <Tab label="Gestión sin Pricing" {...scrollableProps(3)} />
            <Tab label="Pricing Final" {...scrollableProps(4)} />
            <Tab label="% de Descuento" {...scrollableProps(5)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <HomeList type={"pricing"} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <HomeList type={"repricing"} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <HomeList type={"reactivado"} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <HomeList type={"gestionsinpricing"} />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <HomeList type={"endpricing"} flagTab={flagTab} />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <HomeList type={"discount"} />
          </TabPanel>
        </>
      )}
      {userData.role === "analytics" && (
        <>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Buckets" {...scrollableProps(0)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <HomeList type={"buckets"} />
          </TabPanel>
        </>
      )}
      {userData.role === "ventas" && (
        <>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="simulator tab"
          >
            <Tab label="Simulador" {...scrollableProps(0)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <SimulatorHome />
          </TabPanel>
        </>
      )}
    </TabsContainerWrapper>
  );
};

export default TabsContainer;
