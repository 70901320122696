export const headers = ["NID", "Ajustes de normalización", "Editar"];

export const headersComponent = ["Propiedad", "Valor original", "Nuevo valor"];

export const possibleNames = {
  area_original: "ar",
  garaje_original: "pq",
  bano_original: "bn",
  piso_original: "ps",
  ascensor_original: "as",
  antiguedad_original: "an",
  valor_m2_original: "v2",
  tipo_inmueble_id_original: "ti",
  nombre_conjunto_original: "nc",
};

export const renderNames = {
  area_original: "area",
  garaje_original: "garajes",
  bano_original: "baño",
  piso_original: "piso",
  ascensor_original: "ascensores",
  antiguedad_original: "años de antigüedad",
  valor_m2_original: "valor m2",
  tipo_inmueble_id_original: "tipo inmueble",
  nombre_conjunto_original: "nombre del conjunto",
};

export const savedNames = {
  area_normalizado: "area",
  garaje_normalizado: "garajes",
  bano_normalizado: "baño",
  piso_normalizado: "piso",
  ascensor_normalizado: "ascensores",
  antiguedad_normalizado: "años de antigüedad",
  valor_m2_normalizado: "valor m2",
  tipo_inmueble_id_normalizado: "tipo inmueble",
  nombre_conjunto_normalizado: "nombre del conjunto",
};

export const typeInput = {
  area_normalizado: "Int",
  garaje_normalizado: "Int",
  bano_normalizado: "Int",
  piso_normalizado: "Int",
  ascensor_normalizado: "Int",
  antiguedad_normalizado: "Int",
  valor_m2_normalizado: "Currency",
  tipo_inmueble_id_normalizado: "Int",
  nombre_conjunto_normalizado: "Str",
};

export const getCustomNames = (obj, names) => {
  let custom_names = [];
  const arrNames = Object.keys(obj);
  arrNames.forEach((element) => {
    if (Object.keys(names).includes(element) && obj[element] !== null)
      custom_names = [...custom_names, names[element]];
  });
  return custom_names;
};

export const getKeyByValue = (obj, val) => {
  return Object.keys(obj).find((key) => obj[key] === val);
};

export const createRowForMutation = (data, agent) => {
  return {
    id: data.id,
    inmueble_id: data.inmueble_id,
    nid: data.nid,
    area_normalizado: data.area_normalizado ? +data.area_normalizado : null,
    garaje_normalizado: data.garaje_normalizado
      ? +data.garaje_normalizado
      : null,
    bano_normalizado: data.bano_normalizado ? +data.bano_normalizado : null,
    piso_normalizado: data.piso_normalizado ? +data.piso_normalizado : null,
    ascensor_normalizado: data.ascensor_normalizado
      ? +data.ascensor_normalizado
      : null,
    antiguedad_normalizado: data.antiguedad_normalizado
      ? +data.antiguedad_normalizado
      : null,
    valor_m2_normalizado: data.valor_m2_normalizado
      ? +data.valor_m2_normalizado
      : null,
    tipo_inmueble_id_normalizado: data.tipo_inmueble_id_normalizado
      ? +data.tipo_inmueble_id_normalizado
      : null,
    nombre_conjunto_normalizado: data.nombre_conjunto_normalizado
      ? data.nombre_conjunto_normalizado
      : null,
    agente_actualizacion: agent,
    activo: 2,
  };
};
