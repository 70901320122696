import React from "react";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableCell from "@material-ui/core/TableCell";

/**
 * Material-UI Based table head to sort a table.
 * 
 * @param {any[]} headerCells - An array of table header data objects to used to create the headers.
 * @param {string} keyPropName - The key for the prop in the headerCells array to be used as a key for the cells.
 * @param {string} sortPropName - The key for the prop in the headerCells array to be used as a sorting value.
 * @param {string} labelPropName - The key for the prop in the headerCells array to be used as the label in the header.
 * @param {string} orderBy - The key for the prop used to sort the values from the table.
 * @param {string} order - "asc" | "desc" Determines the order for the values to be sorted by, ascending, or descending.
 * @param {Function} handleSort - The function to handle orderBy and order to sort the table.
 *
 * @example
 *
 *  const headerCells = [{
      value: "area",
      label: "Area",
    }, {...}]

 *  <SortingTableHeader
      headerCells={tableHeadCells}
      keyPropName="label"
      sortPropName="value"
      labelPropName="label"
      orderBy={orderBy}
      order={order}
      handleSort={handleRequestSort}
      bold
    />
 */
const SortingTableHeader = (props) => {
  const {
    headerCells,
    keyPropName,
    sortPropName,
    labelPropName,
    handleSort,
    orderBy,
    order,
    bold,
  } = props;

  return (
    <TableHead>
      <TableRow>
        {headerCells.map((cell) => (
          <TableCell
            key={cell[keyPropName]}
            onClick={() => handleSort(cell[sortPropName])}
            style={{ cursor: "pointer" }}
          >
            <TableSortLabel
              active={orderBy === cell[sortPropName]}
              direction={orderBy === cell[sortPropName] ? order : "asc"}
            >
              {bold ? (
                <strong>{cell[labelPropName]}</strong>
              ) : (
                cell[labelPropName]
              )}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default SortingTableHeader;
