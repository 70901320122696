import { propertiesCountTypes } from "../actions/propertiesCount.actions";

const INITIAL_STATE = { count: 0 };

const propertiesCountReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case propertiesCountTypes.SET_COUNT:
      return payload;
    case propertiesCountTypes.REMOVE_COUNT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default propertiesCountReducer;
