import styled from "styled-components";
import { muliBoldFamily, scarpaGray700 } from "../../StylesConstants";

export const SimulatorHomeWrapper = styled.div`
  display: grid;
  grid-template-areas:
    "title"
    "filter"
    "table";
  .title {
    grid-area: "title";
    font-family: ${muliBoldFamily};
    font-size: 24px;
    display: flex;
    align-items: baseline;
    color: ${scarpaGray700};
    margin-bottom: 0;
  }
  .search {
    grid-area: "filter";
    margin: 16px auto 24px;
  }
`;
