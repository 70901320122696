import React, { useEffect, useState } from "react";
import { OverlayWrapper } from "./style";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";

const GenericOverlay = (props) => {
  const { openLeftModal, closeLeftModal, dispatch } = props;
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(openLeftModal);
  }, [openLeftModal]);

  const closeButton = () => {
    setIsOpen(false);
    if (closeLeftModal) closeLeftModal(false);
    if (dispatch) dispatch();
  };

  return (
    <OverlayWrapper>
      <div
        className={`overlay  overlay-open ${
          isOpen ? "overlay-open" : "overlay-close"
        }`}
      >
        <div className="header">
          <div className="close-overlay closebtn" onClick={() => closeButton()}>
            <CancelRoundedIcon />
          </div>
        </div>
        <div className="overlay-content">{props.children}</div>
      </div>
    </OverlayWrapper>
  );
};

export default GenericOverlay;
