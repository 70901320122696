import React from "react";
import { useSelector } from "react-redux";
import { selectHasApartment } from "../../redux/selectors/apartment.selectors";
import { selectPolygonSelected } from "../../redux/selectors/mapPolygon.selectors";
import ComparablesMapPage from "../ComparablesMap/ComparablesMapPage";
import Header from "../../components/Header/header";
import TabsContainer from "../../components/Tabs/tabsContainer";

const Simulator = () => {
  const hasApartment = useSelector((state) => selectHasApartment(state));
  const polygonSelected = useSelector((state) => selectPolygonSelected(state));

  return (
    <>
      <Header className="header" />
      <TabsContainer />
      {hasApartment && !polygonSelected && <ComparablesMapPage />}
    </>
  );
};

export default Simulator;
