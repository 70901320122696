export const headerList = [
  "Fecha de Creación",
  "NID",
  "Conjunto",
  "Fuente",
  "Tipo Inmueble",
  "Dirección",
  "Last_ask_price",
  " ",
];
