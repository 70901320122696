import { createSelector } from "reselect";

const selectMapPolygon = (state) => state.mapPolygon;

export const selectPolygonPoints = createSelector(
  [selectMapPolygon],
  (mapPolygon) => mapPolygon.polygon
);

export const selectPolygonSelected = createSelector(
  [selectMapPolygon],
  (mapPolygon) => mapPolygon.selected
);

export const selectHasValidPolygon = createSelector(
  [selectPolygonPoints],
  (polygon) => polygon.length >= 3
);
