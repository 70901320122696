import styled from 'styled-components';
import { size, nightBlue500 } from '../../StylesConstants';

export const NotificationContainer = styled.div`
  background: ${({ backgroundColor }) => backgroundColor};
  border: ${({ notificationBorder }) => `2px solid ${notificationBorder}`};
  border-radius: 8px;
  padding: 16px;
  text-align: start;

  h3 {
    font-family: 'Muli ExtraBold';
    font-size: 18px;
    line-height: 24px;
    color: ${({ titleColor }) => titleColor};
    margin-top: 8px;
    margin-bottom: 4px;

    @media (min-width: ${size.desktop}) {
      font-size: 16px;
    }
  }

  p {
    font-family: 'Open Sans';
    font-size: 13px;
    line-height: 24px;
    color: ${nightBlue500};
    margin-bottom: 0;
  }

  strong {
    font-family: 'Open Sans Bold';
  }

  @media (min-width: ${size.desktop}) {
    display: grid;
    grid-template-columns: 48px auto;
    grid-template-areas: 'sidebar main';
    padding: 24px;

    .sidebar {
      grid-area: sidebar;
    }

    .main {
      grid-area: main;

      h3 {
        margin-top: 0px;
      }
    }
  }
`;
