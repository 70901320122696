import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ListTableWrapper from "./style";
import Button from "../Button/button";
import AttachMoneyRoundedIcon from "@material-ui/icons/AttachMoneyRounded";
import ArrowDownwardRoundedIcon from "@material-ui/icons/ArrowDownwardRounded";
import { MonthYearFormat, HourFormat } from "../../utils/dateFormat";
import Comparable from "../Comparable/comparable";
import Overlay from "../overlay/overlay";
import { useQuery } from "@apollo/client";
import DotsLoading from "../DotsLoading/dotsLoading";
import HabiPagination from "../HabiPagination/habiPagination";
import {
  getPricingTable,
  getRePricingTable,
  getReactivadoPricingTable,
  getPricingImpossibleInfoTable,
  getPricingNID,
  getPricingFuente,
} from "../../queries/queries";
import ApolloClient from "apollo-boost";
import { setApartmentAction } from "../../redux/actions/apartment.actions";
import { resetPricingDataAction } from "../../redux/actions/pricingData.actions";
import { clearManualRowDataAction } from "../../redux/actions/manualRowData.actions";
import { setPropertiesCountAction } from "../../redux/actions/propertiesCount.actions";

const url =
  process.env.REACT_APP_FORM_API_URL_V2 +
  process.env.REACT_APP_ENDPOINT_GRAPHQL_BASE_PATH +
  process.env.REACT_APP_GRAPHQL_ENDPOINT;

const client = new ApolloClient({
  uri: url,
  // uri: "http://localhost:4000/graphql",
  headers: {
    "x-api-key": process.env.REACT_APP_GRAPHQL_API_KEY,
  },
});

const ListTable = (props) => {
  const {
    headers,
    fuente,
    prevFuente,
    city,
    prevCity,
    inputVal,
    refresh,
    setRefresh,
    type,
    selectedDayRange,
    flagTab,
    setApartment,
    resetPricingData,
    clearManualRowData,
    setCount,
    zoneBig,
    zoneMedian,
  } = props;

  const [pageData, setPageData] = useState(1);
  const ITEMS_BY_PAGE = 50;
  const [dataTable, setDataTable] = useState([]);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const handlePageChange = (newPage) => {
    setPageData(newPage);
  };
  const [paginator, setPaginator] = useState({
    currentPage: pageData,
    max_pages: 0,
    totalItemsCount: 0,
  });

  useEffect(() => {
    if (city !== prevCity) {
      setPageData(1);
      setPaginator({
        currentPage: pageData,
        itemsCountPerPage: ITEMS_BY_PAGE,
        totalItemsCount: 0,
      });
    }
    if (fuente !== prevFuente) {
      setPageData(1);
      setPaginator({
        currentPage: pageData,
        itemsCountPerPage: ITEMS_BY_PAGE,
        totalItemsCount: 0,
      });
    }
  }, [city, prevCity, fuente, prevFuente, pageData]);

  useEffect(() => {
    if (
      props.type !== "discount" &&
      selectedDayRange.from &&
      selectedDayRange.to
    ) {
      setDateFrom(
        `${selectedDayRange.from.year}-${selectedDayRange.from.month}-${selectedDayRange.from.day}`
      );
      setDateTo(
        `${selectedDayRange.to.year}-${selectedDayRange.to.month}-${selectedDayRange.to.day}`
      );
    }
  }, [selectedDayRange, props.type]);

  const { loading, data, refetch } = useQuery(
    props.type === "pricing" && fuente.includes("Todas")
      ? getPricingTable(
          pageData,
          ITEMS_BY_PAGE,
          city,
          dateFrom,
          dateTo,
          zoneBig,
          zoneMedian
        )
      : props.type === "repricing" && fuente.includes("Todas")
      ? getRePricingTable(
          pageData,
          ITEMS_BY_PAGE,
          city,
          dateFrom,
          dateTo,
          zoneBig,
          zoneMedian
        )
      : props.type === "reactivado" && fuente.includes("Todas")
      ? getReactivadoPricingTable(
          pageData,
          ITEMS_BY_PAGE,
          city,
          dateFrom,
          dateTo,
          zoneBig,
          zoneMedian
        )
      : props.type === "gestionsinpricing" && fuente.includes("Todas")
      ? getPricingImpossibleInfoTable(
          pageData,
          ITEMS_BY_PAGE,
          city,
          dateFrom,
          dateTo,
          zoneBig,
          zoneMedian
        )
      : props.type === "endpricing" && fuente.includes("Todas")
      ? getRePricingTable(
          pageData,
          ITEMS_BY_PAGE,
          city,
          dateFrom,
          dateTo,
          zoneBig,
          zoneMedian
        )
      : getPricingFuente(
          props.type !== "endpricing" ? props.type : "repricing",
          fuente,
          pageData,
          ITEMS_BY_PAGE,
          city,
          dateFrom,
          dateTo,
          zoneBig,
          zoneMedian
        )
  );

  useEffect(() => {
    if (!refresh) return;
    refetch();
    setRefresh(false);
  }, [refresh, setRefresh, refetch]);

  let originalData =
    props.type === "pricing" && fuente.includes("Todas")
      ? data?.getPricingInfo?.result
      : props.type === "repricing" && fuente.includes("Todas")
      ? data?.getRePricingInfo?.result
      : props.type === "reactivado" && fuente.includes("Todas")
      ? data?.getReactivadoPricingInfo?.result
      : props.type === "gestionsinpricing" && fuente.includes("Todas")
      ? data?.getPricingImpossibleInfo?.result
      : props.type === "endpricing" && fuente.includes("Todas")
      ? data?.getRePricingInfo?.result
      : props.type === "discount"
      ? data?.getListTaskEngine?.result
      : data?.getPricingInfoByFuente?.result || data;

  useEffect(() => {
    if (typeof data !== "undefined") {
      setDataTable(originalData);
      if (props.type === "pricing" && fuente.includes("Todas")) {
        setCount({ count: data?.getPricingInfo?.count });
        setPaginator({
          currentPage: pageData,
          itemsCountPerPage: ITEMS_BY_PAGE,
          totalItemsCount: data?.getPricingInfo?.count,
        });
      } else if (props.type === "repricing" && fuente.includes("Todas")) {
        setCount({ count: data?.getRePricingInfo?.count });
        setPaginator({
          currentPage: pageData,
          itemsCountPerPage: ITEMS_BY_PAGE,
          totalItemsCount: data?.getRePricingInfo?.count,
        });
      } else if (props.type === "reactivado" && fuente.includes("Todas")) {
        setCount({ count: data?.getReactivadoPricingInfo?.count });
        setPaginator({
          currentPage: pageData,
          itemsCountPerPage: ITEMS_BY_PAGE,
          totalItemsCount: data?.getReactivadoPricingInfo?.count,
        });
      } else if (
        props.type === "gestionsinpricing" &&
        fuente.includes("Todas")
      ) {
        setCount({ count: data?.getPricingImpossibleInfo?.count });
        setPaginator({
          currentPage: pageData,
          itemsCountPerPage: ITEMS_BY_PAGE,
          totalItemsCount: data?.getPricingImpossibleInfo?.count,
        });
      } else if (props.type === "endpricing" && fuente.includes("Todas")) {
        setCount({ count: data?.getRePricingInfo?.count });
        setPaginator({
          currentPage: pageData,
          itemsCountPerPage: ITEMS_BY_PAGE,
          totalItemsCount: data?.getRePricingInfo?.count,
        });
      } else if (props.type === "discount") {
        setCount({ count: data?.getListTaskEngine?.count });
        setPaginator({
          currentPage: pageData,
          itemsCountPerPage: ITEMS_BY_PAGE,
          totalItemsCount: data?.getListTaskEngine?.count,
        });
      } else {
        setCount({ count: data?.getPricingInfoByFuente?.count });
        setPaginator({
          currentPage: pageData,
          itemsCountPerPage: ITEMS_BY_PAGE,
          totalItemsCount: data?.getPricingInfoByFuente?.count,
        });
      }
    }
  }, [data, pageData, fuente, originalData, props.type, setCount]);

  useEffect(() => {
    if (props.type !== "discount" && props.type !== "buckets") {
      const searchNid = async () => {
        if (inputVal.length >= 10) {
          const { data } = await client.query({
            query: getPricingNID,
            variables: {
              nid: +inputVal,
              type: type !== "endpricing" ? type : "repricing",
            },
          });
          setDataTable(data?.getPricingInfoByNID?.result || []);
          setCount({ count: data?.getPricingInfoByNID?.count });
          setPaginator({
            currentPage: pageData,
            itemsCountPerPage: ITEMS_BY_PAGE,
            totalItemsCount: data?.getPricingInfoByNID?.count,
          });
        } else {
          if (props.type === "pricing" && fuente.includes("Todas")) {
            setDataTable(data?.getPricingInfo?.result || []);
          } else if (props.type === "repricing" && fuente.includes("Todas")) {
            setDataTable(data?.getRePricingInfo?.result || []);
          } else if (props.type === "reactivado" && fuente.includes("Todas")) {
            setDataTable(data?.getReactivadoPricingInfo?.result || []);
          } else if (
            props.type === "gestionsinpricing" &&
            fuente.includes("Todas")
          ) {
            setDataTable(data?.getPricingImpossibleInfo?.result || []);
          } else {
            if (props.type === "pricing" && fuente.includes("Todas")) {
              setDataTable(data?.getPricingInfo?.result || []);
            } else if (props.type === "repricing" && fuente.includes("Todas")) {
              setDataTable(data?.getRePricingInfo?.result || []);
            } else if (
              props.type === "reactivado" &&
              fuente.includes("Todas")
            ) {
              setDataTable(data?.getReactivadoPricingInfo?.result || []);
            } else if (
              props.type === "gestionsinpricing" &&
              fuente.includes("Todas")
            ) {
              setDataTable(data?.getPricingImpossibleInfo?.result || []);
            } else if (
              props.type === "endpricing" &&
              fuente.includes("Todas")
            ) {
              setDataTable(data?.getRePricingInfo?.result || []);
            } else if (props.type === "discount") {
              setDataTable(data?.getListTaskEngine?.result || []);
            } else {
              setDataTable(data?.getPricingInfoByFuente?.result || []);
            }
          }
        }
      };
      searchNid();
    }
  }, [inputVal, data, fuente, pageData, props.type, type, setCount]);

  if (loading) return <DotsLoading />;

  return (
    <>
      <ListTableWrapper>
        <div className="table-container">
          <table className="table">
            <thead>
              <tr>
                {headers.map((item, index) => {
                  if (item === "Fecha de Creación") {
                    return (
                      <th className="dateCreation" key={index}>
                        {item}
                        <ArrowDownwardRoundedIcon className="icon-down-arrow" />
                      </th>
                    );
                  }
                  return <th key={index}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {dataTable?.map((apartment) => (
                <tr key={apartment.inmuebleID}>
                  <th>
                    {MonthYearFormat(apartment.fechaCreacion)}
                    <br />
                    <small>{HourFormat(apartment.fechaCreacion)}</small>
                  </th>
                  <th>{Number(apartment.nid)}</th>
                  <th>{apartment.proyecto}</th>
                  <th>{apartment.fuente}</th>
                  <th>{apartment.labelInmueble}</th>
                  <th>{apartment.direccion}</th>
                  <th>
                    {apartment.lastAskPrice !== null
                      ? "$ " +
                        new Intl.NumberFormat().format(apartment.lastAskPrice)
                      : ""}
                  </th>
                  <th>
                    <Button
                      id="button"
                      typeClass="primary"
                      className="d-inline-block"
                      label="Price"
                      type="button"
                      onClick={() => {
                        setApartment(apartment);
                        resetPricingData();
                        clearManualRowData();
                      }}
                    >
                      <AttachMoneyRoundedIcon className="dollar-icon" />
                    </Button>
                  </th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {paginator?.totalItemsCount > paginator?.itemsCountPerPage && (
          <div className="paginator-container">
            <HabiPagination
              className="paginator"
              handlePageChange={handlePageChange}
              paginator={paginator}
            />
          </div>
        )}
      </ListTableWrapper>
      <Overlay>
        <Comparable
          flagTab={flagTab}
          setRefresh={setRefresh}
          type={props.type}
        />
      </Overlay>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setApartment: (apartment) => dispatch(setApartmentAction(apartment)),
  resetPricingData: () => dispatch(resetPricingDataAction()),
  clearManualRowData: () => dispatch(clearManualRowDataAction()),
  setCount: (count) => dispatch(setPropertiesCountAction(count)),
});

export default connect(null, mapDispatchToProps)(ListTable);
