import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useQuery } from "@apollo/client";
import { getTypology } from "../../queries/queries";
import { selectApartment } from "../../redux/selectors/apartment.selectors";
import TypologyList from "./typologyList";

const TypologyListContainer = ({ apartment, ...props }) => {
  const { loteID } = apartment;
  const { loading, error, data } = useQuery(getTypology(loteID));
  const [dataTypology, setDataTypology] = useState([]);

  useEffect(() => {
    if (!data) return;
    setDataTypology(data?.getTypology || []);
  }, [data]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <Fragment>
      <TypologyList dataTypology={dataTypology} />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  apartment: selectApartment(state),
});

export default connect(mapStateToProps)(TypologyListContainer);
