import styled from "styled-components";
import { white } from "../../StylesConstants";

export const ModalContainer = styled.div`
  position: fixed;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  z-index: 350;
  background-color: ${white};
  transform: translate(-50%, -50%);
  border-radius: 8px;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 300;
`;
