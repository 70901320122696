import React, { Fragment, useEffect, useState } from "react";
import { LoginWrapper } from "./style";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { updateUserDataAction } from "../../redux/actions/userData.actions";
import logo from "../../static/assets/logo/habi.svg";
import GoogleLogin from "react-google-login";
import { selectUserData } from "../../redux/selectors/userData.selectors";
import { availableRoles } from "./login.utils";
import PortraitIcon from "@material-ui/icons/Portrait";
import loginDecorationStart from "../../static/assets/img/login/login-decoration-start.svg";
import loginDecorationEnd from "../../static/assets/img/login/login-decoration-end.svg";

const LogIn = (props) => {
  const { updateUserData } = props;
  const [chooseRole, setChooseRole] = useState(true);
  const [roleName, setRoleName] = useState("");

  const handleClick = (role) => {
    setChooseRole(false);
    setRoleName(role);
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const loginParams = JSON.parse(params.get("data"));
    const nid = params.get("nid");
    if (
      loginParams &&
      loginParams.email &&
      loginParams.authenticated &&
      loginParams.role === "ventas"
    ) {
      updateUserData({ ...loginParams, nid: +nid });
      props.setAuth(loginParams.authenticated);
      props.history.push("/simulador");
    }
  }, [updateUserData, props]);

  const onRejectLogin = (response) => {
    props.history.push("/");
  };

  const onSuccessLogin = (response) => {
    const profile = response.profileObj;
    const habiDomains = ["habi.co", "tuhabi.mx"];

    if (profile.googleId) {
      //Check email domain
      const domain = profile.email.split("@")[1];
      let external = 1;
      if (habiDomains.find((item) => item === domain)) {
        external = 0
      }

      const data = {
        userId: profile.googleId,
        external: external,
      };
      const apikey = process.env.REACT_APP_HABI_API_KEY;
      const rootUrl =
        process.env.REACT_APP_FORM_API_URL_V2 +
        process.env.REACT_APP_ENDPOINT_HABI_API_BASE_PATH;
      const endpointUrl =
        process.env.REACT_APP_ENDPOINT_HABI_API_GET_COGNITO_USER_ROLE;
      const url = rootUrl + endpointUrl;
      fetch(url, {
        method: "POST",
        headers: {
          "x-api-key": apikey,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.success && response.role.includes(roleName)) {
            updateUserData({
              role: roleName,
              email: profile.email,
              name: profile.givenName,
              lastName: profile.familyName,
              imageProfile: profile.imageUrl,
              authenticated: true,
            });
            props.setAuth(true);
            if (response.role[roleName] !== "ventas")
              props.history.push("/dashboard");
            else props.history.push("/simulador");
          } else {
            setChooseRole(true);
          }
        });
    }
  };

  return (
    <Fragment>
      <LoginWrapper chooseRole={chooseRole}>
        <div className="decoration">
          <img src={loginDecorationStart} alt="Habi decoration" />
          <img src={loginDecorationEnd} alt="Habi decoration" />
        </div>
        <div className="content">
          <div className="login-container">
            <img src={logo} className="logo-img" alt="logo-img" />
            <p className="title">Portal Pricing</p>
            {chooseRole && (
              <div className="role-buttons">
                <p className="question">¿Con cuál rol deseas ingresar?</p>
                {Object.keys(availableRoles).map((item, idx) => (
                  <div
                    key={`role-${idx}`}
                    className="role"
                    onClick={() => handleClick(availableRoles[item])}
                  >
                    <PortraitIcon />
                    <span>{item}</span>
                  </div>
                ))}
              </div>
            )}
            {!chooseRole && (
              <div className="pass-button">
                <GoogleLogin
                  clientId={process.env.REACT_APP_GOOGLE_AUTH}
                  buttonText="Iniciar Sesión"
                  onSuccess={onSuccessLogin}
                  onFailure={onRejectLogin}
                  cookiePolicy={"single_host_origin"}
                />
              </div>
            )}
          </div>
        </div>
      </LoginWrapper>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  userData: selectUserData(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateUserData: (data) => dispatch(updateUserDataAction(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LogIn));
