import React, { useEffect, useState } from "react";
import Header from "../../components/Header/header";
import { PricingWrapper } from "./style";
import { useHistory, withRouter } from "react-router";
import CommentContainer from "../../components/Comment/commentContainer";
import TablePricingManualContainer from "../../components/TablePricingManual/tablePricingManualContainer";
import CloudUploadRoundedIcon from "@material-ui/icons/CloudUploadRounded";
import { useMutation } from "@apollo/client";
import { saveCommentData, signFileData } from "../../queries/queries";
import ReactS3Uploader from "react-s3-uploader";
import { connect } from "react-redux";
import { Snackbar, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Formik, Field, Form } from "formik";
import { Toggle, ToggleGroup } from "../../components/Toggle/toogle";
import { selectUserEmail } from "../../redux/selectors/userData.selectors";
import CurrentLeadTable from "../../components/CurrentLeadTable/currentLeadTable";
import {
  selectApartment,
  selectInmuebleID,
} from "../../redux/selectors/apartment.selectors";
import Button from "../../components/Button/button";
import SendIcon from "@material-ui/icons/Send";
import { selectPricingData } from "../../redux/selectors/pricingData.selectors";
import { resetPricingDataAction } from "../../redux/actions/pricingData.actions";
import { removeApartmentAction } from "../../redux/actions/apartment.actions";
import { clearPolygonAction } from "../../redux/actions/mapPolygon.actions";

const PricingManual = (props) => {
  const [snackMessage, setSnackMessage] = useState(null);
  const [contentType, setContentType] = useState("");
  const [toggleRefetch, setToggleRefetch] = useState(false);
  const [externalSources, setExternalSources] = useState(0);

  const {
    inmuebleID,
    email,
    pricingData,
    apartment,
    resetPricingData,
    removeApartment,
    clearPolygon,
    mapPolygon,        
  } = props;

  const history = useHistory();

  const [signFile] = useMutation(signFileData);
  const [saveComment] = useMutation(saveCommentData);

  useEffect(() => {
    return () => resetPricingData();
  }, [resetPricingData]);

  const handleSignedFileUpload = async (file, callback) => {
    setContentType(file.type);
    signFile({
      variables: {
        name: file.name,
        type: file.type,
        inmuebleId: inmuebleID,
        agente: email,
      },
      update: (_, response) => {
        if (response.data.signFile === "error") {
          setSnackMessage("Hubo un error subiendo el archivo");
        } else {
          callback(response.data.signFile);
        }
      },
    });
  };

  const handleClose = (_, reason) => {
    if (reason === "clickaway") return;
    setSnackMessage(null);
  };

  const onProgress = () => console.log("onprogress");

  const onError = () => setSnackMessage("Hubo un error subiendo el archivo");

  const onFinish = () => setSnackMessage("Archivo se subio exitosamente");

  const validData =
    pricingData?.comentario?.length > 4 &&
    pricingData.ask_price / apartment.area >= 1000000;

  const saveData = async () => {
    if (!validData) return;
    saveComment({
      variables: {
        inmuebleId: inmuebleID,
        commentary: pricingData.comentario,
        url: "",
        agent: email,
        askPrice: pricingData.ask_price,
        fechaCreacion: apartment.fechaCreacion,
        nid: apartment.nid,
        prioridad: apartment.prioridad,
        responseQuestion: externalSources,
        flag: 1,
        tipoInmuebleId: apartment.tipoInmueble,
        numPiso: apartment.piso,
        areaMetropolitanaId: apartment.areaMetropolitanaId,
        finalCommentary: 0,
        originalData: JSON.stringify(apartment) || "",
        mapPolygon: JSON.stringify(mapPolygon.polygon) || "",
      },
    })
      .then(() => {
        setSnackMessage("Comentario agragado exitosamente");
        setToggleRefetch(true);
        resetPricingData();
        removeApartment();
        clearPolygon();
      })
      .then(() => {
        history.goBack();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <PricingWrapper>
      <Header className="header" />
      <div className="current-lead">
        <CurrentLeadTable />
      </div>
      <div className="title">
        <h2>Calcular Manualmente</h2>
        <div className="upload-button">
          <ReactS3Uploader
            className="uploader-button"
            getSignedUrl={handleSignedFileUpload}
            accept="*"
            onProgress={onProgress}
            onError={onError}
            onFinish={onFinish}
            uploadRequestHeaders={{
              "Content-Type": contentType,
              "x-amz-acl": "private",
            }}
            contentDisposition="auto"
            autoUpload={true}
          />
          <CloudUploadRoundedIcon className="icon-cloud" />
          <span id="button-upload">Subir archivo</span>
        </div>
      </div>
      <div className="table">
        <TablePricingManualContainer />
      </div>
      <div className="source-question">
        <span className="question">
          ¿Utilizaste <b>otras fuentes</b> para calcular el valor de este
          inmueble?
        </span>
        <Formik
          enableReinitialize
          initialValues={{
            externalSources: externalSources,
          }}
        >
          {({ values, errors, touched, setFieldValue }) => (
            <Form>
              <ToggleGroup
                id="externalSources"
                name="externalSources"
                value={values.externalSources}
                error={errors.externalSources}
                touched={touched.externalSources}
                setFieldValue={setFieldValue}
                default={0}
              >
                <div className={`radio-container`}>
                  <Field
                    component={Toggle}
                    name="externalSources"
                    id={"si"}
                    label={"Sí"}
                    value={1}
                    default={0}
                    values={values}
                    error={errors.externalSources}
                    touched={touched.externalSources}
                    setFieldValue={setFieldValue}
                    setFlag={setExternalSources}
                  />
                </div>
                <div className={`radio-container`}>
                  <Field
                    component={Toggle}
                    name="externalSources"
                    id={"no"}
                    label={"No"}
                    value={0}
                    default={0}
                    values={values}
                    error={errors.externalSources}
                    touched={touched.externalSources}
                    setFieldValue={setFieldValue}
                    setFlag={setExternalSources}
                  />
                </div>
              </ToggleGroup>
            </Form>
          )}
        </Formik>
      </div>
      <div className="comment">
        <CommentContainer toggleRefetch={toggleRefetch} />
        {(pricingData.comentario || pricingData.comentario === "") && (
          <Button
            id="saveButton"
            typeClass={validData ? "primary" : "disabled"}
            className="comment-save-button"
            label="Enviar"
            type="submit"
            onClick={saveData}
          >
            <SendIcon className="icon-comment" />
          </Button>
        )}
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackMessage ? true : false}
        autoHideDuration={6000}
        onClose={handleClose}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{snackMessage}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </PricingWrapper>
  );
};
const mapStateToProps = (state) => ({
  email: selectUserEmail(state),
  inmuebleID: selectInmuebleID(state),
  apartment: selectApartment(state),
  pricingData: selectPricingData(state),
  mapPolygon: state.mapPolygon,
});

const mapDispatchToProps = (dispatch) => ({
  resetPricingData: () => dispatch(resetPricingDataAction()),
  removeApartment: () => dispatch(removeApartmentAction()),
  clearPolygon: () => dispatch(clearPolygonAction()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PricingManual)
);
