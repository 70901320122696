import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { useMutation } from "@apollo/client";
import { saveImpossibleData, SaveHabiNoProperty } from "../../queries/queries";
import CalculateAmounts from "./calculateAmounts";
import { fetchDataAxios } from "../../utils/fetchRequest";

import { selectApartment } from "../../redux/selectors/apartment.selectors";
import { removeApartmentAction } from "../../redux/actions/apartment.actions";
import { clearPolygonAction } from "../../redux/actions/mapPolygon.actions";
import {
  selectHasExternalRows,
  selectSelectedRows,
  selectRowsDataForMutations,
} from "../../redux/selectors/selectedRows.selectors";
import {
  resetPricingDataAction,
  updatePricingDataAction,
} from "../../redux/actions/pricingData.actions";
import { selectPercentil } from "../../redux/selectors/pricingData.selectors";
import { selectUserEmail } from "../../redux/selectors/userData.selectors";
import { buildData } from "../ResultFields/resultFields.utils";

const rootUrlForms =
  process.env.REACT_APP_FORM_API_URL_V2 +
  process.env.REACT_APP_ENDPOINT_FORMS_API_BASE_PATH_CONTAINER;
const endpointPricingManual = process.env.REACT_APP_PRICING_MANUAL_URL_ENDPOINT;
const urlGetPricingManual = rootUrlForms + endpointPricingManual;
const apiKeyForms = process.env.REACT_APP_FORMS_API_KEY_CONTAINER;

const CalculateAmountsContainer = (props) => {
  const {
    hasExternalRows,
    removeApartment,
    percentil,
    selectedRows,
    updatePricingData,
    apartment,
    email,
    resetPricingData,
    clearPolygon,
    rowsDataForMutation,
    setHasPrice,
  } = props;

  const [saveHabiImpossible] = useMutation(saveImpossibleData);
  const [saveHabiNoPrice] = useMutation(SaveHabiNoProperty);

  const getManualPricing = async (data) => {
    let responseDataPricingManual = 0;
    try {
      const responsePricingManual = fetchDataAxios(urlGetPricingManual, {
        method: "POST",
        headers: {
          "x-api-key": apiKeyForms,
          "Content-Type": "application/json",
        },
        data: data,
      });
      responseDataPricingManual = await responsePricingManual;
      return responseDataPricingManual;
    } catch (err) {
      console.log(`Error in getManualPricing: ${err.message}`);
    }
  };

  const calculateMetersValue = async () => {
    if (selectedRows.length === 0) return;
    const dataToSend = {
      propertyId: apartment.inmuebleID,
      listProperty: buildData(rowsDataForMutation),
      type: "menor_mt2",
    };
    const responseDataPricing = await getManualPricing(dataToSend);
    updatePricingData({
      ask_price: responseDataPricing.ask_price_habi,
      precio_habi: responseDataPricing.precio_manual,
    });
  };

  const calculateComparablesValue = async () => {
    if (selectedRows.length === 0) return;
    const dataToSend = {
      propertyId: apartment.inmuebleID,
      listProperty: buildData(rowsDataForMutation),
      type: "comparables",
      percentil: percentil,
    };
    const responseDataPricing = await getManualPricing(dataToSend);
    updatePricingData({
      ask_price: responseDataPricing.ask_price_habi,
      precio_habi: responseDataPricing.precio_manual,
    });
  };

  const saveDataImpossible = () => {
    saveHabiImpossible({
      variables: {
        inmuebleId: apartment.inmuebleID,
        nid: apartment.nid,
        flag: 1,
        agent: email,
      },
    });
    removeApartment();
    resetPricingData();
    clearPolygon();
  };

  const manualPricingValue = () => {
    props.history.push({
      pathname: "/pricingManual",
      state: {
        inmuebleId: apartment.inmuebleID,
        apartment: apartment,
      },
    });
  };

  const noHabiProperty = () => {
    saveHabiNoPrice({
      variables: {
        inmuebleId: apartment.inmuebleID,
        nid: apartment.nid,
        negocioId: apartment.negocioID,
        agent: email,
      },
    });
    removeApartment();
    resetPricingData();
    clearPolygon();
  };

  const calculate = (type) => {
    setHasPrice(false);
    if (type === "manually") {
      manualPricingValue();
    } else if (type === "meters") {
      calculateMetersValue();
    } else if (type === "comparables") {
      calculateComparablesValue();
    } else if (type === "nohabi") {
      noHabiProperty();
    } else if (type === "impossible") {
      saveDataImpossible();
    }
  };

  const disableCalculationConditions =
    hasExternalRows || selectedRows.length === 0;

  return (
    <CalculateAmounts
      calculate={calculate}
      flagTab={props.flagTab}
      inmuebleId={apartment.inmuebleID}
      agent={email}
      isValid={disableCalculationConditions}
    />
  );
};

const mapStateToProps = (state) => ({
  email: selectUserEmail(state),
  apartment: selectApartment(state),
  selectedRows: selectSelectedRows(state),
  hasExternalRows: selectHasExternalRows(state),
  percentil: selectPercentil(state),
  rowsDataForMutation: selectRowsDataForMutations(state),
});

const mapDispatchToProps = (dispatch) => ({
  removeApartment: () => dispatch(removeApartmentAction()),
  updatePricingData: (kv) => dispatch(updatePricingDataAction(kv)),
  resetPricingData: () => dispatch(resetPricingDataAction()),
  clearPolygon: () => dispatch(clearPolygonAction()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CalculateAmountsContainer)
);
