import styled from "styled-components";
import {
  scarpaGray100,
  archiaRegular,
  scarpaGray500,
  bogotaBlue100,
  scarpaGray600,
  electricPurple500,
  scarpaGray400,
  scarpaGray000,
  electricPurple600,
} from "../../StylesConstants";

const ListTableWrapper = styled.div`
  .button-new {
    margin: 10px 0px;
  }
  .infinite-scroll::-webkit-scrollbar {
    display: none;
  }

  .infinite-scroll {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
  }

  .table-container {
    font-family: ${archiaRegular};
    thead {
      background-color: ${scarpaGray100};
      font-weight: bold;
      font-size: 13px;
      color: ${scarpaGray500};
      th {
        vertical-align: middle;
        padding: 16px;
        &.dateCreation {
          width: 170px;
        }
        .icon-down-arrow {
          color: ${electricPurple500};
          font-size: 20px;
        }
      }
    }

    tbody {
      font-size: 15px;
      color: ${scarpaGray600};
      tr {
        height: 64px;
        &:hover {
          background-color: ${bogotaBlue100};
        }
        th {
          font-weight: normal;
          vertical-align: middle;
          padding: 0 16px;
          #button {
            background-color: ${electricPurple500};
            color: ${scarpaGray000};
            width: 86px;
            height: 32px;
            font-size: 13px;
            &.primary {
              border: none;
              color: ${scarpaGray000};
              background-color: ${electricPurple500};

              span {
                color: ${scarpaGray000};
                vertical-align: middle;
              }

              &:hover {
                color: ${scarpaGray000};
                background-color: ${electricPurple600};
                span {
                  color: ${scarpaGray000};
                  vertical-align: middle;
                }
              }
              .dollar-icon {
                margin: 0;
                font-size: 16px;
              }
            }
          }
          div {
            .delete-icon {
              cursor: pointer;
              &:hover {
                color: ${scarpaGray400};
              }
            }
          }
          small {
            color: ${scarpaGray400};
            font-size: 11px;
          }
        }
      }
    }
  }
`;

export default ListTableWrapper;
