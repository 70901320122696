export const standarizationActionTypes = {
  SET_PROP_STANDARIZATION: "SET_PROP_STANDARIZATION",
  REMOVE_PROP_STANDARIZATION: "REMOVE_PROP_STANDARIZATION",
};

export const setStandarizationAction = (apartment) => ({
  type: standarizationActionTypes.SET_PROP_STANDARIZATION,
  payload: apartment,
});

export const removeStandarizationAction = () => ({
  type: standarizationActionTypes.REMOVE_PROP_STANDARIZATION,
});
