import React from "react";
import { connect } from "react-redux";
import { selectHasApartment } from "../../redux/selectors/apartment.selectors";
import Header from "../../components/Header/header";
import TabsContainer from "../../components/Tabs/tabsContainer";
import ComparablesMapPage from "../ComparablesMap/ComparablesMapPage";

import { DashBoardWrapper } from "./style";
import { selectPolygonSelected } from "../../redux/selectors/mapPolygon.selectors";
import TabStandarization from "../../components/Tabs/tabStandarization";

const Dashboard = ({ hasApartment, polygonSelected, userData }) => {
  return (
    <DashBoardWrapper>
      <Header className="header" />
      {hasApartment && !polygonSelected && <ComparablesMapPage />}
      <div className="body">
        {userData.role !== "normalizacion" ? (
          <TabsContainer />
        ) : (
          <TabStandarization />
        )}
      </div>
    </DashBoardWrapper>
  );
};

const mapStateToProps = (state) => ({
  hasApartment: selectHasApartment(state),
  polygonSelected: selectPolygonSelected(state),
  userData: state.userData,
});

export default connect(mapStateToProps)(Dashboard);
