import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { useMutation } from "@apollo/client";
import { BucketWrapper } from "./style";
import Header from "../../components/Header/header";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  TextField,
  Button,
  FormControl,
} from "@material-ui/core";
import { saveBuckets } from "../../queries/queries";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 275,
    maxWidth: 800,
  },
}));

const EditBucket = (props) => {
  const classes = useStyles();
  const row = props.location?.state.row;
  const [data, setData] = useState({});

  useEffect(() => {
    setData(row);
  }, [row]);

  const handleChange = (event, name) => {    
    event.preventDefault();
    setData({ ...data, [name]: event.target.value });
  };

  const [sBuckets] = useMutation(saveBuckets);

  const saveBucket = async (data) => {
    sBuckets({
      variables: {
        zoneMedianId: data.zoneMedianId,
        buckets: data.buckets,
      },
    })
      .then(() => cancelBucket())
      .catch((err) => {
        console.log(`Error in saveBuckets saveProcess: ${err.message}`);
      });
  };

  const cancelBucket = async () => {
    props.history.push({
      pathname: "/dashboard",
      state: {
        type: "buckets",
      },
    });
  };

  return (
    <BucketWrapper>
      <Header className="header" />
      <div>
        <div className="title">
          <h2>Buckets</h2>
        </div>
        <Card className="card">
          <CardContent className="cardContent">
            <FormControl variant="outlined" className={classes.formControl}>
              <TextField
                error={!data.buckets ? true : false}
                variant="outlined"
                id="idBuckets"
                label="Buckets"
                required={true}
                size="medium"
                fullWidth
                autoComplete="off"
                defaultValue={row.buckets ? row.buckets : ""}
                className="text-file"
                name="buckets"
                onChange={(event) => handleChange(event, "buckets")}
              />
              <div className="save-button">
                <label htmlFor="contained-button-file">
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    component="span"
                    onClick={() => {
                      saveBucket(data);
                    }}
                  >
                    Guardar
                  </Button>
                </label>
                <label htmlFor="contained-button-file">
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    component="span"
                    onClick={() => {
                      cancelBucket(data);
                    }}
                  >
                    Cancelar
                  </Button>
                </label>
              </div>
            </FormControl>
          </CardContent>
        </Card>
      </div>
    </BucketWrapper>
  );
};

export default withRouter(EditBucket);
