import { stableSort, getComparator } from "../../utils/sorting";

export const sortAndSliceInmuebles = ({ data, order, orderBy, page }) => {
  if (!data) return [];
  return stableSort(data, getComparator(order, orderBy)).slice(
    page * 5,
    page * 5 + 5
  );
};

export const getAndFormatAverage = ({ data }) => {
  if (!data || data.length === 0) return "N/A";
  const calcAverage = (average, curr) =>
    average + curr.precio_manual / curr.area;
  const addAverages = data.reduce(calcAverage, 0);
  const average = Math.round(addAverages / data.length);
  return `$ ${average.toLocaleString("en-US")}`;
};
