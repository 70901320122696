import React, { useEffect, useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "./tabPanel";
import { TabsContainerWrapper } from "./style";
import StandarizationContainer from "../Standarization/StandarizationContainer";

const scrollableProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
};

const TabStandarization = (data) => {
  const [value, setValue] = useState(0);
  const [flagTab, setFlagTab] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (value === 4) {
      setFlagTab(true);
    } else {
      setFlagTab(false);
    }
  }, [value, flagTab]);

  return (
    <TabsContainerWrapper className="tabs-inside-container">
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        <Tab label="Normalización" {...scrollableProps(0)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <StandarizationContainer />
      </TabPanel>
    </TabsContainerWrapper>
  );
};

export default TabStandarization;
