import React from "react";
import { connect } from "react-redux";
import {
  selectWarningMessages,
  selectHasWarningRowIds,
  selectWarningRowIds,
} from "../../redux/selectors/warnings.selectors";
import { resetWarningsAction } from "../../redux/actions/warnings.actions";
import { removeRowsByIdsAction } from "../../redux/actions/selectedRows.actions";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { ReactComponent as NotificationLogo } from "../../static/assets/logo/notifications-24px .svg";

import {
  ModalContainer,
  Overlay,
  CerrarX,
  Title,
  WarningBanner,
  WarningGrid,
  WarningMessageList,
  ValidationQuestion,
  ButtonContainer,
  StyledButton,
  StyledScrollbarWrapper,
} from "./WarningModal.style";

const WarningsModal = (props) => {
  const {
    handleSubmit,
    warnings,
    resetWarnings,
    hasWarningRowIds,
    warningRowIds,
    removeRowsByIds,
  } = props;

  return (
    <ModalWrapper>
      <Overlay />
      <ModalContainer>
        <CerrarX>
          <div onClick={resetWarnings}>&times;</div>
        </CerrarX>
        <Title>Inconsistencias en la selección</Title>
        <WarningBanner>
          <WarningGrid>
            <div className="sidebar">
              <NotificationLogo />
            </div>
            <div className="main">
              <h2>¡Atención!</h2>
              <p>
                Una o más de tus selecciones parecen no estar correctas. Si
                deseas hacer un cambio, haz click en{" "}
                <strong>“Corregir selección”</strong>.
              </p>
            </div>
          </WarningGrid>
        </WarningBanner>
        <StyledScrollbarWrapper>
          <WarningMessageList>
            {warnings?.map(({ message }) => (
              <li>
                <div className="arrows">»</div> {message}
                <br />
              </li>
            ))}
          </WarningMessageList>
          <ValidationQuestion>
            {warnings?.length === 1 && warnings[0]?.question
              ? warnings[0].question
              : "¿Estás seguro que quieres continuar?"}
          </ValidationQuestion>
          <ButtonContainer>
            {hasWarningRowIds ? (
              <>
                <StyledButton
                  onClick={resetWarnings}
                  label="Seguro, continuar así"
                  id="continuar"
                />
                <StyledButton
                  onClick={() => {
                    removeRowsByIds(warningRowIds);
                    resetWarnings();
                  }}
                  label={
                    warningRowIds.length > 1
                      ? `Borrar selecciones no validadas (${warningRowIds.length})`
                      : "Borrar selección"
                  }
                  id="corregir"
                />
              </>
            ) : (
              <>
                <StyledButton
                  onClick={() => {
                    handleSubmit({ validate: false });
                    resetWarnings();
                  }}
                  label="Seguro, continuar así"
                  id="continuar"
                />
                <StyledButton
                  onClick={resetWarnings}
                  label="Corregir selección"
                  id="corregir"
                />
              </>
            )}
          </ButtonContainer>
        </StyledScrollbarWrapper>
      </ModalContainer>
    </ModalWrapper>
  );
};

const mapStateToProps = (state) => ({
  warnings: selectWarningMessages(state),
  warningRowIds: selectWarningRowIds(state),
  hasWarningRowIds: selectHasWarningRowIds(state),
});

const mapDispatchToProps = (dispatch) => ({
  resetWarnings: () => dispatch(resetWarningsAction()),
  removeRowsByIds: (rowIds) => dispatch(removeRowsByIdsAction(rowIds)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WarningsModal);
