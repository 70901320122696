export const decodeJsonData = (data) => {
  data = JSON.parse(data);
  if (Array.isArray(data)) return data;
  const decodedDataArr = [];
  for (let [key, val] of Object.entries(data)) {
    for (let [idx, value] of Object.entries(val)) {
      decodedDataArr[idx] = { ...decodedDataArr[idx] };
      decodedDataArr[idx][key] = value;
    }
  }
  return decodedDataArr;
};