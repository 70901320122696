import React from "react";
import { Dropdown } from "react-bootstrap";
import SquareDropdownWrapper from "./style";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";

const SquareDropdown = ({
  option,
  title,
  text,
  setOption,
  setFilter,
  idDropdown,
  ...props
}) => {
  const handleOptions = (e) => {
    setOption(e.target.innerText);
    setFilter(option[e.target.innerText]);
  };

  return (
    <SquareDropdownWrapper>
      <div className="container">
        <span>{text}</span>
        <div className="dropdow-container">
          <Dropdown drop="down">
            <Dropdown.Menu key={idDropdown} id={`square-${idDropdown}`}>
              {option &&
                Object.keys(option).map((item, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      onClick={(event) => handleOptions(event)}
                      className={title === item ? "active" : null}
                    >
                      {item}
                    </Dropdown.Item>
                  );
                })}
            </Dropdown.Menu>
            <Dropdown.Toggle id="dropdown-custom-components">
              {title}
              <ArrowDropDownRoundedIcon className="icon-arrow" />
            </Dropdown.Toggle>
          </Dropdown>
        </div>
      </div>
    </SquareDropdownWrapper>
  );
};

SquareDropdown.defaultProps = { idDropdown: "square" };

export default SquareDropdown;
