import { createSelector } from "reselect";
import { createManualRowData } from "../utils/selectedRows.utils";

export const selectSelectedRows = (state) => state.selectedRows;
const selectManualRowData = (state) => state.manualRowData;

export const selectHasExternalRows = createSelector(
  [selectSelectedRows],
  (selectedRows) =>
    selectedRows.filter((row) => row.external).length === 0 ? false : true
);

export const selectRowsDataForMutations = createSelector(
  [selectSelectedRows, selectManualRowData],
  (selectedRows, manualRowData) =>
    createManualRowData(selectedRows, manualRowData)
);

export const selectHasZoneRows = createSelector(
  [selectSelectedRows],
  (selectSelectedRows) => {
    const zoneRows = selectSelectedRows.filter((row) => row.tipoConsulta === 3);
    return zoneRows.length === 0 ? false : true;
  }
);

export const selectHasLotRows = createSelector(
  [selectSelectedRows],
  (selectSelectedRows) => {
    const lotRows = selectSelectedRows.filter((row) => row.tipoConsulta === 1);
    return lotRows.length === 0 ? false : true;
  }
);
