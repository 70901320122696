import React from "react";
import { ReactComponent as InfoLogo } from "../../static/assets/icons/info-icon-24px.svg";
import { ReactComponent as WarningLogo } from "../../static/assets/icons/notifications-24px.svg";
import { ReactComponent as ErrorLogo } from "../../static/assets/icons/error-24px.svg";
import { ReactComponent as SuccessLogo } from "../../static/assets/icons/success_check_circle-24px.svg";
import {
  informationPrimary,
  informationSecondary,
  informationPrimary50,
  warningPrimary,
  warningSecondary,
  warningPrimary50,
  errorPrimary,
  errorSecondary,
  errorPrimary50,
  successPrimary,
  successSecondary,
  successPrimary50,
} from "../../StylesConstants";

export const getBorderColor = (type) => {
  if (type === "info") return informationPrimary50;
  if (type === "warning") return warningPrimary50;
  if (type === "error") return errorPrimary50;
  if (type === "success") return successPrimary50;
};

export const getTitleColor = (type) => {
  if (type === "info") return informationPrimary;
  if (type === "warning") return warningPrimary;
  if (type === "error") return errorPrimary;
  if (type === "success") return successPrimary;
};

export const getBackgroundColor = (type) => {
  if (type === "info") return informationSecondary;
  if (type === "warning") return warningSecondary;
  if (type === "error") return errorSecondary;
  if (type === "success") return successSecondary;
};

export const getIcon = (type) => {
  if (type === "info") return <InfoLogo />;
  if (type === "warning") return <WarningLogo />;
  if (type === "error") return <ErrorLogo />;
  if (type === "success") return <SuccessLogo />;
};

export const getTitle = (type) => {
  if (type === "info") return "Importante";
  if (type === "warning") return "¡Atención!";
  if (type === "error") return "¡Error!";
  if (type === "success") return "¡Muy bien!";
};
