import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Button from "../Button/button";
import HomeListWrapper from "./style";
import RefreshRoundedIcon from "@material-ui/icons/RefreshRounded";
import SearchFilter from "../SearchFilter/searchFilter";
import RoundedDropdown from "../RoundedDropdown/roundedDropdown";
import ListTable from "../ListTable/listTable";
import ListDiscount from "../ListDiscount/listDiscount";
import ListBucket from "../ListBucket/listBucket";
import CheckBoxDropdown from "../CheckBoxDropdown/checkBoxDropdown";
import CalendarFilterContainer from "../CalendarFilter/calendarFilterContainer";
import moment from "moment";
import { useQuery } from "@apollo/client";
import {
  getCitiesFilter,
  getZoneBigFilter,
  getZoneMedianFilter,
} from "../../queries/queries";
import {
  headerDiscount,
  headerList,
  options,
  zoneList,
  headerBucket,
  cityList,
} from "./homeList.utils";

const Home = (props) => {
  const [inputValue, setInputValue] = useState("");
  const [optionFuente, setOptionsFuente] = useState(["Todas"]);
  const [sourceIds, setSourceIds] = useState([0]);
  const [prevFuente, setPrevFuente] = useState(["Todas"]);
  const [refresh, setRefresh] = useState(true);
  const [citiesList, setCitiesList] = useState({});
  const [cityOption, setCityOption] = useState("Bogotá");
  const [city, setCity] = useState(1);
  const [prevCity, setPrevCity] = useState(0);
  const [selectedDayRange, setSelectedDayRange] = useState({
    from: {
      year: Number(moment().subtract(3, "days").format("YYYY")),
      month: Number(moment().subtract(3, "days").format("MM")),
      day: Number(moment().subtract(3, "days").format("DD")),
    },
    to: {
      year: Number(moment().format("YYYY")),
      month: Number(moment().format("MM")),
      day: Number(moment().format("DD")),
    },
  });
  const [zoneBigLabel, setZoneBigLabel] = useState("Todas");
  const [zoneBigId, setZoneBigId] = useState(0);
  const [zoneBigList, setZoneBigList] = useState({});
  const [zoneMedianLabel, setZoneMedianLabel] = useState("Todas");
  const [zoneMedianId, setZoneMedianId] = useState(0);
  const [zoneMedianList, setZoneMedianList] = useState({});

  const { data: dataCities } = useQuery(getCitiesFilter());
  const { data: dataZoneBig } = useQuery(getZoneBigFilter(city));
  const { data: dataZoneMedian } = useQuery(getZoneMedianFilter(zoneBigId));

  useEffect(() => {
    setCitiesList(cityList(dataCities?.getCities));
  }, [dataCities]);

  useEffect(() => {
    if (citiesList !== undefined && Object.keys(citiesList).length > 0) {
      setPrevCity(city);
      setCity(citiesList[cityOption]);
      setZoneBigLabel("Todas");
      setZoneBigId(0);
      setZoneMedianLabel("Todas");
      setZoneMedianId(0);
    }
  }, [cityOption, city, citiesList]);

  useEffect(() => {
    setPrevFuente(optionFuente);
  }, [optionFuente]);

  useEffect(() => {
    if (zoneBigList && Object.values(zoneBigList).length > 0) {
      setZoneBigId(zoneBigList[zoneBigLabel]);
      setZoneBigLabel(zoneBigLabel);
      setZoneMedianLabel("Todas");
      setZoneMedianId(0);
    }
  }, [zoneBigList, zoneBigLabel]);

  useEffect(() => {
    setZoneBigList(zoneList(dataZoneBig?.getZoneBig));
  }, [dataZoneBig]);

  useEffect(() => {
    if (zoneMedianList && Object.values(zoneMedianList).length > 0) {
      setZoneMedianId(zoneMedianList[zoneMedianLabel]);
      setZoneMedianLabel(zoneMedianLabel);
    }
  }, [zoneMedianList, zoneMedianLabel]);

  useEffect(() => {
    setZoneMedianList(zoneList(dataZoneMedian?.getZoneMedian));
  }, [dataZoneMedian]);

  return (
    <HomeListWrapper>
      <div className="header-container">
        <div className="title">
          <h1>
            {props.type === "pricing"
              ? "Pricing Tool"
              : props.type === "repricing"
              ? "Repricing Tool"
              : props.type === "reactivado"
              ? "Reactivado Pricing Tool"
              : props.type === "gestionsinpricing"
              ? "Gestión sin Pricing"
              : props.type === "endpricing"
              ? "Pricing Final"
              : props.type === "discount"
              ? "Reglas"
              : "Buckets"}
          </h1>
          {props.type !== "discount" && props.type !== "buckets" && (
            <h4 className="count-content"> Número de Leads: {props.count}</h4>
          )}
        </div>
        <div className="btn-container">
          <Button
            id="button"
            typeClass="primary"
            className="d-inline-block"
            label="Refresh"
            type="button"
            onClick={() => {
              setRefresh(true);
            }}
          >
            <RefreshRoundedIcon className="icon-refresh" />
          </Button>
        </div>
      </div>
      {props.type !== "discount" && props.type !== "buckets" && (
        <>
          <div className="filters-container">
            <div className="search">
              <SearchFilter
                inputValue={inputValue}
                setInputValue={setInputValue}
                placeholder="Buscar por NID"
              />
            </div>
            <>
              <div className="fuentes">
                <CheckBoxDropdown
                  text="Mostrar Fuentes:"
                  idDropdown="fuente"
                  option={options}
                  setOption={setOptionsFuente}
                  title={optionFuente}
                  setIds={setSourceIds}
                />
              </div>
              <RoundedDropdown
                text="Ciudad:"
                idDropdown="city"
                option={citiesList}
                setOption={setCityOption}
                title={cityOption}
              />
              <RoundedDropdown
                text="Zona Grande:"
                idDropdown="big-zone"
                option={zoneBigList}
                setOption={setZoneBigLabel}
                title={zoneBigLabel}
              />
              <RoundedDropdown
                text="Zona Mediana:"
                idDropdown="mid-zone"
                option={zoneMedianList}
                setOption={setZoneMedianLabel}
                title={zoneMedianLabel}
              />
              <CalendarFilterContainer
                label="Fecha:"
                selectedDayRange={selectedDayRange}
                setSelectedDayRange={setSelectedDayRange}
              />
            </>
          </div>
          <div className="table-container">
            <ListTable
              inputVal={inputValue}
              headers={headerList}
              fuente={optionFuente}
              sourceIds={sourceIds}
              prevFuente={prevFuente}
              city={city}
              prevCity={prevCity}
              type={props.type}
              refresh={refresh}
              setRefresh={setRefresh}
              selectedDayRange={selectedDayRange}
              setSelectedDayRange={setSelectedDayRange}
              tab={props.type}
              flagTab={props.flagTab}
              zoneBig={zoneBigId ? zoneBigId : 0}
              zoneMedian={zoneMedianId ? zoneMedianId : 0}
            />
          </div>
        </>
      )}
      {(props.type === "discount" ||
        props.location?.state.type === "discount") && (
        <div className="table-container">
          <ListDiscount
            headers={headerDiscount}
            type={props.type}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        </div>
      )}
      {(props.type === "buckets" ||
        props.location?.state.type === "buckets") && (
        <>
          <div className="filters-container">
            <div className="search">
              <SearchFilter
                inputValue={inputValue}
                setInputValue={setInputValue}
                placeholder="Buscar por Zona"
              />
            </div>
          </div>
          <div className="table-container">
            <ListBucket
              inputVal={inputValue}
              headers={headerBucket}
              type={props.type}
              refresh={refresh}
              setRefresh={setRefresh}
            />
          </div>
        </>
      )}
    </HomeListWrapper>
  );
};

const mapStateToProps = (state) => ({
  count: state.propertiesCount.count,
});

export default connect(mapStateToProps)(Home);
