export const mapPolygonActionTypes = {
  SET_POLYGON_COORD_ARR: "SET_POLYGON_COORD_ARR",
  CLEAR_POLYGON: "CLEAR_POLYGON",
  SELECT_POLYGON: "SELECT_POLYGON",
};

export const setPolygonAction = (coordArr) => ({
  type: mapPolygonActionTypes.SET_POLYGON_COORD_ARR,
  payload: coordArr,
});

export const clearPolygonAction = () => ({
  type: mapPolygonActionTypes.CLEAR_POLYGON,
});

export const selectPolygonAction = () => ({
  type: mapPolygonActionTypes.SELECT_POLYGON,
});
